import React, { useCallback, useState } from "react";
import "./SitesList.scss";
import axios from "axios";
import env from "../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import languages from "./language.json";
import {
  faHand,
  faHandPointer,
  faPen,
  faPlusSquare,
} from "@fortawesome/pro-light-svg-icons";
// import {faEllipsisV} from "@fortawesome/pro-regular-svg-icons"
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { compare, sortList } from "../../../assets/helperFunctions";
import { cloneDeep } from "lodash";
import { buildTemplate } from "./Page";
import { newPage } from "./PagesList";

function clickPosition(e) {
  return { x: e.pageX, y: e.pageY };
}

export default function SitesList(props) {
  const [theSites, setTheSites] = useState([]);
  const [otherSites, setOtherSites] = useState([]);

  const storedSite = sessionStorage.getItem("storedSite");
  const [siteSelected, setSiteSelected] = useState(
    storedSite ? storedSite : -1
  );
  const [rootPageSelected, setRootPageSelected] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";

  const { templateList } = props;

  const editPage = (page_id) => {
    props.setIsLoading(true);
    axios
      .post(env.protocol + env.env + "/api/public/pages/GetPage.php", {
        user: sessionStorage.getItem("vfiUser"),
        page: page_id,
      })
      .then((response) => {
        const data = response.data;
        let lang = "";
        for (var prop in data.languages) {
          if (prop === "en") {
            lang = prop;
          }
        }
        if (lang === "") {
          for (var prop in data.languages) {
            lang = prop;
            break;
          }
        }
        const state = { page_id: page_id };
        const title = data.languages[lang].title;
        if (!window.history.state) {
          window.history.pushState(state, title);
        } else if (window.history.state.page_id != page_id) {
          window.history.pushState(state, title);
        }
        props.editPage(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  if (!loaded)
    axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        setTheSites(response.data.sort(compare));
        setOtherSites(response.data.filter((x) => x.other_sites === 1));

        setLoaded(true);

        if (siteSelected !== -1) {
          if (JSON.parse(sessionStorage.getItem("storedPages")).length === 0) {
            let site = undefined;
            response.data.forEach((element) => {
              if (element.siteId === siteSelected) {
                site = element;
              }
            });

            if (site !== undefined)
              props.siteSelect(siteSelected.siteId, siteSelected.name);
          }
        }
      });
  var clear;

  const isOtherSites = () => {
    if (otherSites.length > 0) {
      return ["VISIT FAROE ISLANDS WEBSITES", "REGIONAL WEBSITES"];
    }
    return [""];
  };
  return (
    <div
      className={
        "sites-list" +
        (theSites.length > 0 ? " loaded" : "") +
        (props.className ? " " + props.className : "") +
        (props.disableSitesList ? " disabled" : "")
      }
    >
      {!loaded ? (
        <div className="load-icon">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        ""
      )}
      <div className="the-list">
        <div className="divide-sites">
          {isOtherSites().map((element, i) => {
            let newTheSites = cloneDeep(theSites);
            let className = "";
            if (isOtherSites().length > 1) {
              if (i === 0) {
                newTheSites = theSites.filter(
                  (x) => x.other_sites === 0 || x.rootPage === 1
                );
              }
              if (i === 1) {
                newTheSites = theSites.filter((x) => x.other_sites === 1);
              }
            }
            if (newTheSites.length === 0) {
              return null;
            }
            return (
              <div key={element} className="wrap-sites">
                <div className={"sites-headline"}>
                  {!element
                    ? languages[language].sites_list.website.toUpperCase()
                    : element}
                </div>
                {newTheSites.map((element) => {
                  return (
                    <div
                      key={element.siteId + " " + element.page_id}
                      className={
                        "site" +
                        (element.siteId === parseInt(siteSelected) &&
                        Boolean(element.rootPage) === rootPageSelected
                          ? " selected"
                          : "")
                      }
                    >
                      {element.name}
                      <div
                        className="default-button"
                        onDoubleClick={() => {
                          if (!props.selectPage) {
                            editPage(element.page_id);
                          } else {
                            props.selectPage({
                              ...element,
                              id: element.page_id,
                            });
                          }
                        }}
                        onClick={(e) => {
                          const click = e.detail;

                          if (click === 1) {
                            clear = setTimeout(function () {
                              setSiteSelected(element.siteId);
                              setRootPageSelected(Boolean(element.rootPage));
                              if (props.sessionSet)
                                sessionStorage.setItem(
                                  "storedSite",
                                  element.siteId
                                );
                              props.siteSelect(
                                element.siteId,
                                element.name,
                                element.page_id
                              );
                              if (props.sessionSet)
                                sessionStorage.setItem(
                                  "selectedPages",
                                  JSON.stringify([])
                                );
                            }, 300);
                          }
                          if (click === 2) {
                            clearTimeout(clear);
                          }
                          // sessionStorage.setItem("storedPages",JSON.stringify([]));
                        }}
                      />
                      <div
                        className="options-button"
                        onClick={(e) => {
                          let finalTemplateList = cloneDeep(templateList) ?? [];
                          finalTemplateList.forEach((e, i) => {
                            finalTemplateList[i].onClick = () => {
                              const values = buildTemplate(e);
                              newPage(
                                element.site_id ?? element.siteId,
                                element.page_id,
                                values,
                                (response) => {
                                  const data = response.data;
                                  props.addExtraPage(data.child_of, data);
                                }
                              );
                            };
                          });

                          props.setOptionsList(
                            element.siteId,
                            props.selectPage
                              ? [
                                  {
                                    is: "choice",
                                    icon: faHandPointer,
                                    text: "Select Page",
                                    onClick: () => {
                                      props.selectPage({
                                        ...element,
                                        id: element.page_id,
                                      });
                                    },
                                  },
                                ]
                              : [
                                  {
                                    is: "choice_list",
                                    // icon: faPlusSquare,
                                    // icon: null,
                                    text: "New page from template",
                                    // onClick: () =>
                                    //   props.newPage(props.siteId, props.values.id, {
                                    //     languages: ["en"],
                                    //     modules: [
                                    //       {
                                    //         type: "visit_faroe_islands_top",
                                    //         data: [
                                    //           {
                                    //             bottom_icon: "2",
                                    //             media: {},
                                    //             title_and_description: "light_text",
                                    //             link_option: "page",
                                    //             key: 1,
                                    //           },
                                    //         ],
                                    //         languages: { en: [{}] },
                                    //         // is_hero: false,
                                    //         // show_icons: true,
                                    //       },
                                    //       { type: "social" },
                                    //       {
                                    //         type: "ckeditor_5",
                                    //         languages: {
                                    //           en: {
                                    //             blocks:
                                    //               "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
                                    //           },
                                    //         },
                                    //       },
                                    //     ],
                                    //   }),
                                    list: finalTemplateList,
                                    onClick: (e) => {},
                                    dontCloseAfter: true,
                                  },
                                  {
                                    is: "choice",
                                    icon: faPen,
                                    text: "Edit page",
                                    onClick: () => {
                                      props.setIsLoading(true);
                                      axios
                                        .post(
                                          env.protocol +
                                            env.env +
                                            "/api/public/pages/GetPage.php",
                                          {
                                            user: sessionStorage.getItem(
                                              "vfiUser"
                                            ),
                                            page: element.page_id,
                                          }
                                        )
                                        .then((response) => {
                                          const data = response.data;
                                          props.editPage(data);
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                        });
                                    },
                                  },
                                  {
                                    is: "choice",
                                    icon: faPlusSquare,
                                    text: "New page",
                                    onClick: () => {
                                      axios
                                        .post(
                                          env.protocol +
                                            env.env +
                                            "/api/secured/pages/AddPage",
                                          {
                                            user: sessionStorage.getItem(
                                              "vfiUser"
                                            ),
                                            site: element.siteId,
                                            page: element.page_id,
                                            languages: ["en"],
                                          }
                                        )
                                        .then((response) => {
                                          const data = response.data;
                                          props.addExtraPage(
                                            data.child_of,
                                            data
                                          );
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                        });
                                    },
                                  },
                                ],
                            clickPosition(e)
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
