import {
  faArrowLeft,
  faArrowRight,
  faCamera,
  faLink,
  faLongArrowRight,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import VfiInputText from "../../VfiInputText/VfiInputText";
import "./VisitFaroeIslandsTop.scss";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import VfiCheckbox from "../../VfiCheckbox/VfiCheckbox";
import { Carousel } from "react-bootstrap";
import OneMedia from "../OneMedia/OneMedia";
import sliderLeft from "../../images/slider-left.svg";
import sliderRight from "../../images/slider-right.svg";
import languages from "./language.json";
import env from "../../../environment.json";
// import FaroeIslandsMap from "../../FaroeIslandsMap/FaroeIslandsMap";
import IndividualEdit from "./IndividualEdit";
import { cloneDeep } from "lodash";
import LinkToEdit from "../VfiCarousel/LinkToEdit";
// import SvgChoose from "../../../Components/Content/Overlay/PageModuleChoose/PageModuleChoose";
import { OnlySvgChoose } from "../../../Components/Content/Overlay/SvgChoose/SvgChoose";
import VfiDivInput from "../../VfiDivInput";

export function moduleOptions(index, contentData, setContentData) {
  return [];
}

export default function VisitFaroeIslandsTop(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [, setTime] = useState(new Date().getTime());
  const [remove, setRemove] = useState(false);

  const onChangeText = (index, key, value) => {
    props.onChangeText(index, key, value);
  };

  const addPage = () => {
    setRemove(false);
    props.addSlide();
    setTime(new Date().getTime());
  };

  const { data } = props;

  useEffect(() => {
    if (remove === false) {
      setCurrentIndex(data.data.length - 1);
    }
  }, [data.data.length, remove]);
  const removePage = (index) => {
    setRemove(true);
    if (index === data.data.length - 1 && index !== 0) {
      setCurrentIndex(index - 1);
    }
    props.removeSlide(index);
    setTime(new Date().getTime());
  };
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language];

  const moveData = (index = 0, moveOffset = 1) => {
    let copyData = cloneDeep(data);
    const theData = copyData.data[index];
    copyData.data.splice(index, 1);
    copyData.data.splice(index + moveOffset, 0, theData);
    props.chosenLanguages.forEach((lang) => {
      const langObj = copyData.languages[index];
      copyData.languages.splice(index, 1);
      copyData.languages.splice(index + moveOffset, 0, langObj);
    });
    props.onChangeObj(copyData);
  };

  const MiddleTop = (props2) => (
    <div className="middle-top">
      <div
        className="option"
        onClick={(e) => {
          props.setOptionsList(
            <IndividualEdit
              values={data.data[currentIndex]}
              change={(e) => {
                let copyData = cloneDeep(data.data);
                copyData[currentIndex] = e;
                props.onChangeObj({ ...data, data: copyData });
              }}
              siteId={props.siteId}
              openOverlay={props.openOverlay}
              close={props.closeOptionsList}
              index={currentIndex}
            />,
            { x: e.pageX - 210, y: e.pageY + 30 },
            true
          );
        }}
      >
        <FontAwesomeIcon icon={faPen} />
      </div>
      <div
        className="option"
        onClick={() => {
          moveData(props2.index, -1);
        }}
        style={{ display: props2.index === 0 ? "none" : "grid" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <div
        className="option"
        onClick={() => {
          moveData(props2.index, 1);
        }}
        style={{
          display: props2.index === data.data.length - 1 ? "none" : "grid",
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </div>

      <div
        className="option red"
        onClick={() => {
          removePage(props2.index);
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </div>
    </div>
  );

  const clickable = (element) =>
    (element.link_option === "page" && element.page_id) ||
    (element.link_option === "external_url" && element.external_link);

  return (
    <div
      style={props.style}
      className={
        "visit-faroe-islands-top " + (props.className ? props.className : "")
      }
    >
      <div className="arrow-buttons">
        <div
          className="left-button"
          onClick={() => {
            if (currentIndex === 0) {
              setCurrentIndex(data.data.length - 1);
            } else {
              setCurrentIndex(currentIndex - 1);
            }
          }}
        >
          <img src={sliderLeft} alt="arrow-left" />
        </div>
        <div
          className="right-button"
          onClick={() => {
            if (currentIndex === data.data.length - 1) {
              setCurrentIndex(0);
            } else {
              setCurrentIndex(currentIndex + 1);
            }
          }}
        >
          <img src={sliderRight} alt="arrow-right" />
        </div>
      </div>
      <div
        className="new-slide"
        title={lang.new_slide_title}
        onClick={() => addPage()}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <Carousel
        interval={null}
        controls={false}
        activeIndex={currentIndex}
        onSelect={(e) => {
          // setCurrentIndex(e);
        }}
        indicators={false}
        keyboard={false}
      >
        {data.data.map((element, i) => {
          return (
            <Carousel.Item key={element.key}>
              <OneMedia
                className="image"
                disableChange={true}
                draggable={false}
                media={element.media}
              />
              <MiddleTop index={i} />
              {/* <FaroeIslandsMap /> */}
              {!element.media.id && (
                <div
                  className="add-media"
                  title="Choose medium"
                  onClick={() => {
                    props.openOverlay(
                      "mediachoose",
                      {
                        singleMedia: true,
                        filter: { mediaType: ["images", "videos"] },
                      },
                      i,
                      "medium"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCamera} />
                  <div className="text">{lang.upload_or_find_image}</div>
                </div>
              )}
              <div className={"left-content" + (props.inEdit ? " active" : "")}>
                {element.title_and_description === "light_text" && (
                  <div>
                    <VfiDivInput
                      className="hero-headline"
                      defaultValue={data.languages[i].headline}
                      onBlur={(e) => {
                        onChangeText(i, "headline", e.target.innerText);
                      }}
                      placeholder={"Insert headline here..."}
                    />
                    <VfiCKeditor
                      className="hero-excerpt"
                      value={data.languages[i].excerpt}
                      onChange={(e) => {
                        onChangeText(i, "excerpt", e);
                      }}
                      placeholder={"Insert excerpt here..."}
                    />
                  </div>
                )}
                {element.link_option !== "none" && (
                  <div className="link">
                    <div
                      className={
                        "link-button" +
                        (!props.inEdit && clickable(element) ? " active" : "")
                      }
                      onClick={() => {
                        if (!props.inEdit && clickable(element)) {
                          if (element.link_option === "external_url") {
                            window.open(element.external_link, "_blank");
                          }
                          if (element.link_option === "page") {
                            props.editPage(element.page_id);
                          }
                        }
                      }}
                    >
                      <div className="link-button-text">Explore</div>
                    </div>
                    <div
                      className="link-options-2"
                      onClick={(e) => {
                        props.setOptionsList(
                          <LinkToEdit
                            values={data.data[currentIndex]}
                            change={(e) => {
                              let copyData = cloneDeep(data.data);
                              copyData[currentIndex] = e;
                              props.onChangeObj({ ...data, data: copyData });
                            }}
                            openOverlay={props.openOverlay}
                            close={props.closeOptionsList}
                            selectedLanguage={props.selectedLanguage}
                            // headline="Select where the slide should be linked to."
                          />,
                          { x: e.pageX + 25, y: e.pageY - 265 / 2 },
                          true
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </div>
                  </div>
                )}
                <div className="link-options">
                  {data.languages[i].button_active ? (
                    <label className="is-external">
                      Is external{" "}
                      <VfiCheckbox
                        className="include-link"
                        checked={data.languages[i].is_external}
                        onChange={(e) => {
                          onChangeText(i, "is_external", e.target.checked);
                          setTime(new Date().getTime());
                        }}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                  {data.languages[i].button_active ? (
                    data.languages[i].is_external ? (
                      <div>
                        External link:
                        <VfiInputText
                          className="external-link"
                          defaultValue={data.languages[i].external_link}
                          onBlur={(e) => {
                            onChangeText(i, "external_link", e.target.value);
                          }}
                          placeholder={"External link..."}
                        />
                      </div>
                    ) : (
                      <div>
                        <div
                          className="select-page"
                          onClick={() => {
                            props.openOverlay(
                              "pagechoose",
                              { disableBottomSelect: true },
                              i,
                              "page"
                            );
                          }}
                        >
                          Select Page
                        </div>
                        <div>Selected page: {"Not implemented"}</div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      {
        <div
          className={`${
            (!data.show_icons && "icon-to-arrow") + " navigation-wrap"
          }`}
        >
          {data.show_icons && (
            <div className="nav-icons">
              <div
                className="icons-wrap"
                style={{
                  marginLeft:
                    currentIndex > 1
                      ? -(currentIndex - 1) * 80 + 80 + "px"
                      : "unset",
                }}
              >
                {data.data.map((element, i) => (
                  <div
                    key={element.key}
                    className={"icon " + (currentIndex === i ? "active " : "")}
                  >
                    <img
                      src={
                        env.protocol +
                        env.env +
                        "/svg_icons/" +
                        element.bottom_icon +
                        ".svg"
                      }
                      alt="bottom-icon"
                      onClick={(e) => {
                        props.setOptionsList(
                          <OnlySvgChoose
                            selected={parseInt(element.bottom_icon)}
                            onChange={(e) => {
                              let cData = cloneDeep(data.data);
                              cData[i] = { ...cData[i], bottom_icon: e };
                              props.onChangeObj({ ...data, data: cData });
                              props.closeOptionsList();
                            }}
                          />,
                          { x: e.pageX + 40, y: e.pageY },
                          true
                        );
                      }}
                    />
                    <div className="sub-text">
                      <VfiInputText
                        placeholder={"Text"}
                        defaultValue={data.languages[i].bottom_text}
                        onChange={(e) => {
                          onChangeText(i, "bottom_text", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
}
