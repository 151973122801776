import {
  faArrowLeftLong,
  faArrowToTop,
  faChevronDown,
  faChevronUp,
  faPencil,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { v4 as uuid } from "uuid";
import Select from "react-select";
import { slugify, uploadMedia } from "../../../assets/helperFunctions";
import OneMedia from "../../../assets/PageModules/OneMedia";
import env from "../../../environment.json";

import "./Product.scss";
const _ = require('lodash');

export default function Product(props) {
  const {
    units,
    saveProduct,
    availability,
    warehouse,
    product,
    close,
    newData,
    configuration,
    deleteProduct,
    siteId,
    vat = configuration.settings.find(x => x.label === 'VAT'),
    prices = configuration.physical
      .find((x) => x.productTypeuuid === product.productTypeId)
      ?.prices,
  } = props;

  const [draggingIndex, setDraggingIndex] = useState(undefined);
  const [uploaded, setUploaded] = useState(100);
  const [draggingOverUpload, setDraggingOverUpload] = useState(false);
  const [productState, setProductState] = useState(product);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [warehouseState] = useState(warehouse);
  const [availableState] = useState(availability);

  const [editInventory, setEditInventory] = useState(null);
  const [openVarient] = useState([]);
  useEffect(() => {
    if (!newData) {
      return;
    }
    let copyValues = cloneDeep(productState);
    newData.map((element) => {
      if (!element.used) {
        let maxKey = 0;
        if (element.unique) {
          copyValues.varient
            .find((x) => (x.sp_uuid ?? x.varient_id) === element.unique)
            .media.forEach((element) => {
              if (element.key > maxKey) maxKey = element.key;
            });
          copyValues.varient
            .find((x) => (x.sp_uuid ?? x.varient_id) === element.unique)
            .media.push({
              key: maxKey + 1,
              media_id: element.id,
              alt: element.alt,
              file_name: element.file_name,
              extension: element.fileExtension,
            });
        } else {
          copyValues.media.forEach((element) => {
            if (element.key > maxKey) maxKey = element.key;
          });

          copyValues.media.push({
            key: maxKey + 1,
            media_id: element.id,
            alt: element.alt,
            file_name: element.file_name,
            extension: element.fileExtension,
          });
        }
      }
      return (element.used = true);
    });
    setProductState(copyValues);
  }, [newData]);
  useEffect(() => { }, [forceUpdate]);
  const compare_numeric_prop = (calc, value) => {
    // Depending on the operator used for comparison
    switch (calc.operator) {
      case 'eq':
        // Equals
        return calc.value == value;
      case 'lt':
        // Less than
        return value < calc.value;
      case 'gt':
        // Greater than
        return value > calc.value;
      case 'lte':
        // Less than or equal to
        return value <= calc.value;
      case 'gte':
        // Greater than or equal to
        return value >= calc.value;
      default:
        return false;
    }
  }
  const property_applies = (calc, varient) => {
    // Get property value from varient if set
    const value = varient[calc.property] ? varient[calc.property] : null;

    if (!value) {
      // Property is not set in varient, it doesn't apply, return FALSE
      return false;
    }

    // Depending on what type of property value we are working on...
    switch (calc.type) {
      case 'numeric':
        // Numeric property value, compare to see if it applies
        return compare_numeric_prop(calc, value);
      case 'boolean':
        const calcBool = (calc.value === "true");
        const valBool = (value === "true" || value == 1);
        return calcBool === valBool;
      default:
        // Any other property type applies if the values are equal
        return (calc.value === value);
    }
  }
  const calc_applies_to_varient = (calc, varient) => {


    if (!_.isEmpty(calc.brand) && calc.brand.value !== varient['branduuid']) {
      // Calculation branduuid is set and does NOT match varient, return FALSE

      return false;
    }

    if (!_.isEmpty(calc.product) && calc.product.value !== varient['productuuid']) {
      // Calculation productuuid is set and does NOT match varient, return FALSE

      return false;
    }

    if (!_.isEmpty(calc.varient) && calc.varient.value !== varient['varientuuid']) {
      // Calculation varientuuid is set and does NOT match varient, return FALSE

      return false;
    }

    // If calc has a property to compare...
    if (!_.isEmpty(calc.property)) {
      // Return whether the property applies to this varient
      return property_applies(calc.property, varient['properties']);
    }


    // No unmatched values, calculation applies to varient, return TRUE
    return true;
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      setUploaded((progressEvent.loaded * 100) / progressEvent.total);
    },
  };

  const uploadFiles = (e) => {
    uploadMedia(e, siteId, config, (res) => {
      let copyValues = cloneDeep(productState);

      res.data.forEach((element) => {
        let maxKey = 0;
        copyValues.media.forEach((element) => {
          if (element.key > maxKey) maxKey = element.key;
        });
        copyValues.media.push({
          key: maxKey + 1,
          media_id: element.id,
          alt: element.alt,
          file_name: element.file_name,
          extension: element.file_extension,
        });
      });
      setProductState(copyValues);
    });
  };
  const calcVAT = (sellingPrice) => {
    sellingPrice = Math.round(
      parseFloat(
        sellingPrice +
        (sellingPrice *
          vat.value) /
        100
      ) * 100
    ) / 100;
    return sellingPrice
  }
  const generalInfo = (
    <div className="general-information">
      <h3>General information</h3>
      <div className="name">
        <label>Name</label>
        <input
          onChange={(e) => {
            productState.name = e.target.value;
          }}
          defaultValue={productState.name}
        />
      </div>
      <div className="description">
        <label>Description</label>
        <div
          onInput={(e) => {
            productState.description = e.currentTarget.innerHTML;
          }}
          suppressContentEditableWarning={true}
          className="div-input"
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: productState.description }}
        ></div>
      </div>
    </div>
  );
  const rating = (
    <div className="rating">
      <h3>Rating</h3>
      <div>
        <label>Rating</label>
        <input
          defaultValue={productState.rating}
          onChange={(e) => {
            productState.rating = e.target.value;
          }}
        />
      </div>
    </div>
  );
  const media = (
    <div className="media">
      <h3>Common images</h3>
      <div className="wrap-image">
        <div className="drag-drop">
          {uploaded === 100 ? (
            <div className="dropzone">
              <Dropzone
                onDrop={(e) => {
                  uploadFiles(e);
                  setDraggingOverUpload(false);
                }}
                onDragEnter={() => {
                  setDraggingOverUpload(true);
                }}
                onDragLeave={() => {
                  setDraggingOverUpload(false);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept=".jpg,.jpeg,.png,.gif,.mp4"
                        maxLength={1}
                      />
                      <div
                        className={
                          "upload-box" +
                          (draggingOverUpload ? " drag-over" : "")
                        }
                      >
                        <div className="text-content">
                          <FontAwesomeIcon icon={faArrowToTop} />
                          <div>UPLOAD IMAGES/VIDEOS</div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          ) : (
            <div className="upload-bar">
              <div
                className="progress-bar"
                style={{
                  width: uploaded + "%",
                }}
              />
            </div>
          )}
          <div
            className="add"
            onClick={() => {
              if (uploaded === 100) {
                props.openOverlay("mediaChoose", {
                  filter: { mediaType: ["images", "videos"] },
                  singleMedia: false,
                  afterFunc: (e) => { },
                });
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
        <div className="images">
          {cloneDeep(productState.media)
            .filter((x) => !x.deleted)
            .map((element, i) => {
              element.order_value = i;
              let url = "";
              if (element.external_url) {
                url = element.external_url;
              }
              if (element.file_name) {
                url =
                  env.protocol +
                  env.env +
                  "/uploads/" +
                  element.file_name +
                  "_thumbnail." +
                  element.extension;
              }
              return (
                <div key={element.id + i} className="wrap-onemedia">
                  {element.media_id ? (
                    <OneMedia
                      key={"oneMedia" + i}
                      media={{
                        id: element.media_id,
                        extension: element.extension,
                        alt: element.alt,
                        file_name: element.file_name,
                      }}
                      disableChange={true}
                      imgFormat="thumbnail"
                      videoThumbnail={true}
                      draggable={true}
                      onDragStart={(e) => {
                        setDraggingIndex(i);
                      }}
                      onDragEnd={(e) => {
                        setDraggingIndex(undefined);
                      }}
                      onDrop={(e) => {
                        const swap = (arr, a, b) => {
                          var temp = arr[a];
                          arr[a] = arr[b];
                          arr[b] = temp;
                          return arr;
                        };
                        if (draggingIndex !== undefined) {
                          let copyMedia = cloneDeep(productState.media);
                          swap(copyMedia, i, draggingIndex);
                          setProductState({
                            ...productState,
                            media: copyMedia,
                          });
                          setDraggingIndex(undefined);
                        }
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    />
                  ) : (
                    <div className="one-media ">
                      <div className="upload-box">
                        <div className="image-container" draggable="true">
                          <img
                            alt="Shop Product"
                            onError={(e) => {
                              e.target.onerror = null; // prevents looping
                              e.target.src =
                                "https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff";
                            }}
                            src={element.external_url ?? url}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    onClick={() => {
                      let findex = productState.media.findIndex(
                        (x) => x.id === element.id
                      );
                      productState.media[findex].deleted = true;
                      setForceUpdate((f) => !f);
                    }}
                    className="remove-image"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
  const productBox = (
    <div className="product-box">
      <h3>Product line</h3>
      {productState.productTypeId && (
        <>
          <div className="select-brand">
            <label>Brand</label>
            <Select
              key={productState.brand}
              className="select"
              placeholder="Select..."
              options={
                configuration.physical.find(
                  (x) => x.productTypeuuid === productState.productTypeId
                ).brands
              }
              defaultValue={productState.brand}
              onChange={(e) => {
                productState.brand = e;
                productState.product = null;
                setForceUpdate((f) => !f);
              }}
            />
          </div>
          {productState.brand && (
            <>
              <div className="select-product">
                <label>Product</label>

                <Select
                  key={productState.product}
                  className="select"
                  placeholder="Select..."
                  options={
                    configuration.physical
                      .find(
                        (x) => x.productTypeuuid === productState.productTypeId
                      )
                      .brands.find((x) => x.id === productState.brand.id)
                      .products
                  }
                  defaultValue={productState.product}
                  onChange={(e) => {
                    productState.product = e;
                    e.varients.forEach((element) => {
                      productState.varient = productState.varient ?? [];

                      let findex = productState.varient.findIndex(
                        (x) => x.uuid === element.uuid
                      );
                      if (findex === -1) {
                        let newVarient = {
                          id: element.id,
                          value: element.value,
                          label: element.label,
                          costPrice: 0,
                          rateType: { label: "DKK", value: "fixed" },
                          unit: { label: "Pieces", value: "pcs" },
                          quantity: 0,
                          uuid: element.uuid,
                          addCost: 0,
                          allocated: 0,
                          properties: element.properties,
                          media: [],
                        };
                        productState.varient.push(newVarient);
                      }
                      productState.varient = productState.varient.map((x) => {
                        x.checked = true;
                        return x;
                      });
                    });
                    setForceUpdate((f) => !f);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
  const attribute = (
    <div className="select-varient">
      <h3>Varients</h3>
      <div className="wrap-varients">
        {productState.varient.map((element) => {
          let findex = openVarient.findIndex((x) => x === element.sp_uuid);
          return (
            <div key={element.id}>
              <div>
                <div>
                  <input
                    onChange={(e) => {
                      element.checked = e.target.checked;
                      setForceUpdate((f) => (f ? false : true));
                    }}
                    defaultChecked={element.checked}
                    type="checkbox"
                  ></input>
                  {element.label}
                </div>
                <div
                  onClick={() => {
                    if (findex === -1) {
                      openVarient.push(element.sp_uuid);
                    } else {
                      openVarient.splice(findex, 1);
                    }
                    setForceUpdate((f) => !f);
                  }}
                >
                  {findex === -1 ? (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronUp} />
                  )}
                </div>
              </div>

              <div
                className="wrap-table"
                style={{
                  display: openVarient.includes(element.sp_uuid)
                    ? "flex"
                    : "none",
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>

                    {Object.keys(element.properties).map((element) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.label}</td>
                          <td>{element.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="media">
                  <div className="wrap-image">
                    <div className="drag-drop">
                      {uploaded === 100 ? (
                        <div className="dropzone">
                          <Dropzone
                            onDrop={(e) => {
                              uploadFiles(e);
                              setDraggingOverUpload(false);
                            }}
                            onDragEnter={() => {
                              setDraggingOverUpload(true);
                            }}
                            onDragLeave={() => {
                              setDraggingOverUpload(false);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input
                                    {...getInputProps()}
                                    accept=".jpg,.jpeg,.png,.gif,.mp4"
                                    maxLength={1}
                                  />
                                  <div
                                    className={
                                      "upload-box" +
                                      (draggingOverUpload ? " drag-over" : "")
                                    }
                                  >
                                    <div className="text-content">
                                      <FontAwesomeIcon icon={faArrowToTop} />
                                      <div>UPLOAD IMAGES/VIDEOS</div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      ) : (
                        <div className="upload-bar">
                          <div
                            className="progress-bar"
                            style={{
                              width: uploaded + "%",
                            }}
                          />
                        </div>
                      )}
                      <div
                        className="add"
                        onClick={() => {
                          if (uploaded === 100) {
                            // props.setModuleKeyInEdit("media_add");
                            props.openOverlay("mediaChoose", {
                              filter: { mediaType: ["images", "videos"] },
                              singleMedia: false,
                              unique: element.sp_uuid ?? element.varient_id,
                            });
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>

                    <div className="images">
                      {element.media
                        .filter((x) => !x.deleted)
                        .map((elm, i) => {
                          let url = "";
                          if (elm.external_url) {
                            url = element.external_url;
                          }
                          if (elm.file_name) {
                            url =
                              env.protocol +
                              env.env +
                              "/uploads/" +
                              elm.file_name +
                              "_thumbnail." +
                              elm.extension;
                          }
                          elm.order_value = i;
                          return (
                            <div key={elm.id + i} className="wrap-onemedia">
                              {elm.media_id ? (
                                <OneMedia
                                  key={"oneMedia" + i}
                                  media={{
                                    id: elm.media_id,
                                    extension: elm.extension,
                                    alt: elm.alt,
                                    file_name: elm.file_name,
                                  }}
                                  disableChange={true}
                                  imgFormat="thumbnail"
                                  videoThumbnail={true}
                                  draggable={true}
                                  onDragStart={(e) => {
                                    setDraggingIndex(i);
                                  }}
                                  onDragEnd={(e) => {
                                    setDraggingIndex(undefined);
                                  }}
                                  onDrop={(e) => {
                                    const swap = (arr, a, b) => {
                                      var temp = arr[a];
                                      arr[a] = arr[b];
                                      arr[b] = temp;
                                      return arr;
                                    };
                                    if (draggingIndex !== undefined) {
                                      let copyMedia = cloneDeep(
                                        productState.media
                                      );
                                      swap(copyMedia, i, draggingIndex);
                                      setProductState({
                                        ...productState,
                                        media: copyMedia,
                                      });
                                      setDraggingIndex(undefined);
                                    }
                                  }}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              ) : (
                                <div className="one-media ">
                                  <div className="upload-box">
                                    <div
                                      className="image-container"
                                      draggable="true"
                                    >
                                      <img
                                        alt="Upload media"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff";
                                        }}
                                        src={elm.external_url ?? url}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div
                                onClick={() => {
                                  element.media[i].deleted = true;
                                  setForceUpdate((f) => !f);
                                }}
                                className="remove-image"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  const editInventoryComponent = (
    <div className="editInventoryComponent">
      <div className="left-content">
        <div className="price">
          <div>
            <h3>Price</h3>
          </div>
          <div>
            <label>Netto Price</label>
            <input
              defaultValue={editInventory?.sellingPrice ?? 0}
              onChange={(e) => {
                if (editInventory) {
                  productState.varient.map((varient) => {
                    if (varient.varient_id === editInventory.varient_id) {
                      varient.nettoPrice = e.target.value;
                    }
                    return varient;
                  });
                  setForceUpdate((f) => !f);
                }
              }}
            />
          </div>
          <div className="extra-price">
            <div>
              <label>Extra Price</label>
              <input
                defaultValue={editInventory?.prices?.rate}
                onChange={(e) => {

                  const value = JSON.parse(
                    e.target.value === "" ? 0 : e.target.value
                  );
                  if (editInventory) {
                    if (!editInventory.prices) {
                      editInventory.prices = {
                        rate: value
                      }
                    } else {
                      editInventory.prices.rate = value;
                    }
                    editInventory.prices.changeState = editInventory.prices.new ? 'new' : 'update';
                    setForceUpdate((f) => !f);
                  }

                }}
              />
            </div>
            <div className="select-container">
              <label>Rate Type</label>
              <Select
                className="select"
                options={[
                  { label: "Percent", value: "percent" },
                  { label: "Fixed", value: "fixed" },
                ]}
                defaultValue={editInventory?.prices?.rateType}
                onChange={(e) => {
                  if (editInventory) {
                    if (!editInventory.prices) {
                      editInventory.prices = { rateType: e }
                    } else {
                      editInventory.prices.rateType = e;
                    }
                    editInventory.prices.changeState = editInventory.prices.new ? 'new' : 'update';
                    setForceUpdate((f) => !f);
                  }
                }}
              ></Select>
            </div>
          </div>
        </div>
        <div className="stock">
          <div>
            <h3>Stock</h3>
          </div>
          <div>
            <div>
              <label>Quantity</label>
              <input
                defaultValue={editInventory?.quantity}
                onChange={(e) => {
                  if (editInventory && e.target.value.length > 0) {
                    editInventory.quantity = JSON.parse(e.target.value);
                    setForceUpdate((f) => !f);
                  }
                }}
              />
            </div>
            <div>
              <label>Remote Quantity</label>
              <input
                defaultValue={editInventory?.remoteQuantity}
                onChange={(e) => {
                  if (editInventory && e.target.value.length > 0) {
                    editInventory.remoteQuantity = JSON.parse(e.target.value);
                    setForceUpdate((f) => !f);
                  }
                }}
              />
            </div>
            <div className="select-container">
              <label>Unit</label>
              <Select
                className="select"
                options={units}
                defaultValue={editInventory?.unit}
                onChange={(e) => {
                  if (editInventory) {
                    editInventory.unit = e;
                    setForceUpdate((f) => !f);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div class="cta">
          <div class="save"
            onClick={() => {
              saveProduct(productState);
              setEditInventory(null);

              // setEditInventory(null);
            }}
          >Save</div>
          <div
            className="close"
            onClick={() => {
              setEditInventory(null);
            }}
          >
            close
          </div>
        </div>
        <div className="wrap-price">
          <table>
            <tbody>
              <tr key="head-row">
                <th>Name</th>
                <th>Amount</th>
                <th></th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
              {!isNull(editInventory) &&
                prices.map((price) => {

                  editInventory.sellingPrice =
                    Math.round(parseFloat(editInventory.sellingPrice) * 100) /
                    100;

                  let isPrice = calc_applies_to_varient(price, editInventory);


                  if (!isPrice) {
                    return "";
                  }
                  if (price.rateType.value === "fixed") {
                    let fixed = (
                      <tr key={"row" + price.id}>
                        <td key="label_fixed">{price.label}</td>
                        <td key="selling_fixed">
                          <div className="line"></div>
                          {editInventory.sellingPrice}{" "}
                        </td>
                        <td key="line1_fixed">
                          <div className="line"></div>+
                        </td>
                        <td key="line2_fixed">
                          <div className="line"></div>
                          {price.rate}{" "}
                        </td>
                        <td key="total_fixed">
                          <div className="line"></div>
                          <b>
                            {Math.round(parseFloat(price.rate) * 100) /
                              100 +
                              editInventory.sellingPrice}
                          </b>
                        </td>
                      </tr>
                    );
                    editInventory.sellingPrice += price.rate;
                    return fixed;
                  }
                  if (price.rateType.value === "percent") {
                    let perc = (
                      <tr key={"row_" + price.id}>
                        <td>{price.label}</td>
                        <td key="selling_perc">
                          <div className="line"></div>
                          {editInventory.sellingPrice}{" "}
                        </td>
                        <td key="line1_perc">
                          <div className="line"></div>x
                        </td>
                        <td key="percent_perc">
                          <div className="line"></div>
                          {price.rate}%
                        </td>
                        <td key="total_perc">
                          <div className="line"></div>
                          <b>
                            {Math.round(
                              parseFloat(
                                editInventory.sellingPrice +
                                (editInventory.sellingPrice *
                                  price.rate) /
                                100
                              ) * 100
                            ) / 100}
                          </b>
                        </td>
                      </tr>
                    );

                    editInventory.sellingPrice +=
                      (price.rate / 100) * editInventory.sellingPrice;
                    return perc;
                  }
                  return "";
                })}
              <div style={{ display: "none" }}>
                {!isNull(editInventory) &&
                  (editInventory.costPrice =
                    Math.round(editInventory?.sellingPrice / 10) * 10)}
              </div>

              {vat && <tr>
                <td>{vat.label}</td>
                <td key="selling_perc">
                  <div className="line"></div>
                  {/* {editInventory?.sellingPrice} */}
                  {Math.round(parseFloat(editInventory?.sellingPrice) * 100) /
                    100}
                </td>
                <td key="line1_perc">
                  <div className="line"></div>
                  x
                </td>
                <td key="percent_perc">
                  <div className="line">
                  </div>
                  {vat.value}%
                </td>
                <td key="total_perc">
                  <div className="line"></div>
                  {!isNull(editInventory) && (editInventory.sellingPrice = calcVAT(editInventory?.sellingPrice))}

                </td>
              </tr>}
              <tr key="rounding-row">
                <td key="title_round">Rounding to 10 </td>
                {[
                  <td key="round_selling">
                    <div className="line"></div>
                    {Math.round(parseFloat(editInventory?.sellingPrice) * 100) /
                      100}
                  </td>,
                  <td key="line1_round">
                    <div className="line"></div>
                    <br />
                  </td>,
                  <td key="line2_round">
                    <div className="line"></div>
                    <br />
                  </td>,
                  <td key="total_round">
                    <div className="line"></div>
                    <b>{Math.round(editInventory?.sellingPrice / 10) * 10}</b>
                  </td>,
                ]}
              </tr>
            </tbody>
          </table>

          <div>
            <h2>
              Selling Price{" "}
              <b>{Math.round(editInventory?.sellingPrice / 10) * 10} DKK</b>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
  const inventory = (
    <div className="inventory">
      <h3>Inventory</h3>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            {/* <th>Selling Price</th> */}
            <th>Netto Price</th>
            <th>Quantity</th>
            <th>Remote Quantity</th>
            <th>Unit</th>
            <th>Available</th>
            <th></th>
          </tr>
          {productState.varient
            ?.filter((x) => x.checked)
            .map((varientElm) => {
              varientElm.branduuid = productState.brand.uuid
              varientElm.productuuid = productState.product.uuid
              varientElm.varientuuid = varientElm.uuid
              varientElm.sellingPrice =
                Math.round(parseFloat(varientElm.nettoPrice ?? 0) * 100) / 100;
              // varientElm.costPrice = 0
              // varientElm.sellingPriceTotal = parseFloat( varientElm.sellingPrice);

              return (
                <tr key={varientElm.id}>
                  <td>{varientElm.label}</td>
                  {/* <td>{varientElm.sellingPriceTotal}</td> */}
                  <td>{varientElm.nettoPrice}</td>
                  <td> {varientElm.quantity}</td>
                  <td> {varientElm.remoteQuantity}</td>
                  <td> {varientElm.unit.label}</td>
                  <td> {varientElm.quantity - varientElm.allocated}</td>
                  <td>
                    <div
                      className="edit-price"
                      onClick={() => {
                        const findPrice = prices.find(x => x.varient?.value === varientElm?.uuid);
                        if (findPrice) {
                          findPrice.order_value = 999999999;
                          varientElm.prices = findPrice;
                        } else {
                          varientElm.prices = {
                            uuid: uuid(),
                            siteId: siteId,
                            productTypeuuid: varientElm.productTypeId,
                            label: "Varient price",
                            rate: 0,
                            rateType: {
                              label: "Percent",
                              value: "percent"
                            },
                            order_value: 9999999999,
                            new: true,
                            varient: { value: varientElm.uuid }
                          }
                          prices.push(varientElm.prices)
                        }

                        setEditInventory(varientElm);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
  const shipping = (
    <div className="shipping">
      <h3>Shipping</h3>
      <div className="shipping-type">
        <label>Weight KG</label>
        <input
          defaultValue={productState.shipping.weight}
          onChange={(e) => {
            productState.shipping.weight = e.target.value;
          }}
        />
      </div>
      <div className="shipping-type">
        <label>Dimension L/W/H</label>
        <input
          defaultValue={productState.shipping.dimension}
          onChange={(e) => {
            productState.shipping.dimension = e.target.value;
          }}
        />
      </div>
    </div>
  );
  const type = (
    <div className="type">
      <h3>Type</h3>
      <div className="product-type">
        <label>Product type</label>
        <Select
          className="select"
          placeholder="Select..."
          options={configuration.productTypes}
          defaultValue={configuration.productTypes.find(
            (x) => x.uuid === productState.productTypeId
          )}
          onChange={(e) => {
            productState.productTypeId = e.uuid;
            setForceUpdate((f) => !f);
            // setProductedTypePrices(configuration.prices.filter(x => x.productTypeId === e.uuid).sort(compare))
          }}
        />
      </div>
    </div>
  );

  const available = (
    <div className="availability">
      <h3>Availability</h3>
      {availableState.map((element) => {
        let name_slug = slugify(element.name);
        return (
          <div key={name_slug} className="storage">
            <label htmlFor={name_slug}>{element.name}</label>
            <input
              onChange={(e) => {
                productState.available = name_slug;
                setForceUpdate((f) => (f ? false : true));
              }}
              id={name_slug}
              name={"available"}
              value={name_slug}
              checked={productState.available === name_slug}
              type={"radio"}
            />
          </div>
        );
      })}
    </div>
  );
  const wareh = (
    <div className="warehouse">
      <h3>Warehouse</h3>
      {warehouseState.map((element) => {
        let name_slug = slugify(element.name);
        let object = "remote";
        if (name_slug === "in-stock") {
          object = "inStock";
        }
        return (
          <div key={name_slug} className="storage">
            <label htmlFor={name_slug}>{element.name}</label>
            <input
              onChange={(e) => {
                productState.warehouse[object] = e.target.checked;
                setForceUpdate((f) => (f ? false : true));
              }}
              id={name_slug}
              name={name_slug}
              checked={productState.warehouse[object]}
              type={"checkbox"}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={`${"product overlay"}`}>
      <div className={`${editInventory && "mask"}`}></div>
      <div className="top">
        <div>
          <div className="title">
            <div
              onClick={() => {
                close();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h1>Product</h1>
          </div>
          <div className="cta">
            <div
              onClick={() => {
                saveProduct(productState);

              }}
              className="save"
            >
              Save
            </div>
            <div
              onClick={() => {
                deleteProduct(productState);
              }}
              className="delete"
            >
              Delete
            </div>
          </div>
        </div>
      </div>
      <div className="max-width">
        <div className="content">
          <div className="wrap-column">
            <div className="first-column">
              {generalInfo}
              {media}
              {attribute}
              {inventory}
            </div>
            <div className="second-column">
              {type}
              {productBox}
              {available}
              {wareh}
              {rating}
              {shipping}
            </div>
          </div>
          {editInventory && editInventoryComponent}
        </div>
      </div>
    </div>
  );
}
