// All are innocent unless proven guilty.

import Verify from "../../../../assets/VerifyMethods";
import { removeTags } from "../../../../assets/helperFunctions";

export function descriptionPassed({ showEn, showFo, beenEdit, newEvent }) {
  if (
    !beenEdit.textEn.headline &&
    !beenEdit.textEn.shortDescription &&
    !beenEdit.textEn.description &&
    !beenEdit.textFo.headline &&
    !beenEdit.textFo.shortDescription &&
    !beenEdit.textFo.description
  ) {
  } else {
    if (showEn) {
      if (
        Verify.notEmpty(newEvent.textEn.headline) === "" &&
        Verify.least3Char(newEvent.textEn.headline) === "" &&
        Verify.notEmpty(removeTags(newEvent.textEn.shortDescription)) === "" &&
        Verify.least10Char(removeTags(newEvent.textEn.shortDescription)) ===
          "" &&
        Verify.notEmpty(removeTags(newEvent.textEn.description)) === "" &&
        Verify.least70Char(removeTags(newEvent.textEn.description)) === ""
      ) {
      } else {
        return false;
      }
    }
    if (showFo) {
      if (
        Verify.notEmpty(newEvent.textFo.headline) === "" &&
        Verify.least3Char(newEvent.textFo.headline) === "" &&
        Verify.notEmpty(removeTags(newEvent.textFo.shortDescription)) === "" &&
        Verify.least10Char(removeTags(newEvent.textFo.shortDescription)) ===
          "" &&
        Verify.notEmpty(removeTags(newEvent.textFo.description)) === "" &&
        Verify.least70Char(removeTags(newEvent.textFo.description)) === ""
      ) {
      } else {
        return false;
      }
    }

    if (!showEn && !showFo) {
      return false;
    }
  }

  return true;
}

export function categoriesPassed({ showEn, showFo, beenEdit, newEvent }) {
  let passingVerify = true;

  if (beenEdit.categories && newEvent.categories.length === 0) {
    passingVerify = false;
  }

  return passingVerify;
}

export function datesPassed({ showEn, showFo, beenEdit, newEvent }) {
  const dates = newEvent.dates;
  if (beenEdit.dates)
    if (!dates.occurence) {
      return false;
    } else {
      switch (dates.occurence.value) {
        case "single":
          if (!dates.startDate || !dates.startTime || !dates.endTime)
            return false;
          break;
        case "many":
          if (
            !dates.startDate ||
            !dates.endDate ||
            !dates.startTime ||
            !dates.endTime
          )
            return false;
          break;
        case "specific":
          for (let i = 0; i < dates.specificDates.length; i++) {
            if (
              !dates.specificDates[i].date ||
              !dates.specificDates[i].startTime ||
              !dates.specificDates[i].endTime
            ) {
              return false;
            }
          }
          break;
        default:
          return false;
      }
    }

  return true;
}

export function pricesPassed({ showEn, showFo, beenEdit, newEvent }) {
  if (beenEdit.isFree)
    if (newEvent.isFree) return true;
    else {
      const prices = newEvent.prices.prices;
      for (let i = 0; i < prices.length; i++) {
        if (!prices[i].price || !prices[i].enLabel || !prices[i].foLabel) {
          return false;
        }
      }
    }

  return true;
}

export function imagesPassed({ showEn, showFo, beenEdit, newEvent }) {
  // IMAGES
  if (beenEdit.images.mainImages) {
    const mainImages = newEvent.images.mainImages;
    for (let i = 0; i < mainImages.length; i++)
      if (
        (showEn &&
          (mainImages[i].enText === undefined ||
            Verify.notEmpty(mainImages[i].enText) !== "")) ||
        (showFo &&
          (mainImages[i].foText === undefined ||
            Verify.notEmpty(mainImages[i].foText) !== ""))
      ) {
        return false;
      }
  }
  // VIDEO LINKS - It is optional...
  // if(beenEdit.urls.extraVideoLinks)
  // {
  //     const extraVideoLinks = newEvent.urls.extraVideoLinks;
  //     for(let i = 0; i < extraVideoLinks.length; i++)
  //     {
  //         if(Verify.notEmpty(extraVideoLinks[i].url) !== "")
  //         {
  //             return false;
  //         }
  //     }
  // }
  // INSTAGRAM
  const beenEditInsta = beenEdit.instagram;
  const instagram = newEvent.instagram;
  if (
    (Verify.notEmpty(instagram.hashtag) !== "" ||
      Verify.notEmpty(instagram.profile) !== "") &&
    beenEditInsta &&
    instagram.active
  ) {
    return false;
  }

  if (beenEditInsta && instagram.active) {
    const instaImages = instagram.images;
    if (instaImages.length === 0) return false;
    for (let i = 0; i < instaImages.length; i++) {
      if (
        (instaImages[i].media.id === undefined &&
          instaImages[i].media.src === undefined) ||
        Verify.notEmpty(instaImages[i].instaLink) !== ""
      ) {
        return false;
      }
    }
  }

  return true;
}

export function placePassed({ showEn, showFo, beenEdit, newEvent }) {
  if (newEvent.isPlace) {
    if (newEvent.place === undefined && beenEdit.isPlace) {
      return false;
    }
  } else {
    const address = newEvent.address;
    if (address !== undefined) {
      if (
        Verify.notEmpty(address.name) !== "" ||
        address.mapCenter === undefined ||
        address.region === undefined ||
        address.zipCode === undefined
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}

export function contactDetailsPassed({ showEn, showFo, beenEdit, newEvent }) {
  if (!newEvent.placeIsHost) {
    const contactDetailsBE = beenEdit.contactDetails;
    const contactDetails = newEvent.contactDetails;
    if (
      (Verify.notEmpty(contactDetails.company) !== "" &&
        contactDetailsBE.company) ||
      (Verify.notEmpty(contactDetails.name) !== "" && contactDetailsBE.name) ||
      (Verify.notEmpty(contactDetails.socialLink) !== "" &&
        contactDetailsBE.socialLink) ||
      (Verify.notEmpty(contactDetails.website) !== "" &&
        contactDetailsBE.website) ||
      ((Verify.notEmpty(contactDetails.email) !== "" ||
        Verify.validEmail(contactDetails.email) !== "") &&
        contactDetailsBE.email) ||
      contactDetails.countryCode === undefined ||
      (Verify.notEmpty(contactDetails.phoneNumber) &&
        contactDetailsBE.phoneNumber)
    ) {
      return false;
    }
  }

  return true;
}
