import { faArrowLeft, faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../../../shared/SharedPreviewDisplay.scss";
import env from "../../../../../environment.json";
import axios from "axios";
import GeneralOptions from "./GeneralOptions";
import { useRef } from "react";
import { useEventListener } from "../../../../../assets/helperFunctions";

export default function PreviewInfoscreen({
  id,
  close = () => void 0,
  site,
  editInfoscreen = () => void 0,
  openOverlay = () => void 0,
  // approvalStatusChange = (id, value) => void 0,
  // keyChange=(id,key,value) => void 0,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  // const [whatsonUrl, setWhatsonUrl] = useState("");
  // const [whatsonSiteId, setWhatsonSiteId] = useState(undefined);
  const [sideOption, setSideOption] = useState("general");
  const [data, setData] = useState(undefined);
  const [latestGet, setLatestGet] = useState(0);
  const [latestPageContent, setLatestPageContent] = useState(0);
  let completeUrl = "";
  if (site) {
    const { protocol, domain_name } = site;
    completeUrl = protocol + domain_name + "/?infoscreenID=1";
  }

  useEffect(() => {
    getWhatson(true, (response) => {
      setData(response.data);
      setLatestGet(new Date().getTime());
    });
  }, [selectedLanguage, latestPageContent]);

  const getWhatson = (includeSelectedLang = true, afterFunc = () => void 0) => {
    axios
      .post(`${env.protocol}${env.env}/api/public/infoscreen/GetInfoscreen.php`, {
        id,
      })
      .then((response) => {
        afterFunc(response);
      })
      .catch((error) => console.error(error));
  };

  const changeValue = (label, value) => {
    axios
      .post(
        env.protocol + env.env + "/api/secured/infoscreen/ChangeInfoscreenValues",
        {
          user: sessionStorage.getItem("vfiUser"),
          id,
          label,
          value,
        }
      )
      .then((response) => {
        setLatestPageContent(new Date().getTime());
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="preview-display">
      <div className="left-side">
        <div className="header">
          <div className="header-content">
            <div className="go-back" onClick={close}>
              <FontAwesomeIcon icon={faArrowLeft} /> Infoscreen
            </div>
            <div
              className={
                "one-language" + (selectedLanguage === "en" ? " selected" : "")
              }
              onClick={() => setSelectedLanguage("en")}
            >
              EN
            </div>
            <div
              className={
                "one-language" + (selectedLanguage === "fo" ? " selected" : "")
              }
              onClick={() => setSelectedLanguage("fo")}
            >
              FO
            </div>
          </div>
        </div>
        <div className="display-section-content">
          <div className="display-content-top">
            <div className="edit-button" onClick={() => editInfoscreen(id)}>
              EDIT
            </div>
            <a className="external-link" href={completeUrl} target="_blank">
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
          <div
            className="page-content aspect-16-9"
            style={{ overflow: "hidden" }}
          >
            {completeUrl && (
              <iframe
                key={latestPageContent}
                className="iframe"
                src={completeUrl + "?disabled"}
              />
            )}
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="options-section-header">
          <div
            className={"option" + (sideOption === "general" ? " selected" : "")}
            onClick={() => {
              setSideOption("general");
            }}
          >
            General
          </div>
        </div>
        {sideOption === "general" && (
          <GeneralOptions
            key={latestGet + selectedLanguage}
            className="section"
            data={data}
            changeValue={changeValue}
            onChange={setData}
            openOverlay={openOverlay}
            // approvalStatusChange={approvalStatusChange}
          />
        )}
      </div>
    </div>
  );
}
