import React, { Component } from "react";
import env from "../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
// import "./WhatsonDisplay.scss";
import "../shared/SharedListDisplay.scss";
import WhatsonList from "./WhatsonList";
import WhatsonCreateNew from "./WhatsonCreateNew";
import { sortList, whatsonContentPush } from "../../../assets/helperFunctions";
import language from "./language.json";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import PreviewWhatson from "../PreviewWhatson/PreviewWhatson";
import {
  withAllOfStore,
  withUserStore,
} from "src/Components/ZustandStores/allOfStore";
import Leaflet from "../Leaflet/Leaflet";

const level = 1;

class WhatsonDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: "list",
      addFilterDroppedDown: false,
      statusFilter: "all",
      searchValue: "",
      checkboxFilter: {},
      blurScreen: false,
      filterOpened: false,
      showAmount: 30,
      editWhatsonId: 0,
    };

    this.whatsonContents = [];
    this.sortedBy = "date";

    this.allAmount = 0;
    this.publishedAmount = 0;
    this.draftsAmount = 0;
    this.awatingApprovalAmount = 0;
    this.deletedAmount = 0;

    this.axios = require("axios");
    this.whatSelected = "";

    this.sortList = this.sortList.bind(this);
    this.editContent = this.editContent.bind(this);
    this.approvalStatusChange = this.approvalStatusChange.bind(this);
    this.selectById = this.selectById.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.goToList = this.goToList.bind(this);
    this.pushOneAndGoToList = this.pushOneAndGoToList.bind(this);
    this.updateOneAndGoToList = this.updateOneAndGoToList.bind(this);
    this.autoSave = this.autoSave.bind(this);
    this.keyChange = this.keyChange.bind(this);

    this.language = language[this.props.language];

    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    this.filterContent = [
      {
        headline: "Date",
        value: [{ startDate: false, endDate: false }], //content, start and enddate
        style: "date",
      },
    ];
  }

  componentDidMount() {
    if (this.props.site.name) {
      this.updateFirst();
    }
  }

  getFunctionality = (whatWhatson) => {
    let endpoint = "/not/an/endpoint";
    switch (whatWhatson.nav) {
      case "events":
        this.whatSelected = "Event";
        this.approvalRequired = true;
        endpoint = "/api/whatson/events/GetEventsList?user_role=collaborator";
        break;
      case "tours":
        this.whatSelected = "Tour";
        this.approvalRequired = true;
        endpoint =
          "/api/whatson/tours/GetToursList?ignore_author&user_role=collaborator";
        break;
      case "tour_providers":
        this.whatSelected = "Tour provider";
        this.approvalRequired = true;
        endpoint =
          "/api/secured/whatson/touroperators/GetTourOperatorsList?user_role=collaborator";
        break;
      case "hiking":
        this.whatSelected = "Hiking";
        this.approvalRequired = false;
        endpoint = "/api/whatson/hiking/GetHikingList";
        break;
      case "places":
        this.whatSelected = "Place";
        this.approvalRequired = true;
        endpoint =
          "/api/whatson/places/GetPlacesList?ignore_author&user_role=collaborator";
        break;
      default:
        break;
    }

    this.setState({ blurScreen: true });
    this.axios
      .post(env.protocol + env.env + endpoint)
      .then((response) => {
        this.cleanValues();

        if (Array.isArray(response.data)) {
          response.data.forEach((e) => {
            // this.addToAmounts(e);
            if (whatWhatson === "hiking") {
              const newHiking = whatsonContentPush(e);
              newHiking.location = e.location;
              this.whatsonContents.push(newHiking);
            } else {
              e.location = e.place_headline;
              this.whatsonContents.push(whatsonContentPush(e));
            }
          });
        }

        const permissionWhatsonTypes = {
          Event: "events",
          Place: "places",
          Tour: "tours",
          "Tour provider": "tour_operators",
          Hiking: undefined,
        };

        const permissionType = permissionWhatsonTypes[this.whatSelected];

        this.whatsonContents = this.whatsonContents.filter(
          (e) =>
            // this.props.store.permCheckFunc({
            //   minimumRole: "Collaborator",
            //   whereRule: "any",
            //   type: permissionType,
            //   ignoreCheck: permissionType === undefined,
            //   id: e.id,
            // })
            true
        );

        this.whatsonContents.forEach((e) => this.addToAmounts(e));

        this.sortList(this.sortedBy);
        this.setState({ blurScreen: false, showAmount: 30 });

        // Direct to create new event
        if (this.props.createNew.toLowerCase() === this.whatSelected) {
          this.createNew(this.props.createNew);
          this.props.SetState("createNew", "");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  updateFirst = () => {
    // if (this.props.site.name) {
    if (this.props.store.route.length >= 2) {
      // this.getFunctionality(this.props.site.name.toLowerCase());
      this.getFunctionality(this.props.store.route[1]);

      this.setState({ display: "list" });
    }

    if (
      this.props.store.route.length > 2 &&
      this.props.store.route[2]?.nav === "add"
    ) {
      this.setState({
        display: "createNew",
      });
      this.props.store.setRouter({
        route: [this.props.store.route[0], this.props.store.route[1]],
      });
    } else {
      this.setState({ display: "list" });
    }
  };

  componentDidUpdate(prevProps) {
    //Updatera listan av events etc um site broytist
    if (prevProps.site.id !== this.props.site.id) {
      this.updateFirst();
    }

    if (this.props.showMore === true) {
      // if (this.state.showAmount < this.mediaContents.length) {
      this.setState({ showAmount: this.state.showAmount + 15 });
      this.props.setBottom(false);
      // }
    }
  }

  cleanValues() {
    this.allAmount = 0;
    this.publishedAmount = 0;
    this.draftsAmount = 0;
    this.awatingApprovalAmount = 0;
    this.deletedAmount = 0;
    this.whatsonContents.splice(0, this.whatsonContents.length);
  }

  addToAmounts(e) {
    if (e.current_status === "draft") {
      this.allAmount++;
      this.draftsAmount++;
    } else if (e.current_status === "published") {
      this.allAmount++;
      this.publishedAmount++;
      if (e.approval_status === "awaiting approval") {
        this.awatingApprovalAmount++;
      }
    } else if (e.current_status === "deleted") {
      this.deletedAmount++;
    }
  }

  recountAmounts() {
    this.allAmount = 0;
    this.publishedAmount = 0;
    this.draftsAmount = 0;
    this.awatingApprovalAmount = 0;
    this.deletedAmount = 0;
    this.whatsonContents.forEach((e) => {
      if (e.currentStatus === "draft") {
        this.allAmount++;
        this.draftsAmount++;
      } else if (e.currentStatus === "published") {
        this.allAmount++;
        this.publishedAmount++;
        if (e.approvalStatus === "awaiting approval") {
          this.awatingApprovalAmount++;
        }
      } else if (e.currentStatus === "deleted") {
        this.deletedAmount++;
      }
    });
  }

  createNew = (name) => {
    //Function used to navigate from home to new event, tour etc
    this.setState({
      display: "createNew",
    });
    if (name.toLowerCase() === "events") {
      this.whatSelected = "Event";
    }
    if (name.toLowerCase() === "tours") {
      this.whatSelected = "Tour";
    }
    if (name.toLowerCase() === "hiking") {
      this.whatSelected = "Hiking";
    }
    if (name.toLowerCase() === "places") {
      this.whatSelected = "Place";
    }
  };

  sortList(sortBy) {
    this.whatsonContents = sortList(
      sortBy,
      this.whatsonContents,
      this.sortedBy === sortBy
    );
    let approvals = [];
    this.whatsonContents.forEach((e) => {
      approvals.push(e.approvalStatus);
    });
    this.sortedBy = sortBy;
    this.setState({});
  }

  // editingContent = (whatson, editWhat) => { //Function used to navigate from home to event, tour etc
  //   this.whatSelected = whatson;
  //   this.editContent(editWhat);
  // }

  editContent(editWhat) {
    this.setState({
      display: "editOne",
      contentToEdit: editWhat,
      editWhatsonId: editWhat.data.id,
    });
  }

  approvalStatusChange(id, value) {
    this.whatsonContents.forEach((element, i) => {
      if (element.id === id) {
        if (this.whatsonContents[i].approvalStatus === "awaiting approval") {
          this.awatingApprovalAmount--;
        }

        this.whatsonContents[i].approvalStatus = value;
      }
    });
    this.setState({});
  }

  keyChange(id, key, value) {
    this.whatsonContents.forEach((element, i) => {
      if (element.id === id) {
        this.whatsonContents[i][key] = value;
      }
    });
    this.setState({});
  }

  selectById(id) {
    this.whatsonContents.forEach((element, i) => {
      if (element.id === id) {
        this.whatsonContents[i].selected = !this.whatsonContents[i].selected;
        this.setState({});
      }
    });
  }

  selectAll(bool) {
    var contents = this.whatsonContents;
    contents = this.statusFilter(contents);
    contents = this.searchFilter(contents);
    contents.forEach((element, i) => {
      contents[i].selected = bool;
    });
    this.setState({});
  }

  deselectAll() {
    this.whatsonContents.forEach((element, i) => {
      this.whatsonContents[i].selected = false;
    });
    this.setState({});
  }

  deleteSelected() {
    let selectedIds = [];

    this.whatsonContents.forEach((element) => {
      if (element.selected) {
        selectedIds.push(element.id);
      }
    });

    let link = "";

    if (this.whatSelected === "Event") {
      link =
        env.protocol + env.env + "/api/secured/whatson/events/DeleteEvents";
    }
    if (this.whatSelected === "Place") {
      link =
        env.protocol + env.env + "/api/secured/whatson/places/DeletePlaces";
    }
    if (this.whatSelected === "Hiking") {
      link =
        env.protocol + env.env + "/api/secured/whatson/hiking/DeleteHiking";
    }
    if (this.whatSelected === "Tour") {
      link = env.protocol + env.env + "/api/secured/whatson/tours/DeleteTours";
    }

    this.axios
      .post(link, { ids: selectedIds })
      .then((response) => {
        this.whatsonContents.forEach((element, i) => {
          if (selectedIds.find((e) => e === element.id)) {
            this.whatsonContents[i].currentStatus = "deleted";
          }
          this.whatsonContents[i].selected = false;
        });
        this.recountAmounts();
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  goToList() {
    this.setState({ display: "list" });
  }

  pushOneAndGoToList(newValue, goBack = true) {
    // this.whatsonContents.unshift(whatsonContentPush(newValue));
    this.autoSave(newValue);
    this.recountAmounts();
    if (goBack) this.setState({ display: "list" });
  }

  updateOneAndGoToList(id, newValue, goBack = true) {
    this.whatsonContents.forEach((element, i) => {
      if (element.id === id) {
        this.whatsonContents[i] = whatsonContentPush(newValue);
      }
    });
    this.recountAmounts();
    if (goBack) this.setState({ display: "list" });
  }

  /**
   * Saves whatson content to the list
   *
   * E.g. newTourOperator data is saved to the tour operators list
   *
   * @param 	{object} 	content 											Element content (e.g. new tour operator data)
   */
  autoSave(content) {
    const isNewValue =
      this.whatsonContents.find(
        (f) => parseInt(f.id) === parseInt(content.id)
      ) === undefined;

    if (isNewValue) {
      this.whatsonContents.unshift(whatsonContentPush(content));
      this.setState({
        contentToEdit: content,
        // display: "editOne"
      });
    } else {
      this.whatsonContents.forEach((element, i) => {
        if (element.id === content.id) {
          this.whatsonContents[i] = whatsonContentPush(content);
        }
      });
    }
    this.recountAmounts();
  }

  statusFilter(contents) {
    if (this.state.statusFilter === "all") {
      return contents.filter((e) => {
        return e.currentStatus !== "deleted";
      });
    }
    if (this.state.statusFilter === "published") {
      return contents.filter((e) => {
        return e.currentStatus === "published";
      });
    }
    if (this.state.statusFilter === "drafts") {
      return contents.filter((e) => {
        return e.currentStatus === "draft";
      });
    }
    if (this.state.statusFilter === "awaiting approval") {
      return contents.filter((e) => {
        return (
          e.currentStatus === "published" &&
          e.approvalStatus === "awaiting approval"
        );
      });
    }
    if (this.state.statusFilter === "deleted") {
      return contents.filter((e) => {
        return e.currentStatus === "deleted";
      });
    }
    return contents;
  }

  searchFilter(list) {
    var returnList = [];
    const filter = this.state.searchValue.toLowerCase();
    //const filter = this.props.getFilterData.searchValue ? this.props.getFilterData.searchValue.toLowerCase() : "";

    if (filter === "") return list;
    else
      list.forEach((element, i) => {
        if (this.searchJson(element, filter)) {
          returnList.push(element);
        }
      });

    return returnList;
  }

  searchJson(json, filter) {
    for (var key in json) {
      if (typeof json[key] === "string" || json[key] instanceof String) {
        if (json[key].toLowerCase().search(filter) !== -1) {
          return true;
        }
      } else {
        if (typeof json[key] === "object" || json[key] instanceof Object);
        if (this.searchJson(json[key], filter)) {
          return true;
        }
      }
    }
    return false;
  }

  dateFilter(list) {
    // var fromDate = this.props.getFilterData.startDate;
    // var toDate = this.props.getFilterData.endDate;

    var fromDate = this.filterContent[0].value[0].startDate;
    var toDate = this.filterContent[0].value[0].endDate;
    if (toDate) toDate = new Date(toDate).setDate(toDate.getDate() + 1);

    if (fromDate && toDate) {
      return list.filter((e) => {
        const theDate = new Date(e.date);
        return theDate >= fromDate && theDate <= toDate;
      });
    } else if (fromDate) {
      return list.filter((e) => {
        const theDate = new Date(e.date);
        return theDate >= fromDate;
      });
    } else if (toDate) {
      return list.filter((e) => {
        const theDate = new Date(e.date);
        return theDate <= toDate;
      });
    } else {
      return list;
    }
  }

  onDateChange = (whatdate, date) => {
    this.filterContent[0].value[0][whatdate] = date;
    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  render() {
    //const filterHidden = this.state.addFilterDroppedDown ? "" : "hidden";
    const filterHidden =
      this.props.addFilterDroppedDown[0] &&
      this.props.addFilterDroppedDown[1] === "whatsonDisplay"
        ? ""
        : "hidden";
    let contentSelected = [];
    this.whatsonContents.forEach((element) => {
      if (element.selected) {
        contentSelected.push(element);
      }
    });
    let contents = Object.assign([], this.whatsonContents);
    contents = this.statusFilter(contents);
    contents = this.searchFilter(contents);
    contents = this.dateFilter(contents);

    if (this.state.display === "list")
      return (
        <>
          {/* <Leaflet
          title={"What's On"}
          sections={[
            {
              title: "Events",
              views: [
                "Events",
                "Places",
              ],
            },
            {
              title: "Tours",
              views: [
                "Tours",
                "Tour Operator",
              ],
            },
            {
              title: "Hiking",
              views: [
                "Hiking",
              ],
            },
          ]}
          selectedView={this.props.store.route[level].displayName}
          setSelectedView={(view) => {
            this.props.store.navTo(level, {
              nav: view.replace(' ', '_').toLowerCase(),
              sidebar: '',
              displayName: view,
            });
        }}
      /> */}
          <div className="whatson-display">
            <div className="header-panel">
              <div className="header-title">
                <h1>
                  {this.props.site.name
                    ? this.language[
                        this.props.site.name.toLowerCase().replace(" ", "_")
                      ].top.headline
                    : ""}
                </h1>
              </div>
              <div className="status-filter-parent">
                <span
                  className={
                    this.state.statusFilter === "all"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    this.setState({ statusFilter: "all" });
                    this.deselectAll();
                  }}
                >
                  {this.language.top.status.all + " "}
                  <span className="status-filter-amount">{this.allAmount}</span>
                </span>
                |
                <span
                  className={
                    this.state.statusFilter === "published"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    this.setState({ statusFilter: "published" });
                    this.deselectAll();
                  }}
                >
                  {this.language.top.status.published + " "}
                  <span className="status-filter-amount">
                    {this.publishedAmount}
                  </span>
                </span>
                |
                <span
                  className={
                    this.state.statusFilter === "drafts"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    this.setState({ statusFilter: "drafts" });
                    this.deselectAll();
                  }}
                >
                  {this.language.top.status.drafts + " "}
                  <span className="status-filter-amount">
                    {this.draftsAmount}
                  </span>
                </span>
                |
                <span
                  className={
                    this.state.statusFilter === "awaiting approval"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    this.setState({ statusFilter: "awaiting approval" });
                    this.deselectAll();
                  }}
                >
                  {this.language.top.status.awaiting_approval + " "}
                  <span className="status-filter-amount">
                    {this.awatingApprovalAmount}
                  </span>
                </span>
                |
                <span
                  className={
                    this.state.statusFilter === "deleted"
                      ? "status-filter selected"
                      : "status-filter"
                  }
                  onClick={() => {
                    this.setState({ statusFilter: "deleted" });
                    this.deselectAll();
                  }}
                >
                  {this.language.top.status.deleted + " "}
                  <span className="status-filter-amount">
                    {this.deletedAmount}
                  </span>
                </span>
              </div>
              <div className="add-new-media-parent">
                <div
                  className="add-new-media"
                  onClick={() =>
                    this.setState({
                      display: "createNew",
                    })
                  }
                >
                  <div className="text">
                    {this.props.site.name
                      ? this.language[
                          this.props.site.name.toLowerCase().replace(" ", "_")
                        ].top.add_new
                      : ""}
                  </div>
                </div>
              </div>
              <div className="search-parent">
                <div className="search">
                  <VfiInputText
                    value={this.state.searchValue}
                    placeholder="Search media..."
                    onChange={(e) => {
                      this.setState({ searchValue: e.target.value });
                      this.deselectAll();
                    }}
                    isSearch={true}
                  />
                </div>
              </div>
              <div className="all-sort-and-filter">
                <div
                  className={"filter-button " + filterHidden}
                  onClick={() => {
                    this.setState({
                      //addFilterDroppedDown: !this.state.addFilterDroppedDown
                    });

                    // this.props.openCloseFilter(!this.props.addFilterDroppedDown[0], "activityDisplay")
                    // this.props.openCloseFilter(!this.state.filterOpened, "whatsonDisplay")

                    this.props.openCloseFilter(
                      [!this.props.addFilterDroppedDown[0], "whatsonDisplay"],
                      {
                        type: "whatson",
                        search: false,
                        style: {},
                        content: this.filterContent,
                        executeDate: (whatdate, date) => {
                          // When date changes
                          this.onDateChange(whatdate, date);
                        },
                      }
                    );

                    // this.setState({ filterOpened: !this.state.filterOpened });
                    // this.props.setFilterData("activity","psychopaths", ["Joker","Patrick Bateman","Jack Torrance"], oneYearAgo, new Date());
                    // this.props.SetFilterData({}, "activity", "psychopaths", undefined, oneYearAgo, new Date());
                  }}
                >
                  <span className="text">{this.language.top.filter}</span>
                  <FontAwesomeIcon
                    className="slide-icon"
                    icon={faSlidersH}
                    flip={"vertical"}
                  />
                </div>
                <div
                  className={
                    contentSelected.length > 0
                      ? "delete-selected-button"
                      : "delete-selected-button hidden"
                  }
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete the selected rows?"
                      )
                    ) {
                      this.deleteSelected();
                    }
                  }}
                >
                  Delete selected
                </div>
              </div>
            </div>
            <WhatsonList
              list={contents}
              searchFilter={this.state.searchValue}
              sortList={this.sortList}
              checkboxFilter={this.state.checkboxFilter}
              editContent={this.editContent}
              whatWhatson={this.whatSelected}
              approvalStatusChange={this.approvalStatusChange}
              selectById={this.selectById}
              selectAll={this.selectAll}
              approvalRequired={this.approvalRequired}
              language={this.props.language}
              showAmount={this.state.showAmount}
            />
            <div
              className={
                this.state.blurScreen ? "blur-screen" : "blur-screen hidden"
              }
            />
          </div>
        </>
      );

    if (
      this.state.display === "createNew" /*|| this.state.display === "editOne"*/
    ) {
      return (
        <WhatsonCreateNew
          newWhat={this.whatSelected.toLocaleLowerCase()}
          updateData={
            this.state.display === "editOne"
              ? this.state.contentToEdit
              : undefined
          }
          pushOneAndGoToList={this.pushOneAndGoToList}
          updateOneAndGoToList={this.updateOneAndGoToList}
          autoSave={this.autoSave}
          openOverlay={this.props.openOverlay}
          addOne={this.props.addOne}
          addContent={this.props.addContent}
          setSiteRef={this.props.setSiteRef}
          language={this.props.language}
          close={this.goToList}
        />
      );
    }

    if (this.state.display === "editOne") {
      return (
        <PreviewWhatson
          whatWhatson={this.whatSelected.toLocaleLowerCase()}
          whatsonId={this.state.editWhatsonId}
          close={this.goToList}
          openOverlay={this.props.openOverlay}
          setSiteRef={this.props.setSiteRef}
          addOne={this.props.addOne}
          approvalStatusChange={this.approvalStatusChange}
          autoSave={this.autoSave}
        />
      );
    }

    return "";
  }
}

export default withAllOfStore(WhatsonDisplay);
