import React, { Component } from "react";
import "./VfiInputText.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faCheck,
  faTimes as faTimes2,
} from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { slugify, twoDigits } from "../helperFunctions";
/**
 * VFI Input Text
 * @augments {Component<Props, State>}
 */
class VfiInputText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: [],
    };

    this.ref = React.createRef(null);

    this.keyHandle = this.keyHandle.bind(this);
  }

  componentDidMount() {
    this.errorCheck(
      this.props.value
        ? this.props.value
        : this.props.defaultValue
        ? this.props.defaultValue
        : ""
    );

    if (this.props.onMount) this.props.onMount(this.props.inputRef);
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.errorCheck(
        this.props.value
          ? this.props.value
          : this.props.defaultValue
          ? this.props.defaultValue
          : ""
      );
    }
  }

  thisChange(e) {
    this.errorCheck(e.target.value);
  }

  errorCheck(value) {
    let errors = [];
    if (this.props.errorCheckSequence) {
      this.props.errorCheckSequence.forEach((element) => {
        // If a function has been passed...
				let error = "";
				if(typeof element === 'function'){
					error = element(value);
				} else if(typeof element === 'object') {
					// If an object has passed, we expect the following structure:
					// {function:validationFunction(), args:[argument1, argument2...]}
					error = element.function(value, ...element.args);
				}
				
        if (error !== "" && error !== undefined) {
          errors.push(error);
        }
      });
    }
    this.setState({ errorMessages: errors });
  }

  keyHandle(e) {
    if (e.keyCode === 27) {
      if (this.props.isSearch) this.props.onChange({ target: { value: "" } });
    }
    if (e.keyCode === 13 && this.props.onEnter) {
      this.props.onEnter(e);
    }
    if (e.keyCode === 27 && this.props.onEscape) {
      this.props.onEscape(e);
    }
  }

  render() {
    const inputError = this.state.errorMessages.length > 0 ? true : false;
    const inputClassName = inputError
      ? this.props.hideError
        ? ""
        : "error"
      : this.props.checkShow
      ? "check"
      : "";

    return (
      <div
        style={this.props.style}
        className={
          this.props.className !== undefined
            ? this.props.className + " vfi-input-text"
            : "vfi-input-text"
        }
      >
        <label>
          <input
            ref={this.props.inputRef ?? this.ref}
            name={this.props.name}
            type={this.props.type ?? "text"}
            value={this.props.value}
            defaultValue={this.props.defaultValue}
            disabled={this.props.disabled}
            onChange={(e) => {
              if (this.props.inputType !== undefined) {
                if (this.props.inputType.toLowerCase() === "int")
                  e.target.value = e.target.value.replace(/\D/, "");

                if (this.props.inputType.toLowerCase() === "2digits") {
                  // const RxFinalForm = /^(?:\d+(?:\.\d{0,2})?|\.\d{1,2})$/;
                  // const RxRmvInvalid = /[^\d.]+/g;
                  // const RxPartialForm = /^(\d+(?:\.\d{0,2})?|\.\d{0,2})?.*?$/;

                  // var ob = e.target;
                  // var sContent = ob.value;

                  // if (!RxFinalForm.test(e.target.value)) {
                  //   sContent = sContent.replace(",", ".");
                  //   sContent = sContent.replace(RxRmvInvalid, "");
                  //   sContent = sContent.replace(RxPartialForm, "$1");

                  //   if (ob.value !== sContent) ob.value = sContent;
                  // }

                  e.target.value = twoDigits(e);
                }

                if (this.props.inputType.toLowerCase() === "slug") {
                  e.target.value = slugify(e.target.value);
                }
              }

              if (this.props.maxLength !== undefined) {
                e.target.value = e.target.value.substring(
                  0,
                  this.props.maxLength
                );
              }

              this.thisChange(e);
              if (this.props.onChange) {
                this.props.onChange(e);
              }
            }}
            onBlur={(e) => {
              if (this.props.onBlur) this.props.onBlur(e);
            }}
            onFocus={(e) => {
              if (this.props.onFocus) this.props.onFocus(e);
            }}
            className={inputClassName}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onKeyDown={this.keyHandle}
          />
          {this.props.value === "" || !this.props.isSearch ? (
            <div className="right-icon">
              {inputError && !this.props.hideError ? (
                <FontAwesomeIcon icon={faExclamation} />
              ) : this.props.checkShow ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : this.props.isSearch ? (
                <FontAwesomeIcon icon={faSearch} />
              ) : (
                ""
              )}
            </div>
          ) : (
            <div
              className="remove-text-button"
              onClick={() => {
                this.props.onChange({ target: { value: "" } });
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
          {this.props.customRightSide && (
            <div className="custom-right-side">
              {this.props.customRightSide}
            </div>
          )}
          {this.props.customUnder && (
            <div className="custom-under">{this.props.customUnder}</div>
          )}
          {this.props.maxLength ? (
            <div className="max-chars">
              {this.props.value.length}/{this.props.maxLength}
            </div>
          ) : (
            ""
          )}
          {this.props.onSubmit && (
            <div className="submit-buttons">
              <button
                className="yes"
                onClick={() => {
                  this.props.onSubmit({
                    target: (this.props.inputRef ?? this.ref)?.current,
                    checked: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                className="no"
                onClick={() => {
                  this.props.onSubmit({
                    target: (this.props.inputRef ?? this.ref)?.current,
                    checked: false,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTimes2} />
              </button>
            </div>
          )}
          <div className="errors">
            {this.state.errorMessages.map((element, i) => {
              return !this.props.hideError ? (
                <div className="error" key={i}>
                  {element}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        </label>
      </div>
    );
  }
}

export default VfiInputText;
