import React, { CSSProperties, ReactNode } from "react";
import styles from "./squareButton.module.scss";

interface SquareButtonProps {
  children: ReactNode;
  onClick: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

function SquareButton({
  children,
  onClick,
  type = "button",
  disabled = false,
  style,
  className,
}: // ...props
SquareButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={styles.button + " " + `${className ? className : ""}`}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
}

export default SquareButton;
