// All are innocent unless proven guilty.

import Verify from "../../../../assets/VerifyMethods";
import { removeTags } from "../../../../assets/helperFunctions";

export function descriptionPassed({ showEn, showFo, beenEdit, newPlace }) {
  if (
    !beenEdit.textEn.headline &&
    !beenEdit.textEn.shortDescription &&
    !beenEdit.textEn.description &&
    !beenEdit.textFo.headline &&
    !beenEdit.textFo.shortDescription &&
    !beenEdit.textFo.description
  ) {
  } else {
    if (showEn) {
      if (
        Verify.notEmpty(newPlace.textEn.headline) === "" &&
        Verify.least3Char(newPlace.textEn.headline) === "" &&
        Verify.notEmpty(removeTags(newPlace.textEn.shortDescription)) === "" &&
        Verify.least10Char(removeTags(newPlace.textEn.shortDescription)) ===
          "" &&
        Verify.notEmpty(removeTags(newPlace.textEn.description)) === "" &&
        Verify.least70Char(removeTags(newPlace.textEn.description)) === ""
      ) {
      } else {
        return false;
      }
    }
    if (showFo) {
      if (
        Verify.notEmpty(newPlace.textFo.headline) === "" &&
        Verify.least3Char(newPlace.textFo.headline) === "" &&
        Verify.notEmpty(removeTags(newPlace.textFo.shortDescription)) === "" &&
        Verify.least10Char(removeTags(newPlace.textFo.shortDescription)) ===
          "" &&
        Verify.notEmpty(removeTags(newPlace.textFo.description)) === "" &&
        Verify.least70Char(removeTags(newPlace.textFo.description)) === ""
      ) {
      } else {
        return false;
      }
    }

    if (!showEn && !showFo) {
      return false;
    }
  }

  return true;
}

export function categoriesPassed({ beenEdit, newPlace }) {
  let passingVerify = true;

  if (beenEdit.categories && newPlace.categories.length === 0) {
    passingVerify = false;
  }

  return passingVerify;
}

/**
 * Validate amenities input
 * (currently returns true on anything, since there is no validation to be done)
 *
 * @param   {object}  beenEdit          Object keeping track of edited fields
 * @param   {object}  newPlace          Object with new place information
 *
 * @returns   bool                      Validation success
 *
 * @author          Pætur Mortensen
 */
export function amenitiesPassed({ beenEdit, newPlace }) {
  return true;
}

export function openingHoursPassed({ beenEdit, newPlace }) {
  if (beenEdit.openingHours) {
    const openingHours = newPlace.openingHours;
    for (let i = 0; i < openingHours.length; i++) {
      if (
        openingHours[i].day === "" ||
        !openingHours[i].from.hour.value ||
        !openingHours[i].from.minutes.value ||
        !openingHours[i].to.hour.value ||
        !openingHours[i].to.minutes.value
      ) {
        return false;
      }
    }
  }

  return true;
}

export function permissionPassed({ beenEdit, newPlace }) {
  // --- Both permissions have become optional ---
  // const {minAge:minAgeBE, capacity:capacityBE} = beenEdit.placeRestrictions;
  // const {minAge, capacity} = newPlace.placeRestrictions;

  // if((minAgeBE && minAge === "") || (capacityBE && capacity === ""))
  // {
  //     return false;
  // }

  return true;
}

export function imagesPassed({ showEn, showFo, beenEdit, newPlace }) {
  // IMAGES
  if (beenEdit.images.mainImages) {
    const mainImages = newPlace.images.mainImages;
    for (let i = 0; i < mainImages.length; i++)
      if (
        (showEn &&
          (mainImages[i].enText === undefined ||
            Verify.notEmpty(mainImages[i].enText) !== "")) ||
        (showFo &&
          (mainImages[i].foText === undefined ||
            Verify.notEmpty(mainImages[i].foText) !== ""))
      ) {
        return false;
      }
  }
  // INSTAGRAM
  const beenEditInsta = beenEdit.instagram;
  const instagram = newPlace.instagram;
  if (
    (Verify.notEmpty(instagram.hashtag) !== "" ||
      Verify.notEmpty(instagram.profile) !== "") &&
    beenEditInsta &&
    instagram.active
  ) {
    return false;
  }

  if (beenEditInsta && instagram.active) {
    const instaImages = instagram.images;
    if (instaImages.length === 0) return false;
    for (let i = 0; i < instaImages.length; i++) {
      if (
        (instaImages[i].media.id === undefined &&
          instaImages[i].media.src === undefined) ||
        Verify.notEmpty(instaImages[i].instaLink) !== ""
      ) {
        return false;
      }
    }
  }

  return true;
}

export function placePassed({ beenEdit, newPlace }) {
  if (beenEdit.address) {
    const address = newPlace.address;
    if (address !== undefined) {
      if (
        Verify.notEmpty(address.name) !== "" ||
        address.mapCenter === undefined ||
        address.region === undefined ||
        address.zipCode === undefined ||
        address.zipCode === 0
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}

export function contactDetailsPassed({ beenEdit, newPlace }) {
  if (!newPlace.placeIsHost) {
    const contactDetailsBE = beenEdit.contactDetails;
    const contactDetails = newPlace.contactDetails;
    if (
      // (Verify.notEmpty(contactDetails.company) !== "" &&
      //   contactDetailsBE.company) ||
      (Verify.notEmpty(contactDetails.name) !== "" && contactDetailsBE.name) ||
      (Verify.notEmpty(contactDetails.socialLink) !== "" &&
        contactDetailsBE.socialLink) ||
      (Verify.notEmpty(contactDetails.website) !== "" &&
        contactDetailsBE.website) ||
      ((Verify.notEmpty(contactDetails.email) !== "" ||
        Verify.validEmail(contactDetails.email) !== "") &&
        contactDetailsBE.email) ||
      contactDetails.countryCode === undefined ||
      (Verify.notEmpty(contactDetails.phoneNumber) &&
        contactDetailsBE.phoneNumber)
    ) {
      return false;
    }
  }

  return true;
}
