import axios from "axios";
import env from "../../environment.json";
import PropTypes from "prop-types";

const sitesStore = (set) => ({
  allSites: [],
  allSitesNoRootPages: [],
  allSitesLoaded: false,
  getAllSites: (callback = () => void 0) => {
    set({ allSitesLoaded: false });
    axios
      .post(`${env.protocol}${env.env}/api/public/GetSites.php`, {
        userId: sessionStorage.getItem("vfiUser"),
        // ignoreRootPages: false,
      })
      .then((response) => {
        const data = response.data;
        set({
          allSites: data,
          allSitesNoRootPages: data.filter((d) => !d.rootPage),
          allSitesLoaded: true,
        });
        callback(response);
      })
      .catch((error) => console.error(error));
  },
});

sitesStore.propTypes = {
  allSites: PropTypes.array.isRequired,
  adminSites: PropTypes.array.isRequired,
  getAllSites: PropTypes.func.isRequired,
};

export { sitesStore };
