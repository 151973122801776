import axios from "axios";
import env from "../../environment.json";

export const articlesStore = (set) => ({
  articleTypes: [],
  getArticleTypes: () => {
    axios
      .get(`${env.protocol}${env.env}/api/public/infoscreen/infoscreenArticles/getArticleTypes`)
      .then((response) => {
        set({ articleTypes: response.data });
      })
      .catch((error) => console.error(error));
  },
});
