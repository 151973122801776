
export type Route = {
    nav?: string,
    sidebar?: string,
    displayName?: string,
    value?: number,
    sections?: IRoutes,
}

interface IRoutes {
    [key: string]: Route;
}

// This array helps us initialize our route when deep linking

export const navigationElements: IRoutes = {

    /* Default */
    default: {
        nav: 'home',
        sidebar: '',
        displayName: 'Home',
    },

    /* Sections */
    home: {
        nav: 'home',
        sidebar: '',
        displayName: 'Home',
        sections: {
            // This should include the sections for our leaflets
        },
    },
    activity: {
        nav: 'activity',
        sidebar: '',
        displayName: 'Activity',
    },
    pages: {
        nav: 'pages',
        sidebar: '',
        displayName: 'Pages',
    },
    articles: {
        nav: 'articles',
        sidebar: '',
        displayName: 'Articles',
    },
    media: {
        nav: 'media',
        sidebar: 'list',
        displayName: 'Media',
    },
    whatson: {
        nav: 'whatson',
        sidebar: 'whatson',
        displayName: "What's On",
        sections: {

            /* Default */
            default: {
                nav: 'events',
                displayName: 'Events',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },

            /* Sections */
            events: {
                nav: 'events',
                displayName: 'Events',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },
            places: {
                nav: 'places',
                displayName: 'Places',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },
            tours: {
                nav: 'tours',
                displayName: 'Tours',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },
            tour_operator: {
                nav: 'tour_operator',
                displayName: 'Tour Operator',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },
            hiking: {
                nav: 'hiking',
                displayName: 'Hiking',
                sections: {
                    add: {
                        nav: 'add',
                    }
                }
            },
        }
    },
    users: {
        nav: 'users',
        sidebar: '',
        displayName: 'Persons',
    },
    analytics: {
        nav: 'analytics',
        sidebar: '',
        displayName: 'Analytics',
    },
    collaboration: {
        nav: 'collaboration',
        sidebar: '',
        displayName: 'Collaboration',
    },
    shop: {
        nav: 'shop',
        sidebar: '',
        displayName: 'Shop',
    },
    admin: {
        nav: 'admin',
        sidebar: '',
        displayName: 'Admin',
        sections: {

            /* Default */
            default: {
                nav: 'sites',
                displayName: 'Sites',
            },

            /* Sections */
            sites: {
                nav: 'sites',
                displayName: 'Sites',
            },
            infoscreens: {
                nav: 'infoscreens',
                displayName: 'Infoscreens',
            },
            amenities: {
                nav: 'amenities',
                displayName: 'Amenities (places)',
                sections: {

                    /* Default */
                    default: {
                        nav: 'add',
                    },

                    /* Sections */
                    add: {
                        nav: 'add',
                    },
                    edit: {
                        value: 0,
                    },
                }
            },
        },
    },
    help: {
        nav: 'help',
        sidebar: '',
        displayName: 'Help',
    }
}