import { create } from "zustand";
import { v4 as uuidv4 } from 'uuid';
import { createOverlay } from "./overlayTypeElements";
import { IOverlayProperties } from "./types";

// Initial states
const initialState: any = {
  overlays: {
    card: [],
    side_popout_self_close: [],
  },
};

export const overlayStore = create((set, get) => ({
  // Initialize states
  ...initialState,

  // Set overlay
  // - setOverlay allows us to set states within the overlayStore
  setOverlay: (states: any) => set((state: any) => ({ ...states })),

  closeOverlay: (type: any, id: any, customClose: any = () => {}) => {
    // Apply custom calls on close function
    customClose();
    // Remove overlay from store
    set((state: any) => {
      return {
        overlays: {
          ...state.overlays,
          [type]: state.overlays[type]?.filter((e: any) => e.id !== id),
        }
      };
    });
  },

  // Add overlay
  // - addOverlay allows us to add an overlay
  addOverlay: (
    type: string,
    callback: (e: any) => void,
    customClose: any = () => {},
  ) => {
    // (type)         - type specifies the overlay type we're using.
    // (callback)     - callback returns a close function, when calling
    //                  callback we want to return the jsx we want in our overlay.
    // (customClose)  - customClose is an optional parameter taht takes a function that
    //                  gets called when you close the overlay.

    // Generate id for our overlay
    const id = uuidv4();
    // Get current state
    const state: any = get();
    // Handle for close function
    const close: any = () => {state.closeOverlay(type, id, customClose)};

    // Create properties
    let properties: IOverlayProperties = {
      id: id,
      type: type,
      close: close,
      // Create the overlay component and store in the overlay member of properties
      overlay: createOverlay({
        overlay: callback(close),
        id: id,
        close: close,
        type: type,
      }),
    }

    // Update state
    set((state: any) => {
      return {
        overlays: {
          ...state.overlays,
          [type]: [...state.overlays?.[type], properties]
        },
      };
    });

    // Return proerties
    return properties;
  },

  // Reset overlay
  // - resetOverlay resets the store to its initial state
  resetOverlay: () => {
    set((state: any) => ({ ...initialState }));
  },
}));
