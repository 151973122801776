import type { articleType, section } from "./types";

export const articlesSections = [
  // {
  //   title: "Inspiration",
  //   views: [
  //     "Articles",
  //     "Podcasts"
  //   ],
  // },

  {
    title: "Infoscreens",
    views: [
      { name: "See & Do", key: "infoscreens" },
      { name: "Eat & Drink", key: "infoscreens" },
      { name: "Transport", key: "infoscreens" },
    ],
  },
  // {
  //     title: "Other",
  //     views: [
  //       {name:"Inspiration",key:"inspiration"},
  //       {name:"Podcasts",key:"podcasts"},
  //     ],
  //   },
];
