import React from "react";
import "./MainPages.scss";

import SitesList from "./SitesList";
import PagesList from "./PagesList";
import OptionsList from "../../../assets/OptionsList/OptionsList";

export default function HierarchyView(props) {
  const {
    listIsOpen,
    setListIsOpen,
    setOptionsListKey,
    optionsListOptions,
    optionsListPos,
    thePages,
    siteSelect,
    setOptionsList,
    setOptionsListPageSelected,
    editPage,
    addExtraPage,
    setIsLoading,
    deletePage,
    deletedPage,
    pageSelect,
    movePage,
    orderPage,
    duplicatePage,
    selectedLanguage,
    templateList,
    ghostPage,
    disableBottomSelect,
    disableSitesList,
    disableSharedPages,
    refreshPages,
  } = props;

  let stopRenderChildren = false;

  return (
    <div className="sites-pages-wrap">
      <OptionsList
        // key={optionsListKey}
        isOpen={listIsOpen}
        close={(dontClose, deep) => {
          if (!dontClose) {
            setListIsOpen(false);
            setOptionsListPageSelected(undefined);
          } else {
            setOptionsListKey(new Date().getTime());
          }
        }}
        options={optionsListOptions}
        position={optionsListPos}
      />
      <SitesList
        className={"bg-" + Math.min(thePages.length + 1, 7)}
        siteSelect={siteSelect}
        sessionSet={props.sessionSet}
        setOptionsList={setOptionsList}
        selectPage={props.selectPage}
        editPage={editPage}
        addExtraPage={addExtraPage}
        setIsLoading={(e) => setIsLoading(e)}
        templateList={templateList}
        thePage={thePages[0]}
        disableSitesList={disableSitesList}
      />
      {thePages.map((element, i) => {
        if (props.excludePages) {
          if (props.excludePages.includes(element.pageId)) {
            stopRenderChildren = true;
          }
        }

        if (stopRenderChildren) return "";

        let shownGhostPage = undefined;
        if (ghostPage && ghostPage.child_of === parseInt(element.pageId)) {
          shownGhostPage = ghostPage;
        }

        return (
          <PagesList
            key={element.pageId ? element.pageId + " " + element.time : 0}
            className={"bg-" + Math.min(thePages.length - i, 7)}
            siteId={element.siteId}
            pageId={element.pageId}
            time={element.time}
            deletedChild={deletedPage}
            name={element.name}
            pageSelect={pageSelect}
            addExtraPage={addExtraPage}
            newValue={element.newValue}
            editPage={editPage}
            movePage={movePage}
            orderPage={orderPage}
            duplicatePage={duplicatePage}
            selectedPage={
              sessionStorage.getItem("selectedPages")
                ? JSON.parse(sessionStorage.getItem("selectedPages"))[i]
                : undefined
            }
            setOptionsList={setOptionsList}
            // setOptionsListPageSelected={props.setOptionsListPageSelected}
            optionsListPageSelected={props.optionsListPageSelected}
            selectAPage={props.selectPage}
            deletePage={deletePage}
            setIsLoading={(e) => setIsLoading(e)}
            openOverlay={props.openOverlay}
            excludePages={props.excludePages}
            selectedLanguage={selectedLanguage}
            templateList={templateList}
            ghostPage={shownGhostPage}
            disableBottomSelect={disableBottomSelect}
            disableSharedPages={disableSharedPages}
            refreshPages={refreshPages}
          />
        );
      })}
    </div>
  );
}
