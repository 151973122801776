import { useUserStore } from "../ZustandStores/allOfStore";
// import { permissions } from "./dummyUserData";

export type accessType =
  | "sites"
  | "regions"
  | "events"
  | "places"
  | "articleTypes"
  | "tours"
  | "tourOperators"
  | "superadmin";

// const accessColumnKeyNames = {
//   sites: "site_id",
//   regions: "region_id",
//   events: "event_id",
//   places: "place_id",
//   articleTypes: "article_type_id",
//   tours: "tour_id",
//   tourOperators: "tour_operator_id",
// } as const;

// export type whereRuleType = "any_of_type" | "any" | "all";
export type roleType = "Admin" | "Publisher" | "Collaborator";

// interface iDataAccess {
//   id: number;
//   user_id: number;
//   role_id: number;
//   acc_id: number;
//   permission: roleType;
//   permission_value: number;

//   site_id?: number;
//   region_id?: number;
//   event_id?: number;
//   place_id?: number;
//   article_type_id?: number;
//   tour_id?: number;
//   tour_operator_id?: number;
// }
interface iDataAccess {
  [id: number]: number;
}
interface iDataPermissions {
  // sites:[iDataPermissions,asd:number];
  sites: iDataAccess /*Site*/;
  regions: iDataAccess /*Region*/;
  events: iDataAccess /*Event*/;
  places: iDataAccess /*Place*/;
  articleTypes: iDataAccess /*ArticleType*/;
  tours: iDataAccess /*TourId*/;
  tourOperators: iDataAccess /*TourOperator*/;
  superadmin: boolean;
}

interface RoleParams {
  id: number;
  permission: roleType;
  permission_value: number;
}

interface PermCheckProps {
  type?: accessType | accessType[];
  id?: number | number[];
  minimumRole?: roleType;
  children?: React.ReactElement | null;
  // whereRule: whereRuleType;
  ignoreCheck?: boolean;
  permissions?: iDataPermissions;
  roles?: RoleParams[];
}

export function permCheckFunc(props: PermCheckProps): boolean {
  // try {
  const userDataPermissions: iDataPermissions | undefined = props.permissions;
  if (props.ignoreCheck || userDataPermissions?.superadmin) return true;

  const { roles, id: propId, minimumRole } = props;
  const role = roles?.find((e) => e.permission === minimumRole);

  if (props.type === undefined) {
    return false;
  }
  if (Array.isArray(props.type) && propId !== undefined) {
    throw new TypeError(
      "When using 'id' property, you cannot have array of types."
    );
  }
  if (userDataPermissions === undefined) {
    return false;
  }

  if (props.type === "superadmin") {
    return userDataPermissions.superadmin;
  }

  const types = Array.isArray(props.type) ? props.type : [props.type];

  if (propId === undefined && userDataPermissions) {
    // `id` is not defined, we check if user only has permission to any of defined types with minimum role
    for (let i = 0; i < types.length; i++) {
      // const permission = (userDataPermissions[types[i]] ?? {}) as iDataAccess;
      const permission = userDataPermissions[types[i]] ?? {};
      if (
        typeof permission !== "boolean" &&
        Object.keys(permission).find((id) => {
          const theId = parseInt(id);
          const thisRole = roles?.find((r) => {
            return r.id === permission[theId];
          });
          if (thisRole !== undefined) {
            return thisRole.permission_value >= (role?.permission_value ?? 0);
          }
          return false;
        })
        // permission?.find(
        //   (e) => e.permission_value >= (role?.permission_value ?? 0)
        // ) !== undefined
      ) {
        return true;
      }
    }
  } else {
    const type = types[0];
    const permissions = (userDataPermissions[type] ?? {}) as iDataAccess;
    // const ids = Object.keys(permissions);
    const theRole = roles?.find((role) => role.permission === minimumRole);
    const theRolePermissionValue = theRole?.permission_value ?? 0;
    const theIds = Array.isArray(propId) ? propId : [propId];
    if (
      theIds.find((id) => {
        if (
          id !== undefined &&
          permissions[id] &&
          (roles?.find((role) => role.id === permissions[id])
            ?.permission_value ?? 0) >= theRolePermissionValue
        ) {
          return true;
        }
      }) !== undefined
    ) {
      return true;
    }
  }
  // } catch (e) {
  //   let message: string = "An unknown error occured when checking permission.";
  //   if (typeof e === "string") {
  //     message = e; // works, `e` narrowed to string
  //   } else if (e instanceof Error || e instanceof TypeError) {
  //     message = e.message; // works, `e` narrowed to Error
  //   }
  //   console.error(message);
  // }
  return false;
}

export function PermCheck(
  props: PermCheckProps = {
    minimumRole: "Collaborator",
    type: undefined,
    // whereRule: "any",
  }
): React.ReactElement | null {
  // const userStore = useUserStore();
  // //   permCheckFunc(props);

  // //   const userData = useUserStore();
  // //   console.log(userData);
  // const hasAccess = permCheckFunc({
  //   ...props,
  //   permissions: props.permissions ?? userStore.permissions,
  // });

  // if (!hasAccess) {
  //   return "";
  // }

  // if (props.children === undefined) {
  //   return null;
  // }
  // return props.children;

  const userStore = useUserStore();
  const hasAccess = permCheckFunc({
    ...props,
    permissions: props.permissions ?? userStore.permissionsCheck,
    roles: props.roles ?? userStore.roles,
  });

  if (props.children === undefined || !hasAccess) {
    return null;
  }

  return props.children ?? null;
}
