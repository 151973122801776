import React, { Component } from "react";
import "./UserOverlay.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencil } from "@fortawesome/pro-light-svg-icons";
import Profile from "./Panels/Profile";
import Activity from "./Panels/Activity";
import Permission from "./Panels/Permission";
import Settings from "./Panels/Settings";
import Dropzone from "react-dropzone";
import env from "../../../../environment.json";
import globalObject from "../../../../assets/globalVariables";
import axios from "axios";
import SquareButton from "src/Components/Buttons/SquareButton/SquareButton";

class UserOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      checkboxFilter: { siteType: ["all"], format: ["all"] },
      update: false,
      onsavePopText: "",
      uploadFilePercent: 100,
      draggingOnUserImage: false,
      allPlaces: [],
      placesICanChoose: [],
      accountStatus: "",
    };

    this.imgConfig.onUploadProgress =
      this.imgConfig.onUploadProgress.bind(this);
  }

  componentDidMount() {
    axios
      .get(env.protocol + env.env + "/api/whatson/places/GetPlacesList")
      .then((response) => {
        this.setState({
          allPlaces: response.data.map((e) => {
            return { label: `${e.headline}`, value: e.id };
          }),
        });
      })
      .catch((error) => console.error(error));

    axios
      .get(
        env.protocol +
          env.env +
          "/api/whatson/places/GetPlacesList?" +
          (globalObject.vfiUser.superAdmin === "1"
            ? ""
            : `author=${globalObject.vfiUser.userId}&isPlaceAdmin`)
      )
      .then((response) => {
        this.setState({
          placesICanChoose: response.data,
        });
      })
      .catch((error) => console.error(error));

    if (this.props.data.id) {
      axios
        .get(
          env.protocol +
            env.env +
            "/api/whatson/places/GetPlacesList?author=" +
            this.props.data.id
        )
        .then((response) => {
          this.props.onChange(
            "places",
            response.data.map((e) => {
              return { label: e.headline, value: e.id, is_admin: e.is_admin };
            })
          );
        })
        .catch((error) => console.error(error));
    } else {
      this.props.onChange("places", []);
    }
  }

  handleSave = () => {
    this.props.saveBtn();

    if (this.state.readyToUpdate) {
      this.state.readyToUpdate();
    }

    setTimeout(() => {
      this.setState({
        update: false,
      });

      setTimeout(() => {
        this.props.setResponse("");
      }, 3000);
    }, 100);
  };

  showUpdateBtn = () => {
    if (!this.state.update) {
      //Only update if already false
      this.setState({ update: true });
    }
  };

  handleReadyToUpdate = (readyToUpdate) => {
    if (readyToUpdate) {
      this.setState({ readyToUpdate });
    }
  };

  onDrop = (e, imgConfig) => {
    this.props.uploadPhoto(e, imgConfig);
    this.showUpdateBtn();
  };

  renderSwitch() {
    switch (this.props.panel.toLowerCase()) {
      case "profile":
        return (
          <Profile
            data={this.props.data}
            onChange={this.props.onChange}
            update={this.state.update}
            showUpdateBtn={this.showUpdateBtn}
            extra={this.props.extra}
          />
        );

      case "activity":
        return (
          <Activity
            userClicked={this.props.data.id}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            checkboxFiltering={this.props.checkboxFiltering}
            SetFilterData={this.props.SetFilterData}
            listOptions={this.props.listOptions}
            addFilterDroppedDown={this.props.addFilterDroppedDown}
            openCloseFilter={this.props.openCloseFilter}
            SetState={this.props.SetState}
          />
        );

      case "permission":
        return (
          <Permission
            data={this.props.data}
            handleOptionChange={this.props.handleOptionChange}
            clearAllOptionChange={this.props.clearAllOptionChange}
            onChange={this.props.onChange}
            update={this.state.update}
            showUpdateBtn={this.showUpdateBtn}
            extra={this.props.extra}
            allPlaces={this.state.allPlaces}
            placesICanChoose={this.state.placesICanChoose}
            openOverlay={this.props.openOverlay}
          />
        );
      case "settings":
        return (
          <Settings
            data={this.props.data}
            close={() => this.props.close()}
            SetState={this.props.SetState}
            // accountStatus={this.state.accountStatus}
            setAccountStatus={(key, value) => {
              this.setState({ [key]: value });
            }}
            showUpdateBtn={this.showUpdateBtn}
            onReadyToUpdate={this.handleReadyToUpdate}
            // userClicked={this.props.data.id}

            // startDate={this.props.startDate}
            // endDate={this.props.endDate}
            // checkboxFiltering={this.props.checkboxFiltering}
            // SetFilterData={this.props.SetFilterData}
            // listOptions={this.props.listOptions}
            // addFilterDroppedDown={this.props.addFilterDroppedDown}
            // openCloseFilter={this.props.openCloseFilter}
            // SetState={this.props.SetState}
          />
        );

      default:
        return "";
    }
  }

  // lastElm(array) {
  //   let returnArray = this.props.data.loginDates;
  //   let lastelm = JSON.parse(returnArray).slice(-1)[0];
  //   return lastelm;
  // }

  setPanelState(param) {
    param = param.toLowerCase();
    switch (param) {
      case "profile":
        this.setState({ panel: "profile" });
        this.props.SetState("panel", "profile");
        break;
      case "activity":
        this.setState({ panel: "activity" });
        this.props.SetState("panel", "activity");
        break;
      case "permission":
        this.setState({ panel: "permission" });
        this.props.SetState("panel", "permission");
        break;
      case "settings":
        this.setState({ panel: "settings" });
        this.props.SetState("panel", "settings");
        break;
      default:
        return "";
    }
  }

  JsonValue() {
    return {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
  }

  imgConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.setState({
        uploadFilePercent: percentCompleted,
      });
    },
  };

  render() {
    const uploadedPhoto = this.props.data.uploadedPhoto;
    return (
      <div className="wrapper">
        <div className={"user-panel " + this.props.extra}>
          <div className="top-header">
            {this.state.accountStatus == "deleted" && (
              <div className="account-status">
                <h2>DELETED USER</h2>
              </div>
            )}
            <div className="header">
              <div
                className="close"
                onClick={() => {
                  this.props.close();
                }}
              >
                <p className="icon">
                  <FontAwesomeIcon icon={faTimes} />
                </p>
              </div>
              <div className="userInfo">
                <div className="dragndrop-photo-parent">
                  <Dropzone
                    onDrop={(e) => this.onDrop(e, this.imgConfig)}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept=".jpg,.jpeg,.png"
                            maxLength={1}
                          />
                          {uploadedPhoto !== undefined &&
                          uploadedPhoto.length > 0 ? ( //Vísir myndina so skjótt tað verður uploada
                            <div
                              className={
                                "profile_pic" +
                                (this.state.uploadFilePercent !== 100
                                  ? " uploading"
                                  : "")
                              }
                            >
                              <img src={uploadedPhoto} alt="user img" />
                              {this.state.uploadFilePercent !== 100 ? (
                                <div className="upload-bar">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: this.state.uploadFilePercent + "%",
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <FontAwesomeIcon icon={faPencil} />
                            </div>
                          ) : //If no new uploaded image is up there is a picture in the database connected
                          parseInt(this.props.data.localImg) > 0 ? (
                            <div
                              className={
                                "profile_pic" +
                                (this.state.uploadFilePercent !== 100
                                  ? " uploading"
                                  : "")
                              }
                            >
                              <img
                                src={
                                  env.protocol +
                                  env.env +
                                  "/uploads/" +
                                  this.props.data.localImg_file_name +
                                  "_thumbnail." +
                                  this.props.data.localImg_extension
                                }
                                alt="user img"
                              />
                              <FontAwesomeIcon icon={faPencil} />
                            </div>
                          ) : (
                            //No image
                            <div
                              className={
                                "upload-box" +
                                (this.state.uploadFilePercent !== 100
                                  ? " uploading"
                                  : "") +
                                (this.state.draggingOnUserImage === true
                                  ? " on-drag"
                                  : "")
                              }
                              onDragEnter={() =>
                                this.setState({ draggingOnUserImage: true })
                              }
                              onDragLeave={() =>
                                this.setState({ draggingOnUserImage: false })
                              }
                            >
                              {
                                <div className="text-content">
                                  Drag and drop photo or select file
                                </div>
                              }
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {/* }  */}

                <p className="name">
                  {this.props.data.firstname + " " + this.props.data.lastname}
                </p>
                <p className="lastlogin">
                  {this.props.data.loginDates?.length <= 0 ||
                  this.props.data.loginDates === undefined
                    ? ""
                    : "Last login " +
                      JSON.parse(this.props.data.loginDates).slice(-1)[0]}
                </p>
              </div>

              {/* <div className="cancel"><div className="text">Cancel</div></div>
                      <div className="save" onClick={()=> this.props.saveBtn()}><div className="text">Save</div></div> */}
            </div>
            <div className="panels">
              <ul>
                <li
                  className={
                    this.props.panel === "profile"
                      ? "Profile Active"
                      : "Profile"
                  }
                  onClick={() => {
                    this.setPanelState("Profile");
                  }}
                >
                  PROFILE
                </li>
                <li
                  className={
                    this.props.panel === "activity"
                      ? "Activity Active"
                      : "Activity"
                  }
                  onClick={() => {
                    this.setPanelState("Activity");
                  }}
                >
                  ACTIVITY
                </li>
                <li
                  className={
                    this.props.panel === "permission"
                      ? "Permission Active"
                      : "Permission"
                  }
                  onClick={() => {
                    this.setPanelState("Permission");
                  }}
                >
                  PERMISSION
                </li>
                <li
                  className={
                    this.props.panel === "settings"
                      ? "Settings Active"
                      : "Settings"
                  }
                  onClick={() => {
                    this.setPanelState("Settings");
                  }}
                >
                  SETTINGS
                </li>
              </ul>
            </div>
          </div>
          <div className="body">
            {this.renderSwitch()}
            <p
              className={
                this.props.response.length > 0 ? "SaveText" : "SaveTextHide"
              }
            >
              {this.props.response}
            </p>

            {this.state.update ? (
              <div style={{ display: "flex" }}>
                <SquareButton
                  onClick={() => this.handleSave()}
                  // style={this.buttonStyle}
                  className="save"
                >
                  {this.props.extra === "editUser"
                    ? "Update profile changes"
                    : "Create user"}
                </SquareButton>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default UserOverlay;
