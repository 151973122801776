import { create } from "zustand";
import { navigationElements } from "./navigationElements";
import { initRouteArray } from "./helper-functions";

// [...arr.map((r: string, index: number) => {
//   return {
//     nav: r,
//     sidebar: sidebarArr[index],
//     displayName: displayArr[index],
//   };
// })]

// Initial states
const initialState: any = {
  route: initRouteArray(),
  // activeSidebar: "",
};

export const routerStore = (set: any, get: any) => ({
  // Initialize states
  ...initialState,

  // Set routes
  // - setRoutes allows us to set states within the routesStore
  setRouter: (states: any) => set((state: any) => ({ ...states })),

  // Use navTo as much as possible for navigation, it tracks everything in the route state
  // and also our url.
  navTo: (level: number, destination: any) => {
    // - navTo should let you navigate to a specific location in the system
    //   navTo will also set the url so it can be used for deep linking
    set((state: any) => {

      // get() might not be necessarry when we get the state from the set function.

      window.location.hash =
        (level? "/" : '') +
        get().route
          .filter((r: any, index: number) => index < level)
          .map((r: any) => r.nav)
          .join("/") +
        "/" +
        destination.nav;

      return {
        route: [
          ...get().route.filter((r: any, index: number) => index < level),
          {
            nav: destination.nav,
            sidebar: destination.sidebar,
            displayName: destination.displayName,
          },
        ],
      };
    });
  },

  navByLink: (link: string) => {

    window.location.hash = '/' + link;

    set((state: any) => {
      return {
        route: link.split('/').map((r: string, index: number) => ({
          nav: r,
          sidebar: navigationElements[r]? navigationElements[r].sidebar : '',
          displayName: navigationElements[r]? navigationElements[r].displayName : '',
        })),
      }
    })
  },

  back: () => {
    set((state: any) => {
      let arr = [...state.route];
      arr.pop();
      window.location.hash = "/" + arr.map((r: any) => r.nav).join("/");
      return { route: arr };
    });
  },

  // forward: () => {} - forward will let you go forward 1 level

  initRoute: () => {
    set({
      route: initRouteArray(),
    });
  },

  // Reset routes
  // - resetRoutes...
  resetRoutes: () => {
    set(() => ({ ...initialState }));
  },
});

export const useRouterStore = create(routerStore);

export const withRouterStore = (BaseComponent: any) => (props: any) => {
  const store = useRouterStore();
  return <BaseComponent {...props} routerStore={store} />;
};
