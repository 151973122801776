import React, { useEffect, useState } from "react";
import env from "../../../../environment.json";
import styles from "./DomainOverlay.module.scss";

function DomainOverlay() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(hasSubdomain(env.env));
  }, []);

  const hasSubdomain = (url: string) => {
    var parts = url.split(".");
    if (parts.length > 2 && parts[0] !== "www") {
      return true;
    }
    return false;
  };

  return <>{show && <div className={styles.domainBanner}>{env.env}</div>}</>;
}

export default DomainOverlay;
