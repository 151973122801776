import { create } from "zustand";
import { sitesStore } from "./sitesStore";
import { userStore } from "./userStore";
import { whatsonStore } from "./whatsonStore";
import { articlesStore } from "./articlesStore";
import { routerStore } from "../Router/routerStore";
import { permCheckFunc } from "../PermCheck";
import React from "react";

const useAllOfStore = create((...a) => ({
  ...sitesStore(...a),
  ...userStore(...a),
  ...whatsonStore(...a),
  ...articlesStore(...a),
  ...routerStore(...a),
  permCheckFunc: (props) => {
    return permCheckFunc({
      roles: useAllOfStore.getState().roles,
      permissions: useAllOfStore.getState().permissionsCheck,
      ...props,
    });
  },
}));

export const { loggedInUser } = useAllOfStore;

useAllOfStore.propTypes = {
  ...sitesStore.propTypes,
  ...userStore.propTypes,
  ...whatsonStore.propTypes,
  ...articlesStore.propTypes,
};

export { useAllOfStore };

export const withAllOfStore = (BaseComponent) => {
  const WithAllOfStore = React.forwardRef((props, ref) => {
    const store = useAllOfStore();
    return <BaseComponent ref={ref} {...props} store={store} />;
  });

  // This line is important, forwardRef will not work without it
  WithAllOfStore.displayName = `WithAllOfStore(${BaseComponent.name})`;

  return WithAllOfStore;
};

// Article stores
export const useArticlesStore = () => {
  const articleTypes = useAllOfStore((s) => s.articleTypes);
  const getArticleTypes = useAllOfStore((s) => s.getArticleTypes);
  return { articleTypes, getArticleTypes };
};

export const withArticlesStore = (BaseComponent) => (props) => {
  const store = useArticlesStore();
  return <BaseComponent {...props} articlesStore={store} />;
};

// Sites stores
export const useSitesStore = () => {
  const allSites = useAllOfStore((s) => s.allSites);
  const allSitesNoRootPages = useAllOfStore((s) => s.allSitesNoRootPages);
  const allSitesLoaded = useAllOfStore((s) => s.allSitesLoaded);
  const getAllSites = useAllOfStore((s) => s.getAllSites);

  return { allSites, allSitesNoRootPages, allSitesLoaded, getAllSites };
};

export const withSitesStore = (BaseComponent) => (props) => {
  const store = useSitesStore();
  return <BaseComponent {...props} sitesStore={store} />;
};

// User stores
export const useUserStore = () => {
  const loggedInUser = useAllOfStore((s) => s.loggedInUser);
  const permissions = useAllOfStore((s) => s.permissions);
  const permissionsCheck = useAllOfStore((s) => s.permissionsCheck);
  const users = useAllOfStore((s) => s.users);
  const roles = useAllOfStore((s) => s.roles);
  const getLoggedInUser = useAllOfStore((s) => s.getLoggedInUser);
  const getUsers = useAllOfStore((s) => s.getUsers);
  const getRoles = useAllOfStore((s) => s.getRoles);
  return {
    loggedInUser,
    users,
    roles,
    getLoggedInUser,
    getUsers,
    getRoles,
    permissions,
    permissionsCheck,
  };
};

export const withUserStore = (BaseComponent) => (props) => {
  const store = useUserStore();
  return <BaseComponent {...props} userStore={store} />;
};

// Whatson stores
export const useWhatsonStore = () => {
  const places = useAllOfStore((s) => s.places);
  const events = useAllOfStore((s) => s.events);
  const tours = useAllOfStore((s) => s.tours);
  const hiking = useAllOfStore((s) => s.hiking);
  const tourOperators = useAllOfStore((s) => s.tourOperators);
  const getAllWhatson = useAllOfStore((s) => s.getAllWhatson);
  const getTourOperators = useAllOfStore((s) => s.getTourOperators);
  return {
    places,
    events,
    tours,
    hiking,
    tourOperators,
    getAllWhatson,
    getTourOperators,
  };
};

export const withWhatsonStore = (BaseComponent) => (props) => {
  const store = useWhatsonStore();
  return <BaseComponent {...props} whatsonStore={store} />;
};
