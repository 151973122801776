//All sliders, including grids are in this EditModule

import { useEffect, useState } from "react";
import "./LinkToEdit.scss";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import Select from "react-select";
import VfiInputText from "../../VfiInputText/VfiInputText";
import axios from "axios";
import env from "../../../environment.json";

export const linkToEditLinkOptions = [
  { label: "External URL", value: "external_url" },
  { label: "Page", value: "page" },
  { label: "None", value: "none" },
];

export default function LinkToEdit({
  values: propsValues,
  change = () => void 0,
  selectedLanguage,
  close = () => void 0,
  openOverlay = () => void 0,
  headline,
  linkOptions = linkToEditLinkOptions,
  customContents = {},
}) {
  const [values, setValues] = useState(propsValues);
  const [screen, setScreen] = useState("linkTo");
  const [pagePath, setPagePath] = useState("");

  useEffect(() => {
    if (values) {
      // change(values);
    }
  }, [values, change]);

  useEffect(() => {
    setValues(propsValues);
  }, [propsValues]);
  useEffect(() => {
    if (values.link_option === "page") {
      if (values.page_id) {
        axios
          .get(
            env.protocol +
              env.env +
              "/api/public/pages/GetPage.php?id=" +
              values.page_id +
              "&shared=" +
              (values.shared ? "1" : "0")
          )
          .then((response) => {
            setPagePath(response.data.languages[selectedLanguage].path);
          })
          .catch((error) => console.error(error));
      } else {
        setPagePath("");
      }
    } else {
      setPagePath("");
    }
  }, [values.link_option, values.page_id, selectedLanguage]);

  const OpenNewWindow = (props) => {
    return (
      <div
        className="option whole-click"
        onClick={() => {
          setValues({
            ...values,
            new_window: !values.new_window,
          });
          change({ ...values, new_window: !values.new_window });
        }}
      >
        <div className="label">Open in new window</div>
        <div className="value">
          <VfiCheckbox2
            checked={values.new_window}
            isRadio={true}
            readOnly={true}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="link-to-edit edit-module" key={values.key}>
      <div className="top">
        <div
          className={"top-choice " + (screen === "linkTo" ? "active " : "")}
          onClick={() => setScreen("linkTo")}
        >
          LINK TO
        </div>
      </div>
      <div className="content">
        <div className="option full">
          <div className="value">
            <Select
              options={linkOptions}
              value={linkOptions.find((e) => e.value === values.link_option)}
              onChange={(e) => {
                setValues({
                  ...values,
                  link_option: e.value,
                  new_window: e.value === "external_url",
                });
                change({
                  ...values,
                  link_option: e.value,
                  new_window: e.value === "external_url",
                });
              }}
            />
          </div>
        </div>

        {values.link_option === "external_url" ? (
          <div>
            <div className="option link">
              <div className="value">
                <VfiInputText
                  key={"ext: " + values.external_link}
                  placeholder="External Link"
                  defaultValue={values.external_link}
                  onBlur={(e) => {
                    setValues({ ...values, external_link: e.target.value });
                    change({ ...values, external_link: e.target.value });
                  }}
                />
              </div>
            </div>
            <OpenNewWindow />
          </div>
        ) : (
          ""
        )}

        {values.link_option === "page" ? (
          <div>
            <div className="option link fifty-fifty">
              <div
                className="label button"
                onClick={() => {
                  close();
                  openOverlay("pageChoose", {
                    afterFunc: (e) => {
                      setValues({ ...values, page_id: e.id, shared: e.shared });
                      change({ ...values, page_id: e.id, shared: e.shared });
                    },
                    headline: headline,
                    disableBottomSelect: true,
                  });
                }}
              >
                Select Page
              </div>
              <div className="value">
                <VfiInputText
                  placeholder="Url"
                  disabled={true}
                  value={pagePath}
                />
              </div>
            </div>
            <OpenNewWindow />
          </div>
        ) : (
          ""
        )}

        {values.link_option !== "external_url" &&
          values.link_option !== "page" && (
            <div className="custom-link-to-edit-content">
              {customContents[values.link_option]}
            </div>
          )}

        {/* {values.link_option === "download" ? (
          <div>
            <div className="option link fifty-fifty">
              <div
                className="label button"
                onClick={() => {
                  close();
                  openOverlay("pageChoose", {
                    afterFunc: (e) => {
                      setValues({ ...values, page_id: e.id });
                      change({ ...values, page_id: e.id });
                    },
                    headline: headline,
                    disableBottomSelect:true
                  });
                }}
              >
                Select Page
              </div>
              <div className="value">
                <VfiInputText
                  placeholder="Url"
                  disabled={true}
                  value={pagePath}
                />
              </div>
            </div>
            <OpenNewWindow />
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
}
