import { useEffect, useState } from "react";
import OneMedia from "../../../../../assets/PageModules/OneMedia";
import VfiCKeditor from "../../../../../assets/VfiCKeditor/VfiCKeditor";
import VfiInputText from "../../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../../assets/VfiTextarea/VfiTextarea";
import { WhatsonApprovalStatusButtons } from "../../../WhatsonDisplay/WhatsonContent";
import noImage from "../../../../../assets/images/empty.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import VfiCheckbox from "../../../../../assets/VfiCheckbox";
import { capitalize, showDate } from "../../../../../assets/helperFunctions";

export default function GeneralOptions({
  data: d,
  onChange: setD = () => void 0,
  whatWhatson,
  changeValue = () => void 0,
  className,
  openOverlay = () => void 0,
}) {
  const [data, setData] = useState(d);
  const [subOption, setSubOption] = useState("");

  useEffect(() => {
    setData(d);
  }, [d]);

  if (data === undefined) return "";
  let content;

  switch (subOption) {
    case "current_status":
      content = (
        <div>
          <div className="small-label top-gap">CURRENT STATUS</div>
          <label
            className="encapsulate status top-gap"
            onClick={() => {
              changeValue("current_status", "draft", "");
            }}
          >
            <div className="text">Draft</div>
            <input
              type={"radio"}
              className="right-point"
              readOnly={true}
              checked={data.data.current_status === "draft"}
            />
          </label>
          <label
            className="encapsulate status top-gap"
            onClick={() => {
              changeValue("current_status", "published", "");
            }}
          >
            <div className="text">Published</div>
            <input
              type={"radio"}
              className="right-point"
              readOnly={true}
              checked={data.data.current_status === "published"}
            />
          </label>
        </div>
      );
      break;
    default:
      content = (
        <div className="wrap-general-options">
          <div>
            <div className="small-label top-gap">SCREEN TITLE</div>
            <VfiInputText
              defaultValue={d?.info?.name}
              onBlur={(e) => {
                changeValue("name", e.target.value);
              }}
            />
            <hr />
            <button
              className="standard-filters"
              onClick={() => {
                openOverlay("infoscreenStandardFilters", {
                  data: data,
                  afterFunc: (e) => {
                    if (e) {
                      setD({ ...e });
                    }
                  },
                });
              }}
            >
              DEFINE STANDARD FILTERS
            </button>
          </div>
        </div>
      );
  }

  return (
    <div className={"general-options " + (className ?? "")}>
      <div
        className={"option-title" + (subOption ? " clickable" : "")}
        onClick={() => setSubOption("")}
      >
        {subOption && <FontAwesomeIcon icon={faChevronLeft} />}General
      </div>
      {content}
    </div>
  );
}
