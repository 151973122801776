import React, { Component } from "react";
import "./Sidebar.scss";
import ListSidebar from "./ListSidebar/ListSidebar";
import TreeSidebar from "./TreeSidebar/TreeSidebar";
import WhatsonSidebar from "./WhatsonSidebar/WhatsonSidebar";
import InfoscreenSidebar from "./InfoscreenSidebar/InfoscreenSidebar";

class Sidebar extends Component {
  // constructor(props)
  // {
  //   super(props);

  //   this.state = {
  //   }
  // }

  render() {

    // const collapsed = this.props.collapsed ? " collapsed" : "";
    const collapsed = this.props.store.route[0]?.collapsed ? " collapsed" : "";

    // switch (this.props.show) {
    switch (this.props.store.route[0].sidebar) {
      case "list":
        return (
          <div className={"sidebar" + collapsed}>
            <ListSidebar
              siteSelect={this.props.siteSelect}
              contentshow={this.props.contentshow}
              listOptions={this.props.listOptions}
              optionClicked={this.props.optionClicked}
              showAllParameter={this.props.showAllParameter}
            />
          </div>
        );
      case "tree":
        return (
          <div className={"sidebar" + collapsed}>
            <TreeSidebar />
          </div>
        );
      case "whatson":
        return (
          <div className={"sidebar" + collapsed}>
            <WhatsonSidebar
              siteSelect={this.props.siteSelect}
              contentshow={this.props.contentshow}
              store={this.props.store}
            />
          </div>
        );
      case "infoscreens":
        return (
          <div className={"sidebar" + collapsed}>
            <InfoscreenSidebar
              infoscreenMenu={this.props.infoscreenMenu}
              setInfoscreenMenu={this.props.setInfoscreenMenu}
            />
          </div>
        );
      default:
        return "";
    }
  }
}

export default Sidebar;
