import PropTypes from "prop-types";
import InfoscreenPage from "../InfoscreenPage";

import "./ScreenManagement.scss";
import "../../Pages/PagesList.scss";
import { useEffect } from "react";
import axios from "axios";
import env from "../../../../environment.json";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import {
  faEye,
  faFrame,
  faLink,
  faPen,
  faPlus,
  faTrashAlt,
  faTv,
} from "@fortawesome/pro-light-svg-icons";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import { useCallback } from "react";
import { useRef } from "react";
import OptionsList from "../../../../assets/OptionsList";
import { propTypes } from "react-video-thumbnail";
import PreviewInfoscreen from "./PreviewInfoscreen";
import ToolTip from "../../../ToolTips/ToolTip";

function ScreenManagement({
  openOverlay = () => void 0,
  site,
  setIsLoading = void 0,
}) {
  const [display, setDisplay] = useState("leaflet");
  const [regions, setRegions] = useState([]);
  const [regionsFetched, setRegionsFetched] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(undefined);
  const [infoscreens, setInfoscreens] = useState([]);
  const [infoscreensFetched, setInfoscreensFetched] = useState(undefined);
  const [selectedInfoscreen, setSelectedInfoscreen] = useState(undefined);

  const [newInfoscreenInProgress, setNewInfoscreenInProgress] = useState(false);
  const [newInfoscreenName, setNewInfoscreenName] = useState("");

  const [editInfoscreenInProgress, setEditInfoscreenInProgress] =
    useState(false);
  const [editInfoscreenName, setEditInfoscreenName] = useState("");

  const newInfoscreenRef = useRef(null);
  const editInfoscreenRef = useRef(null);

  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({ x: 0, y: 0 });

  const getIsOptionsList = (id) => {
    return [
      {
        is: "choice",
        icon: faLink,
        text: "Copy URL",
        onClick: () => {
          navigator.clipboard.writeText(
            site.protocol + site.domain_name + "?infoscreenID=" + id
          );
        },
        // style: { color: "#f64e60" },
      },
      { is: "hr" },
      {
        is: "choice",
        icon: faTv,
        text: "Edit screen",
        onClick: () => {
          editScreen(id);
        },
        // style: { color: "#f64e60" },
      },
      {
        is: "choice",
        icon: faPen,
        text: "Edit name",
        onClick: () => {
          const infoscreen = infoscreens.find((is) => is.id === id);
          infoscreen.inNameEdit = true;
          setInfoscreens([...infoscreens]);
          setEditInfoscreenName(infoscreen.name);
          setEditInfoscreenInProgress(true);
        },
      },
      {
        is: "choice",
        icon: faFrame,
        text: "Edit order",
        onClick: () => {
          // Discussion if token should be generated here...
          editInfoscreen(id);
        },
      },
      { is: "hr" },
      {
        is: "choice",
        icon: faTrashAlt,
        text: "Delete screen",
        onClick: () => {
          openOverlay("popupselect", {
            title: "delete infoscreen?",
            description: `Are you sure you want to delete the “${
              infoscreens.find((e) => e.id === id).name
            }” infoscreen?`,
            afterFunc: (e) => {
              if (e) {
                deleteInfoscreen(id);
              }
            },
          });
        },
        style: { color: "#f64e60" },
      },
    ];
  };

  const editInfoscreen = useCallback(
    (id) => {
      window.open(
        site.protocol +
          site.domain_name +
          "?infoscreenID=" +
          id +
          "&token=7fd5b5eb-a2a5-43e9-832d-f013e380c61f&this_is_hardcoded"
      );
    },
    [site]
  );

  useEffect(() => {
    axios
      .get(`${env.protocol}${env.env}/api/public/GetRegions.php`)
      .then((response) => {
        setRegions(response.data);
        setRegionsFetched(true);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedRegion !== undefined) {
      setInfoscreensFetched(false);
      setInfoscreens([]);
      axios
        .get(
          env.protocol +
            env.env +
            "/api/public/infoscreen/GetInfoscreens.php?region=" +
            selectedRegion
        )
        .then((response) => {
          setInfoscreens(response.data);
          // setSelectedInfoscreen(response.data[0].region_id);
          setInfoscreensFetched(true);
        })
        .catch((error) => console.error(error));
    }
  }, [selectedRegion]);

  // useEffect(() => {
  //     // const blurFunc = () => {
  //     //     setNewInfoscreenInProgress(false);
  //     //     setNewInfoscreenName("");
  //     // }
  //     // newInfoscreenRef.current.addEventListener('blur', blurFunc);
  //     if (newInfoscreenInProgress) {
  //         newInfoscreenRef.current.focus();
  //     }
  //     // return () => {
  //     //     newInfoscreenRef.current.removeEventListener('blur', blurFunc);
  //     // }
  // }, [newInfoscreenInProgress, newInfoscreenRef])

  const createInfoscreen = useCallback(
    (name, region) => {
      setNewInfoscreenName("");
      setNewInfoscreenInProgress(false);
      setInfoscreensFetched(false);
      if (name) {
        axios
          .post(
            env.protocol + env.env + "/api/secured/infoscreen/createInfoscreen",
            { name, region }
          )
          .then((response) => {
            
            
            // setInfoscreens(response.data);
            setInfoscreensFetched(true);
            setInfoscreens(infoscreens.concat(response.data.new_is));
          })
          .catch((error) => console.error(error));
      } else {
        setInfoscreensFetched(true);
      }
    },
    [infoscreens]
  );

  const changeInfoscreenName = useCallback(
    (id, newName) => {
      setNewInfoscreenName("");
      setNewInfoscreenInProgress(false);
      if (newName) {
        setInfoscreensFetched(false);
        axios
          .post(
            env.protocol +
              env.env +
              "/api/secured/infoscreen/ChangeInfoscreenValues",
            {
              id,
              label: "name",
              value: newName,
              user: sessionStorage.getItem("vfiUser"),
            }
          )
          .then((response) => {
            
            
            // setInfoscreens(response.data);
            setInfoscreensFetched(true);
            const infoscreen = infoscreens.find((e) => e.inNameEdit === true);
            infoscreen.inNameEdit = false;
            infoscreen.name = newName;
            setInfoscreens([...infoscreens]);
            setEditInfoscreenName(false);
            // setInfoscreens(infoscreens.concat(response.data.new_is))
          })
          .catch((error) => console.error(error));
      } else {
        const infoscreen = infoscreens.find((e) => e.inNameEdit === true);
        infoscreen.inNameEdit = false;
        setInfoscreens([...infoscreens]);
      }
    },
    [infoscreens]
  );

  const cancelInfoscreenName = useCallback(() => {
    const infoscreen = infoscreens.find((is) => is.inNameEdit === true);
    infoscreen.inNameEdit = false;
    setInfoscreens([...infoscreens]);
    setEditInfoscreenInProgress(false);
    setEditInfoscreenName("");
  }, [infoscreens]);

  const deleteInfoscreen = useCallback(
    (id) => {
      setInfoscreensFetched(false);
      axios
        .post(`${env.protocol}${env.env}/api/secured/infoscreen/deleteInfoscreen`, {
          user: sessionStorage.getItem("vfiUser"),
          id: id,
        })
        .then((response) => {
          setInfoscreens(infoscreens.filter((is) => is.id !== id));
          setInfoscreensFetched(true);
        })
        .catch((error) => console.error(error));
    },
    [infoscreens]
  );

  const setOptionsList = (id, list, pos) => {
    setListIsOpen(true);
    setOptionsListOptions(list(id));
    setOptionsListPos(pos);
  };

  const editScreen = useCallback((id) => {
    // setIsLoading(true);
    setSelectedInfoscreen(id);
    setDisplay("preview");
  });

  let content = "Should not be shown.";
  switch (display) {
    case "leaflet":
      content = (
        <>
          <div
            className={
              "pages-list regions" + (regions.length > 0 ? " loaded" : "")
            }
          >
            {!regionsFetched && (
              <div className="load-icon">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            )}
            <div className="the-list">
              <div className="pages-headline top">
                <span>REGIONS</span>
              </div>
              {regions.map((r) => {
                return (
                  <div
                    key={r.id}
                    className={
                      "page" +
                      (selectedRegion === r.region_id ? " selected" : "")
                    }
                    onClick={() => {
                      setSelectedRegion(r.region_id);
                    }}
                  >
                    {r.region_name}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={
              "pages-list infoscreens" + (infoscreensFetched ? " loaded" : "")
            }
          >
            {infoscreensFetched === false && (
              <div className="load-icon">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            )}
            <div className="the-list">
              <div className="pages-headline top">
                <span>INFOSCREENS</span>
              </div>
              {infoscreens.map((is) => {
                return (
                  <div
                    key={is.id + (editInfoscreenInProgress ? "1" : "0")}
                    className={
                      "page" +
                      (selectedInfoscreen === is.id ? " selected" : "") +
                      (is.inNameEdit ? " text-input" : "")
                    }
                  >
                    {is.inNameEdit ? (
                      <VfiInputText
                        inputRef={editInfoscreenRef}
                        onEnter={(e) => {
                          changeInfoscreenName(is.id, e.target.value);
                        }}
                        onEscape={cancelInfoscreenName}
                        value={editInfoscreenName}
                        onChange={(e) => {
                          setEditInfoscreenName(e.target.value);
                        }}
                        onMount={(e) => {
                          e.current.focus();
                        }}
                        onBlur={(e) => {
                          changeInfoscreenName(is.id, e.target.value);
                        }}
                      />
                    ) : (
                      <>
                        {is.name}
                        <div
                          className="default-button"
                          onClick={() => {
                            setSelectedInfoscreen(is.id);
                          }}
                          onDoubleClick={() => {
                            editScreen(is.id);
                          }}
                        ></div>
                        <div
                          className="options-button"
                          onClick={(e) => {
                            setOptionsList(is.id, getIsOptionsList, {
                              x: e.pageX,
                              y: e.pageY,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              {newInfoscreenInProgress && (
                <div
                  className={
                    "page text-input" +
                    (newInfoscreenInProgress ? "" : " hidden")
                  }
                >
                  <VfiInputText
                    inputRef={newInfoscreenRef}
                    onEnter={(e) => {
                      createInfoscreen(e.target.value, selectedRegion);
                    }}
                    onEscape={() => {
                      setNewInfoscreenInProgress(false);
                      setNewInfoscreenName("");
                    }}
                    value={newInfoscreenName}
                    onChange={(e) => {
                      setNewInfoscreenName(e.target.value);
                    }}
                    onMount={(e) => {
                      e.current.focus();
                    }}
                    onBlur={(e) => {
                      createInfoscreen(e.target.value, selectedRegion);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className="add-page"
              onClick={() => {
                setNewInfoscreenInProgress(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <OptionsList
            isOpen={listIsOpen}
            options={optionsListOptions}
            position={optionsListPos}
            close={() => {
              setListIsOpen(false);
            }}
          />
        </>
      );
      break;
    case "preview":
      
      content = (
        <PreviewInfoscreen
          id={selectedInfoscreen}
          close={() => setDisplay("leaflet")}
          editInfoscreen={editInfoscreen}
          openOverlay={openOverlay}
          site={site}
        />
      );
      break;
    default:
      break;
  }

  return <div className="screen-management">{content}</div>;
}

ScreenManagement.propTypes = {};

export default ScreenManagement;
