import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import MainPages from "../../Pages/MainPages";
import "./PageChoose.scss";
import globalObject from "../../../../assets/globalVariables";

export default function PageChoose(props) {
  const [selectedPage, setSelectedPage] = useState(undefined);

  return (
    <div className="page-choose">
      <div className="headlineWrapper">
        <h2 className="headline">
          {props.data.headline ?? "Select page to insert"}
        </h2>
        <div
          className="close"
          onClick={() => {
            props.close();
          }}
        >
          <p className="icon">
            <FontAwesomeIcon icon={faTimes} />
          </p>
        </div>
      </div>
      <MainPages
        ignoreSidebar={true}
        language={props.language}
        excludePages={[props.data.pageId]}
        // setSiteRef={this.setSiteRef}
        // openOverlay={this.openOverlay}
        // newData={this.state.addOne}
        showInSidebar={props.showInSidebar}
        selectPage={(e) => {
          let languages = {};
          if (e.languages) {
            for (const [key, value] of Object.entries(e.languages)) {
              languages[e.languages[key].short] = value;
            }
          }

          if (props.data.pageMode !== "movePage") {
            props.selectPage({ ...e, id: e.id, languages });
            props.close();
          }

          if (props.data.pageMode === "movePage") {
            setSelectedPage({ ...e, id: e.id, languages });
          }
        }}
        pageMode={props.data.pageMode}
        ghostPage={
          selectedPage
            ? { ...props.data.pageDetails, child_of: selectedPage.id }
            : undefined
        }
        disableBottomSelect={props.data.disableBottomSelect}
        // disableSitesList={globalObject.vfiUser.superAdmin !== "1"}
        disableSitesList={false} // TODO: Use permCheck for validating permission
        disableSharedPages={props.data.disableSharedPages}
      />
      {props.data.pageMode === "movePage" && selectedPage && (
        <div
          className="move-page-save"
          onClick={() => {
            props.selectPage(selectedPage);
            props.close();
          }}
        >
          Save
        </div>
      )}
    </div>
  );
}
