import {
  faArrowRight,
  faSearch,
  faSpinner,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import VfiInputText from "../../VfiInputText/VfiInputText";
import "./Newsletter.scss";
import languages from "../language.json";
import axios from "axios";
import env from "../../../environment.json";
// import cloneDeep from "clone-deep";
import cloneDeep from "lodash.clonedeep";
import VfiCheckbox from "../../VfiCheckbox";
import VfiDivInput from "../../VfiDivInput";

const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export function NewsletterMO(props) {
  const [screen, setScreen] = useState("picks");
  const [search, setSearch] = useState("");
  const [newsTypes, setNewsTypes] = useState([]);
  const [fetchedTypes, setFetchedTypes] = useState(false);
  const [addingType, setAddingType] = useState(false);
  const [values, setValues] = useState(props.values);

  const lang = languages[language].newsletter;

  useEffect(() => {
    axios
      .get(
        env.protocol + env.env + "/api/public/newsletter/GetNewsletterTypes.php"
      )
      .then((response) => {
        setNewsTypes(response.data);
        setFetchedTypes(true);
      })
      .catch((error) => console.error(error));
  }, []);

  const setNewsletter = (e, checkbox) => {
    let copyValues = cloneDeep(values);
    copyValues.selectedTypes = copyValues.selectedTypes.filter((e) => e);
    if (checkbox.target.checked) {
      copyValues.selectedTypes.push(e);
    } else {
      const index = copyValues.selectedTypes.findIndex(
        (g) => g && g.id === e.id
      );
      copyValues.selectedTypes.splice(index, 1);
    }
    props.change(copyValues);
    setValues(copyValues);
  };

  const addNewsletterType = (typeName) => {
    setAddingType(true);
    axios
      .post(
        env.protocol + env.env + "/api/secured/newsletter/AddNewsletterType",
        {
          typeName,
        }
      )
      .then((response) => {
        if (response.data.id) {
          let copyNews = cloneDeep(newsTypes);
          copyNews.push({ ...response.data, checked: true });
          setNewsTypes(copyNews);
          setAddingType(false);
          setNewsletter(response.data, { target: { checked: true } });
        }
      })
      .catch((error) => console.error(error));
  };

  let content;
  switch (screen) {
    case "picks":
      content = (
        <div className="picks">
          <div className="news-types-list search">
            <FontAwesomeIcon icon={faSearch} />
            {/* <input placeholder={languages[language].search_placeholder} onChange={(e)=>props.setSearch(e.target.value)} /> */}
            <input
              placeholder={lang.search_placeholder}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          {search !== "" &&
            !newsTypes.find((e) => {
              return e.type.toLowerCase() === search.toLowerCase();
            }) &&
            !addingType && (
              <div
                className="add-option"
                onClick={() => {
                  addNewsletterType(search);
                  setSearch("");
                }}
              >
                + add "{search}" to list.
              </div>
            )}
          {addingType ||
            (!fetchedTypes && <FontAwesomeIcon icon={faSpinner} spin />)}
          <div className="newsletter-types">
            {
              // eslint-disable-next-line
              newsTypes.map((e) => {
                const theReturn = (
                  <div className="newsletter-type" key={e.id}>
                    <label>
                      <VfiCheckbox
                        checked={
                          values.selectedTypes.find((f) =>
                            f ? f.id === e.id : false
                          ) !== undefined
                        }
                        onChange={(f) => setNewsletter(e, f)}
                      />
                      <div className="text">{e.type}</div>
                    </label>
                    <div
                      className="trash"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove this newsletter?\nAction cannot be undone."
                          )
                        ) {
                          axios
                            .post(
                              env.protocol +
                                env.env +
                                "/api/secured/newsletter/DeleteNewsletter",
                              { id: e.id }
                            )
                            .then((response) => {
                              setNewsTypes(response.data);
                            })
                            .catch((error) => console.error(error));
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </div>
                );
                if (search === "") return theReturn;
                else {
                  if (e.type.toLowerCase().search(search.toLowerCase()) !== -1)
                    return theReturn;
                }
              })
            }
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="newsletter edit-module">
      <div className="top">
        <div
          className={"top-choice " + (screen === "picks" ? "active " : "")}
          onClick={() => setScreen("picks")}
        >
          PICKS
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}

export default function Newsletter(props) {
  const { data, onChange } = props;

  return (
    <div style={props.style} className="newsletter">
      <VfiDivInput
        className="headline"
        defaultValue={data.languages.headline}
        onBlur={(e) => {
          if (onChange)
            onChange({
              ...data,
              languages: {
                ...data.languages,
                headline: e.target.innerHTML,
              },
            });
        }}
      />
      <VfiInputText
        placeholder={"Email address"}
        className="email"
        disabled={true}
        customRightSide={
          <span className="email-btn">
            Add email <FontAwesomeIcon icon={faArrowRight} />
          </span>
        }
        customUnder={<span className="show-more">Show more</span>}
      />
    </div>
  );
}
