import {
  faArrowLeftLong,
  faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import { faSliders } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./Shop.scss";
import { v4 as uuid } from "uuid";

export default function Products(props) {
  const { allProducts, setSelectedProduct, close, configuration, compare } =
    props;
  const [filterState, setFilterState] = useState(false);
  const filter = () => {
    setFilterState(filterState ? false : true);
  };
  const [search, setSearch] = useState("");

  return (
    <div className="products list">
      <div className="top">
        <div>
          <div className="title">
            <div
              onClick={() => {
                close();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h1>Products</h1>
          </div>
          <div
            onClick={() => {
              setSelectedProduct({
                name: "",
                description: "",
                available: "draft",
                warehouse: { inStock: true, remote: false },
                rating: 0,
                slug: "",
                shipping: { weight: 0, dimension: 0 },
                uuid: uuid(),
                brand: null,
                product: null,
                varient: [],
                media: [],
                sellingPrice: 0,
              });
            }}
            className="add-product"
          >
            Add Product
          </div>
        </div>
        <div>
          <div className="search">
            <input
              type={"text"}
              onChange={(e) => {
                setSearch(e.target.value.toLowerCase());
              }}
              placeholder="Search products..."
            ></input>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <div className="wrap-filter">
            <div
              onClick={() => {
                filter();
              }}
              className={(filterState && "open") + " filter"}
            >
              Filter
              <FontAwesomeIcon icon={faSliders} />
            </div>
          </div>
        </div>
      </div>
      <div className="list">
        <table>
          <tbody>
            <tr>
              {/* <th>Id</th> */}
              <th></th>
              <th>Name</th>
              <th>Type</th>
              <th>Varients</th>
              <th>Available</th>
              <th>Dato</th>
            </tr>
            {allProducts
              .filter((x) => x.name.toLowerCase().includes(search))
              .map((element, i) => {
                element.media = element.media.sort(compare);

                // element.media = element.media.filter(x => [0, null].includes(x.imageType));
                let setMedia;
                element.media.forEach((media) => {
                  if (media.urls?.info) {
                    if(!media.urls.info.comment.toLowerCase().includes("brand")){
                      setMedia = media.urls.thumbnail;
                    }
                  }
                  if (!setMedia) {
                    if (media.imageType !== "brand") {
                      setMedia = media.external_url
                      // if (["import"].some(x => setMedia?.includes(x)) || setMedia === '') {
                      //   setMedia = "https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff"
                      // }
                    }
                  }
                })

                return (
                  <tr
                    key={"products" + i}
                    onClick={() => {
                      setSelectedProduct(element);
                    }}
                  >
                    {/* <td>{element.id}</td> */}
                    <td>
                      <div className="image">
                        <img
                          alt=""
                          src={
                            setMedia ??
                            "https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff"
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff";
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="wrap-name">

                        <div>
                          {element.name}
                        </div>
                      </div>
                    </td>
                    <td>{element.productType ?? 'No Type'}</td>
                    <td>{element.varient.length}</td>
                    <td>{element.available}</td>
                    <td>{element.reg_date}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
