import React, { useEffect, useState } from "react";
import "./Location.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import ReactMapGl, { Marker, Popup } from "react-map-gl";
import Axios from "axios";
import env from "../../../environment.json";
import languages from "../language.json";
import mapboxgl from "mapbox-gl";
import mapMarkers from "../../../assets/images/map-markers/map-markers";
import VfiInputText from "../../VfiInputText/VfiInputText";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import GoogleMapSearchBox from "../../GoogleMapSearchBox";
import { cloneDeep } from "lodash";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
  ];
}

export default function Location(props) {
  const { inEdit, data, onChange } = props;
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].read_also;
  const [pageTitle, setPageTitle] = useState("No page implemented");
  const [viewport, setViewport] = useState({
    viewState: {
      latitude: data.geo.latitude === 0 ? 61.8926 : data.geo.latitude,
      longitude: data.geo.longitude === 0 ? -6.9118 : data.geo.longitude,
      zoom: 16,
      transitionDuration: 500,
    },
  });

  const [settings, setsettings] = useState({
    dragPan: false,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
  });

  useEffect(() => {
    if (props.pageId)
      Axios.get(
        env.protocol +
          env.env +
          "/api/public/pages/GetPage.php?id=" +
          props.pageId
      )
        .then((response) => {
          if (response.data[props.selectedLanguage])
            setPageTitle(response.data[props.selectedLanguage].title);
        })
        .catch((error) => console.error(error));
  }, [props.pageId, props.selectedLanguage]);

  return (
    <div
      style={props.style}
      className={"location-lock " + (props.className ? props.className : "")}
    >
      <div className="map-wrap">
        <ReactMapGl
          {...viewport}
          {...settings}
          // // style="mapbox://styles/mapbox/streets-v9"
          mapStyle={"mapbox://styles/mapbox/light-v10"}
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          // center={mapCenter}
          // zoom={[mapZoom]}
          // onZoomEnd={(e) => { setMapZoom(e.style.z) }}
          width="100%"
          height="100%"
          mapboxApiAccessToken="pk.eyJ1IjoiYXNrb2RhbiIsImEiOiJja2drcWR4eW4xYWtuMnN0ZXJpNnJlemk3In0.Dy7UfnJQiXaBYYkN-okLpw"
          onViewStateChange={setViewport}
        >
          <Marker
            longitude={viewport.viewState.longitude}
            latitude={viewport.viewState.latitude}
            offsetLeft={-12}
            offsetTop={-24}
            style={{ cursor: "pointer" }}
            // onClick={() => {

            //   setPopup(place);
            //   sideScroll.current.scroll({ top: i * 120, left: 0, behaviour: 'smooth' });
            // }}
          >
            <FontAwesomeIcon icon={faLocationDot} />
          </Marker>
        </ReactMapGl>
      </div>
      <div className="contact-info">
        <div className="wrap-info">
          <div className="top">
            <div className="headline">
              <VfiCKeditor
                value={data.headline}
                onBlur={(e) => {
                  if (onChange)
                    onChange({
                      ...data,
                      headline: e,
                    });
                }}
              />
            </div>
            <div className="location">
              <GoogleMapSearchBox
                change={(e) => {
                  let fullAddress = e.formatted_address.split(",");
                  let address = fullAddress[0].split(" ").reverse();
                  let city = fullAddress[1].split(" ").sort();

                  let newViewport = cloneDeep(viewport);
                  newViewport.viewState.latitude = e.geometry.location.lat();
                  newViewport.viewState.longitude = e.geometry.location.lng();
                  setViewport(newViewport);
                  if (onChange)
                    onChange({
                      ...data,
                      geo: {
                        latitude: e.geometry.location.lat(),
                        longitude: e.geometry.location.lng(),
                        location: address.join(" ") + ", " + city.join(" "),
                        url: e.url,
                      },
                    });
                }}
                defaultValue={data.geo.location}
                name="address"
                listResult={true}
                className="search-box"
              />
            </div>
            <div className="phone">
              <VfiInputText
                defaultValue={data.phone}
                onBlur={(e) => {
                  if (onChange)
                    onChange({
                      ...data,
                      phone: e.target.value,
                    });
                }}
              />
            </div>
            <div className="email">
              <VfiInputText
                defaultValue={data.email}
                onBlur={(e) => {
                  if (onChange)
                    onChange({
                      ...data,
                      email: e.target.value,
                    });
                }}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="more-info">
              <VfiCKeditor
                value={data.moreInfo}
                onBlur={(e) => {
                  if (onChange)
                    onChange({
                      ...data,
                      moreInfo: e,
                    });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
