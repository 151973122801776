import React, { Component } from "react";
import env from "../../environment.json";
import "./Login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUser } from "@fortawesome/pro-solid-svg-icons";
import FacebookLogin from "react-facebook-login";
import vfiLogo from "../../assets/images/vfi-logo.png";
import vitaLogo from "../../assets/images/vita-logo.png";
import CreateWhatson from "./CreateWhatson";
import SubmitChange from "./submitChange";
import globalObject from "../../assets/globalVariables";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./NewPassword";
import RegisterTable from "./RegisterTable";
import axios from "axios";
import LoginOrRegister from "./LoginOrRegister";

class Login extends Component {
  constructor(props) {
    super(props);

    this.loginInfo = {
      email: "",
      psw: "",
      facebookLogin: undefined,
      img: "",
    };
    this.axios = require("axios");
    this.state = {
      loading: false,
      emailHasValue: false,
      passwordHasValue: false,
      showPassword: false,
      rememberMe: false,
      navTo: "",
      urlToGoBack: "",
      id: 0,
      error: "",
      shown: "login",
      register: false,
      redirect: "none",
    };

    this.createProfileClick = this.createProfileClick.bind(this);
  }

  componentDidMount() {
    // Why router in login????

    if (window.location.hash.split("/")[1] === "createEvent") {
      this.setState({ navTo: "create.Event" });
      this.setState({ urlToGoBack: window.location.hash.split("/")[2] ?? "" });
    } else if (window.location.hash.split("/")[1] === "createPlace") {
      this.setState({ navTo: "create.Place" });
      this.setState({ urlToGoBack: window.location.hash.split("/")[2] ?? "" });
    } else if (window.location.hash.split("/")[1] === "createTour") {
      this.setState({ navTo: "create.Tour" });
      this.setState({ urlToGoBack: window.location.hash.split("/")[2] ?? "" });
    } else if (window.location.hash.split("/")[1] === "changeEvent") {
      this.setState({ navTo: "submit.Event" });
      this.setState({ id: window.location.hash.split("/")[2] });
      this.setState({ urlToGoBack: window.location.hash.split("/")[3] ?? "" });
    } else if (window.location.hash.split("/")[1] === "changePlace") {
      this.setState({ navTo: "submit.Place" });
      this.setState({ id: window.location.hash.split("/")[2] });
      this.setState({ urlToGoBack: window.location.hash.split("/")[3] ?? "" });
    } else if (window.location.hash.split("/")[1] === "changeTour") {
      this.setState({ navTo: "submit.Tour" });
      this.setState({ id: window.location.hash.split("/")[2] });
      this.setState({ urlToGoBack: window.location.hash.split("/")[3] ?? "" });
    } else if (window.location.hash === "#/createPlace") {
      this.setState({ navTo: "Place" });
    } else if (window.location.hash === "#/createTour") {
      this.setState({ navTo: "Tour" });
    } else if (window.location.hash.split("/")[1] === "newPassword") {
      this.setState({ navTo: "newPassword" });
      this.setState({ hash: window.location.hash.split("/")[2] });
      this.setState({ email: window.location.hash.split("/")[3] });
    } else if (
      window.location.hash.split("/")[1] === "whatson" &&
      window.location.hash.split("/")[3] === "add"
    ) {
      if (
        ["events", "places", "hiking", "tours", "tour_operator"].includes(
          window.location.hash.split("/")[2]
        )
      ) {
        this.setState({
          register: true,
          navTo: "login",
          redirect: window.location.hash.split("/")[1],
        });
      }
    } else if (window.location.hash.split("/")[1] === "register") {
      // Quick fix for new registration create/change-submit flow

      let splitUrl = window.location.hash.split("/");
      this.setState({
        register: true,
        navTo: "register",
      });
      if (splitUrl.length > 2) {
        switch (splitUrl[2].split("?")[0]) {
          case "createEvent":
            this.setState({ redirect: "Event" });
            break;
          case "createPlace":
            this.setState({ redirect: "Place" });
            break;
          case "createTour":
            this.setState({ redirect: "Tour" });
            break;
          case "createTourOperator":
            this.setState({ redirect: "Tour-operator" });
            break;
          case "changeEvent":
            this.setState({ redirect: "submit.Event" });
            break;
          case "changePlace":
            this.setState({ redirect: "submit.Place" });
            break;
          default:
            this.setState({ redirect: "none" });
        }
      }
    }
  }

  loginForm(e, define) {
    if (define === "uname") {
      this.loginInfo.email = e.target.value;
      if (e.target.value !== "") {
        this.setState({ emailHasValue: true });
      } else {
        this.setState({ emailHasValue: false });
      }
    }
    if (define === "psw") {
      this.loginInfo.psw = e.target.value;

      if (e.target.value !== "") {
        this.setState({ passwordHasValue: true });
      } else {
        this.setState({ passwordHasValue: false });
      }
    }
  }

  login(e) {
    this.setState({ loading: true });

    this.axios
      .post(env.protocol + env.env + "/api/public/login.php", this.loginInfo)
      .then((response) => {
        if (response.data.loginSuccess) {
          sessionStorage.setItem("vfiUser", response.data.id);
          this.setState({ loading: false });
          this.props.loginSuccess();
        } else {
          this.setState({ loading: false });

          this.setState({ error: "Email or password is incorrect" });
        }
      });
  }

  responseFacebook = (response) => {
    // response = {accessToken: "EAAHZAeqJ6lPIBAKjuegiPz4PR7XoqQApZBeVFrUEfaQALumbZBEd6TMy7IxjMABBfpFWDJrDR0ta0wPoQnRocrG32UlgThZCervoWKuGv1zFLVLCiW75VaoMwgSKnUCpkRaUpqFSDR0V5k3IOx3OhWmfhZCQPdUroyAFuRv5QrbwyHelhPPLqQPlNmZCFF4kii1AZAXif8FtIJg2du6yjHa",
    // data_access_expiration_time: 1664784057,
    // email: "hemming_lol@hotmail.com",
    // expiresIn: 7143,
    // graphDomain: "facebook",
    // id: "10223239659677581",
    // name: "Hemming Petersen",
    // picture: { data: {
    //   height: 50,
    //   is_silhouette: false,
    //   url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?as…1&height=50&width=50&ext=1659600058&hash=AeSR60BGPDSuCTW0vB0",
    //   width: 50
    // } },
    // signedRequest: "-lLUzTBrRk5xrKGP7wgsFWR7UFlroEnTg5CXqjVoLOk.eyJ1c2VyX2lkIjoiMTAyMjMyMzk2NTk2Nzc1ODEiLCJjb2RlIjoiQVFEejJmUGd3TDRiMUJPcFZFVjJIRjR4aWVCS1dhbjUyZzMtWG0wbk8zNHROVTBRbkFMSnVzSWo1a2VudTJCR2pBcVBLLTZEbGU3QS1uY0ZCOHF1ZDNRUGM1azNZVjRWSWlVX0g4WF9lTDNrRjFnSHl4cjlJR0RKY1JxQVZRMS00S0RmSHVIN3ZENlZ1SFdrMGFBYmlySXRObDZRc1UxUTR5dXZKQ2ZEMF9yaWpjTUdUc2RaQS1jSkRjNzRpRm5vdDJzSkdqamM1TkRqdWFqS2x4XzBaWGhjWERINzBfQ2lzRkQ1ejl4M0ZiV3E2WkpiR2NGYTYxa0FEWTgzSUV0R0xWZGJOSTl3YTk1ZXlJUkZ4VEFlX1VPQ052a3JLNVpHWHV4YW5VWF9ZSzdXUldlUl9rLWdJOG5sOEVjNXpJUnhiRk04REdZNC1LdTFKTjY1ZXJPTFNQVnQiLCJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImlzc3VlZF9hdCI6MTY1NzAwODA1N30",
    // userID: "10223239659677581"}
    // if (sessionStorage.getItem("loginWithFacebook") === "enabled") {
    this.loginInfo.email = response.email;
    this.loginInfo.facebookLogin = 1;
    this.loginInfo.psw = "";
    this.loginInfo.img = response.picture.data.url;
    // this.login();
    // }

    let e = response;

    axios
      .post(env.protocol + env.env + "/api/public/facebookAuth.php", {
        access_token: e.accessToken,
      }) //userData)
      .then((response) => {
        var data = response.data;

        if (response.data.loginSuccess) {
          sessionStorage.setItem("vfiUser", data.id);

          this.props.loginSuccess();
        }
      });
  };

  createProfileClick() {
    return;

    this.setState({
      navTo: "register",
      register: true,
    });
  }

  createWhatsonEvent = () => {
    return;

    window.location.hash = "/register/createEvent";
    window.history.pushState(null, "", "createEvent");
    this.setState({
      navTo: "register",
      redirect: "Event",
    });
  };

  render() {
    const rememberChecked = this.state.rememberMe ? "checked" : "";

    switch (this.state.navTo) {
      case "newPassword":
        return <NewPassword hash={this.state.hash} email={this.state.email} />;
      case "create.Event":
      case "create.Place":
      case "create.Tour":
        return (
          <CreateWhatson
            navTo={this.state.navTo.split(".")[1]}
            urlToGoBack={this.state.urlToGoBack}
            setNav={(e) => {
              this.setState({ navTo: e });
            }}
            redirect={this.state.redirect}
            register={this.state.register}
          />
        );
      case "register":
        return (
          <CreateWhatson
            navTo={"register"}
            urlToGoBack={this.state.urlToGoBack}
            setNav={(e) => {
              this.setState({ navTo: e });
            }}
            redirect={this.state.redirect}
            register={this.state.register}
          />
        );
      case "login":
        return (
          <LoginOrRegister
            navTo={"login"}
            redirect={this.state.redirect}
            register={this.state.redirect}
            loginSuccess={this.props.loginSuccess}
          />
        );
      case "submit.Event":
      case "submit.Place":
      case "submit.Tour":
        return (
          <SubmitChange
            navTo={this.state.navTo.split(".")[1]}
            id={this.state.id}
            urlToGoBack={this.state.urlToGoBack}
          />
        );

      default:
        return (
          <div
            className={
              env.env.includes("vita.fo")
                ? "vita login-contents"
                : "login-contents"
            }
          >
            <div className="content col-sm-6">
              <div className={"imgcontainer"}>
                {/* <img
                            src={require("../../../assets/logo.png")}
                            alt="Avatar"
                            className="avatar"
                        /> */}
                <img
                  className="vfi-logo"
                  src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo}
                  alt="vfi-logo"
                />

                <span className="vfi-text">
                  {env.env.includes("vita.fo")
                    ? [
                        <span>
                          <img
                            className="vita-logo"
                            src={vitaLogo}
                            alt="vita-logo"
                          />
                        </span>,
                        <span className="dash">|</span>,
                        <span className="type">CMS</span>,
                      ]
                    : "Visit Faroe Islands"}
                </span>
              </div>
              {this.state.shown === "login" && (
                <div id="Login">
                  {this.props.topMessage && (
                    <div className="top-message">{this.props.topMessage}</div>
                  )}
                  {this.state.error !== "" && (
                    <div className="error-message">{this.state.error}</div>
                  )}
                  <div className="wrap-container">
                    <div className="container">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.login();
                        }}
                      >
                        <input
                          className={this.state.emailHasValue ? "" : "no-value"}
                          type="email"
                          placeholder="Email"
                          name="uname"
                          onChange={(e) => {
                            this.loginForm(e, "uname");
                          }}
                          required
                        />
                        <input
                          className={
                            "password " +
                            (this.state.passwordHasValue ? "" : "no-value")
                          }
                          type={!this.state.showPassword ? "password" : "text"}
                          placeholder="Password"
                          name="psw"
                          onChange={(e) => {
                            this.loginForm(e, "psw");
                          }}
                          required
                        />
                        <div className="show-password-container">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.showPassword}
                              onChange={() => {
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                });
                              }}
                            />
                            <span
                              className={"show-password " + rememberChecked}
                            >
                              Show password
                            </span>
                          </label>
                        </div>
                        <div className="remember-me-container">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.rememberMe}
                              onChange={() => {
                                this.setState({
                                  rememberMe: !this.state.rememberMe,
                                });
                              }}
                            />
                            <span className={"remember-me " + rememberChecked}>
                              Remember me
                            </span>
                          </label>
                        </div>
                        <button
                          onClick={() => {
                            this.login();
                          }}
                          type="submit"
                        >
                          {this.state.loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form>
                      <div
                        className="cannot-login"
                        onClick={() => {
                          this.setState({ shown: "forgotPsw" });
                        }}
                      >
                        Can't log in?
                      </div>
                      <div className="or-container">
                        <div className="or-line" />
                        <div className="or-word">or</div>
                        <div className="or-line" />
                      </div>
                      <FacebookLogin
                        appId="520595711890674"
                        // autoLoad={true}
                        fields="name,email,picture"
                        onClick={this.componentClicked}
                        callback={this.responseFacebook}
                        cssClass="my-facebook-button-class"
                        textButton={
                          <div>
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                              alt="fb-logo"
                            />
                            <span className="fb-text">
                              Continue with Facebook
                            </span>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.shown === "forgotPsw" && (
                <ForgotPassword
                  goBack={() => this.setState({ shown: "login" })}
                />
              )}
            </div>
            {(window.location.hostname === "localhost" ||
              window.location.hostname === "127.0.0.1") && [
              <div
                key={"create-profile"}
                className="create-profile"
                onClick={this.createProfileClick}
              >
                <span className="text">CREATE PROFILE</span>
                <FontAwesomeIcon icon={faUser} />
              </div>,
              <div
                key={"create-event"}
                className="create-event"
                onClick={this.createWhatsonEvent}
              >
                <span className="text">CREATE WHATSON EVENT</span>
                <FontAwesomeIcon icon={faUser} />
              </div>,
            ]}
          </div>
        );
    }
  }
}

export default Login;
