import axios from "axios";
import env from "../../environment.json";

export const userStore = (set) => ({
  loggedInUser: {},
  permissions: {},
  permissionsCheck: {},
  users: [],
  roles: [
    // { id: 1, permission: "Admin", permission_value: 200 },
    // { id: 2, permission: "Publisher", permission_value: 100 },
    // { id: 3, permission: "Collaborator", permission_value: 50 }, // Giving data for the reason that endpoint call doesn't work..
    { id: -1, permission: "not_a_role_name", permission_value: 0 },
  ],
  getLoggedInUser: () => {
    axios
      .get(`${env.protocol}${env.env}/api/secured/GetMyUser`)
      .then((response) => {
        set({
          loggedInUser: response.data,
          permissions: response.data.permissions,
          permissionsCheck: response.data.permissions_check,
        });
      })
      .catch((error) => console.error(error));
  },
  getUsers: (e) => {
    axios
      .get(`${env.protocol}${env.env}/api/secured/GetUsers`)
      .then((response) => {
        set({ users: response.data });
      })
      .catch((error) => console.error(error));
  },
  getRoles: () => {
    axios
      .get(`${env.protocol}${env.env}/api/secured/GetUserRoles`)
      .then((response) => {
        set({ roles: response.data });
      })
      .catch((error) => console.error(error));
  },
});
