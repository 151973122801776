import React, { useEffect, useState } from "react";
import "./appearances.scss";
import axios from "axios";
import env from "../../../../../environment.json";
import DoubleTree from "../../../../Sidebar/doubleTree/doubleTree";
import EditHeader from "./editHeader";
import EditPage from "../../../Pages/EditPage/EditPage";
import { compare, getEditPage } from "./../../../../../assets/helperFunctions";
import EditSitesMenu from "./EditSitesMenu";
import EditFooter from "./editFooter";
import Themes from "./themes";
import languages from "./language.json";
import Leaflet from "src/Components/Content/Leaflet/Leaflet";
import Pages from "./doubleTree/pages";
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";
// import languages from "./language.json";
// const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

export default function Appearances(props) {
  const [currentLocation, setCurrentLocation] = useState("main");
  const [siteSelected, setSiteSelected] = useState({});
  const [sites, setSites] = useState([]);
  const [otherSites, setOtherSites] = useState([]);
  const [editData, setEditData] = useState({});
  const [refreshTime] = useState(new Date().getTime());
  const [, setListIsOpen] = useState(false);
  const [, setOptionsListOptions] = useState([]);
  const [, setOptionsListPos] = useState({});
  const [, setOptionsListKey] = useState("");
  const [pageSelected, setPageSelected] = useState({});

  const theLanguage = languages[language];
  const mypages = [
    { show: "Menu", label: theLanguage.site_options.header },
    { show: "Sites menu", label: theLanguage.site_options.sites_menu },
    { show: "Footer", label: theLanguage.site_options.footer },
    { show: "Themes", label: theLanguage.site_options.themes },
  ];
  const sections = [
    {
      title: "Settings",
      views: [
        { name: "Menu", key: "1" },
        { name: "Sites menu", key: "2" },
        { name: "Footer", key: "3" },
        { name: "Themes", key: "4" },
      ],
    },
  ];

  // const theLanguage = languages[language];
  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        setSites(response.data.sort(compare));
        setOtherSites(response.data.filter((x) => x.other_sites === "1"));
      })
      .catch((error) => console.error(error));
  }, []);

  const editPage = (id, isShared = false) => {
    // props.setIsLoading(true);
    getEditPage(id, isShared).then((data) => {
      setEditData(data);
      setCurrentLocation("editPage");
    });
  };
  const setEditPage = (element) => {
    setCurrentLocation(element.show);
  };

  const setOptionsList = (id, options, pos) => {
    setListIsOpen(true);
    setOptionsListOptions(options);
    setOptionsListPos(pos);
    setOptionsListKey(new Date().getTime());
  };
  let content = <>Empty</>;
  switch (currentLocation) {
    case "editPage":
      content = (
        <EditPage
          key={editData.page.id + " " + refreshTime}
          data={editData}
          newData={props.newData}
          setSiteRef={props.setSiteRef}
          screenSize={editData.screenSize}
          openOverlay={props.openOverlay}
          //   setIsLoading={(e) => setIsLoading(e)}
          close={() => {
            setCurrentLocation("Menu");
            // setIsLoading(false);
          }}
          //   deletePage={deletePage}
          history="appearances"
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate}
          editPage={(data) => {
            setEditData(data);
            setCurrentLocation("editPage");
          }}
        />
      );

      break;
    case "main":
      if (!siteSelected) {
        content = <></>;
        break;
      }

      content = (
        <div className={"main-pages"}>
          <div className={"header absolute row"}>
            <div className="left col-lg-10">
              {/* <span className="title">{"Appearances"}</span> */}
            </div>
          </div>
          <div className="wrap-tree-edit">
            <Leaflet
              title={""}
              sections={[
                {
                  title: "Sites",
                  views: sites,
                },
              ]}
              selectedView={siteSelected.name}
              setSelectedView={() => {}}
              add={{
                call: () => {},
                active: false,
              }}
              customCall={(view) => {
                setSiteSelected(sites.find((e) => e.name == view));
                props.setSiteRef(sites.find((e) => e.name == view).siteId);
              }}
            />
            {siteSelected?.siteId && (
              <>
                <Leaflet
                  title={""}
                  sections={[
                    {
                      title: "Settings",
                      views: mypages
                        .map((page) => {
                          return page.show;
                        })
                        .filter(
                          (e) => !(e === "Themes" && siteSelected.rootPage)
                        ),
                    },
                  ]}
                  selectedView={""}
                  setSelectedView={() => {}}
                  add={{
                    call: () => {},
                    active: false,
                  }}
                  customCall={(view) => {
                    setEditPage({
                      show: view,
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
      );
      break;
    case "Menu":
      content = (
        <div key={"wrap"} className="wrap-component">
          <EditHeader
            key={"editHeader"}
            close={() => {
              setCurrentLocation("main");
              // setIsLoading(false);
            }}
            editPage={editPage}
            orderPage={props.orderPage}
            setOptionsListKey={setOptionsListKey}
            setOptionsList={setOptionsList}
            siteSelected={siteSelected}
            openOverlay={props.openOverlay}
          />
        </div>
      );
      break;
    case "Sites menu":
      content = (
        <EditSitesMenu
          close={() => {
            setCurrentLocation("main");
            // setIsLoading(false);
          }}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate} // Not used
          siteSelected={siteSelected}
        />
      );
      break;
    case "Footer":
      content = (
        <EditFooter
          key={"editFooter"}
          close={() => {
            setCurrentLocation("main");
            // setIsLoading(false);
          }}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate} // Not used
          siteSelected={siteSelected}
        />
      );
      break;
    case "Themes":
      content = (
        <Themes
          key={"themes"}
          close={() => {
            setCurrentLocation("main");
            // setIsLoading(false);
          }}
          setConfirmBeforeNavigate={props.setConfirmBeforeNavigate} // Not used
          siteSelected={siteSelected}
        />
      );
      break;
    default:
      break;
  }

  return content;
}
