import { overlayStore } from "./OverlayStore";

export default function Overlays() {

    // An intermeidary for our overlays, 
    // easier way of rendering our overlays in react components
 
    const { overlays, addOverlay } = overlayStore((state: any) => state);
    
    return (
        <>
            {Object.keys(overlays).map((key: string) => {
                return (
                    overlays[key].map((properties: any) => {
                        return properties.overlay;
                    })
                );
            })}

            {/* For testing */}

            {/*<button 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                }}
                onClick={() => {
                    addOverlay('card', (close: any) => <div>123</div>)
                }}>
                Click me!
            </button> */}

        </>
    );
}