import { navigationElements } from "./navigationElements";

export function initRouteArray(): any[] {
  let arr = window.location.hash.split("/");
  arr.shift();

  let sidebarArr: any[] = Array.from(
    { length: arr.length },
    (_, i: number) => ""
  );
  let displayArr: any[] = Array.from(
    { length: arr.length },
    (_, i: number) => ""
  );

  let el: any = navigationElements[arr[0]];

  let obj: any = {};
  let routeArr: any[] = [];

  arr[0] = el ? el.nav : navigationElements.home.nav;
  sidebarArr[0] = el ? el.sidebar : navigationElements.home.sidebar;
  displayArr[0] = el ? el.displayName : navigationElements.home.displayName;

  obj = {
    nav: el ? el.nav : navigationElements.home.nav,
    sidebar: el ? el.sidebar : navigationElements.home.sidebar,
    displayName: el ? el.displayName : navigationElements.home.displayName,
  };

  if (el?.sections) obj["sections"] = el.sections;
  routeArr.push(obj);

  for (let i = 1; i < arr.length; i++) {
    obj = obj?.["sections"];
    obj = obj?.[arr[i]]? obj?.[arr[i]] : obj?.default;

    let vals: string[] = arr[i].split('-');

    if (vals[0] === 'edit') {
        obj.nav = arr[i];
        obj.displayName = arr[i];
    }

    routeArr.push(obj);
  }  

  return routeArr;
}
