import { Component } from "react";
import env from "../../../../environment.json";
import {
  faInfo,
  faCheck,
  faMapMarkerAlt,
  faImage,
  faTimes as faTimes2,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import WhatsonNavigation from "../WhatsonNavigation";
import "./NewTourOperator.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { allObjTrue } from "../../../../assets/helperFunctions";
import loadash, { cloneDeep } from "lodash";
import language from "../language.json";
import {
  mediaPassed,
  informationPassed,
  locationPassed,
} from "./TourOperatorVerify";
import Information from "./Information";
import Media from "./Media";
import Location from "./Location";
import Team from "./Team";
import CompleteRegistration from "./CompleteRegistration";
import axios from "axios";

class NewTourOperator extends Component {
  /**
   * NewTourOperator constructor
   *
   * @param 		{int} 			index 								Step bubble index
   * @param 		{function} 	indexChange 					Index change function used with WhatsonNavigation
   * @param 		{function} 	stepBubble 						Function for rendering step bubble
   * @param 		{object} 		updateData 						Data to update, fetched from server
   * @param 		{function} 	autoSave 							Auto save function
   * @param			{function} 	blurScreen 						Blur screen function
   * @param 		{string} 		language 							Chosen language ("english" or "faroese")
   * @param 		{function} 	closeTourOperator			Close tour operator function
   * @param 		{function} 	openOverlay 					Open overlay function
   * @param 		{function} 	setSiteRef 						Set site reference
   *
   * @author 					Pætur Mortensen
   */
  constructor(props) {
    super(props);

    this.state = {
      beenEdit: {
        textFo: {
          headline: false,
          description: false,
          shortDescription: false,
        },
        textEn: {
          headline: false,
          description: false,
          shortDescription: false,
        },
        images: { mainImages: false },
        address: false,
        contactDetails: {
          website: false,
          email: false,
          phone: false,
          phoneCC: false,
        },
        instagram: false,
        team: false,
        optionalMedia: false,
      },
    };

    // Get tour operator language object
    this.language = language[this.props.language].tour_providers.new;

    // Set the newTourOperator object (used to contain TourOp data)
    this.newTourOperator = this.get_new_tour_operator();

    // If there is update data (we're updating, not creating new)
    if (props.updateData !== undefined) {
      this.set_update_data();
    }

    // Init newTourOperatorSaved as equal to new TourOperator (will be compared for autosave)
    this.newTourOperatorSaved = cloneDeep(this.newTourOperator);
  }

  /**
   * Builds and returns the newTourOperator object
   *
   * @returns 	{object} 												newTourOperator
   *
   * @author 					Pætur Mortensen
   */
  get_new_tour_operator() {
    // New tour operator object contains all data on the new tour operator for manipulation,
    // saving etc.
    return {
      id: null,
      textFo: {
        showIt: true,
        headline: "",
        shortDescription: "",
        description: "",
      },
      textEn: {
        showIt: true,
        headline: "",
        shortDescription: "",
        description: "",
      },
      images: { mainImages: [] },
      address: {
        mapMarker: { lat: 62.0107, lng: -6.7741 },
        name: "",
        region: "",
        zip: "",
      },
      contactDetails: {
        phoneCC: 298,
        phone: "",
        website: "",
        email: "",
      },
      instagram: {
        active: false,
        profile: "",
        hashtag: "",
        images: [],
      },
      team: {
        members: [],
      },
      optionalMedia: [],
    };
  }

  /**
   * If we are editing tour operator, we set update data here
   *
   * @author 					Pætur Mortensen
   */
  set_update_data() {
    this.newTourOperator = this.props.updateData.tourOperator;

    this.setState({ beenEdit: allObjTrue(this.state.beenEdit) });
  }

  /**
   * Save changes to tour operator
   *
   * Pushes changes to the server
   *
   * @param 		{string} 	status 								Status to set for save
   * 																						("draft", "published", or "deleted")
   *
   * @author 					Pætur Mortensen
   */
  save_changes = (status = "draft") => {
    // If there have been changes since last saved state, OR if we're publishing...
    if (
      !loadash.isEqual(this.newTourOperatorSaved, this.newTourOperator) ||
      status === "published"
    ) {
      const vfiUser = sessionStorage.getItem("vfiUser");

      const link =
        env.protocol + env.env + "/api/secured/whatson/touroperators/AddUpdateTourOp";
      const isNewTourOperator = !this.newTourOperator.id;

      axios
        .post(link, {
          ...this.newTourOperator,
          status,
          vfiUser,
        })
        .then((response) => {
          // Get new value from response
          this.newTourOperator = response.data.tourOperator;

          // Autosave the new tour operator to the list of tour operators
          this.props.autoSave(this.newTourOperator, isNewTourOperator);

          // Set the newTourOperatorSaved for comparison
          this.newTourOperatorSaved = loadash.cloneDeep(this.newTourOperator);

          this.setState({});
        })
        .catch((error) => console.error(error));
    }
  };

  /**
   * Set an autosave interval
   *
   * Every few seconds, the system will check for changes, and if there are any, will autosave
   *
   * @author 					Pætur Mortensen
   */
  set_autosave_interval() {
    this.saveInterval = setInterval(() => {
      if (!loadash.isEqual(this.newTourOperatorSaved, this.newTourOperator)) {
        // Do the auto-save
        this.save_changes();
      }
    }, 30000);
  }

  componentDidMount() {
    this.set_autosave_interval();
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
  }

  /**
   * Check form validation
   *
   * @param 		{bool} 	ignoreBeenEdit 						Whether to ignore been edit (for submit)
   *
   * @author 					Pætur Mortensen
   */
  check_validation(ignoreBeenEdit = false) {
    const showEn = this.newTourOperator.textEn.showIt;
    const showFo = this.newTourOperator.textFo.showIt;
    const beenEdit = ignoreBeenEdit ? null : this.state.beenEdit;

    this.informationPassed = informationPassed(
      showEn,
      showFo,
      beenEdit,
      this.newTourOperator
    );
    this.mediaPassed = mediaPassed(
      showEn,
      showFo,
      beenEdit,
      this.newTourOperator
    );
    this.locationPassed = locationPassed(beenEdit, this.newTourOperator);

    this.allPassed =
      this.informationPassed && this.mediaPassed && this.locationPassed;
  }

  /**
   * Validate all fields for submission
   *
   * This calls check_validation, but with the ignoreBeenEdit flag set to true.
   *
   * @returns 	{bool} 									Whether all validation passes for submit
   *
   * @author 					Pætur Mortensen
   */
  check_submit_validation() {
    this.check_validation(true);

    return this.allPassed;
  }

  show_preview() {
    // TODO show the preview
  }

  /**
   * Render the whatson navigation CP
   *
   * @param 		{object} 	params 						Parameters to use for the CP
   *
   * @returns 	{jsx} 											Whatson navigation CP
   *
   * @author 					Pætur Mortensen
   */
  render_whatson_navigation = (params) => {
    params = params || {};

    return (
      <WhatsonNavigation
        language={this.props.language}
        indexChange={this.props.indexChange}
        isFront={false}
        undoAndRedoDisabled={true}
        forwardDisabled={params.forwardDisabled || false}
        backwardDisabled={params.backwardDisabled || false}
        saveDraft={this.save_changes}
        customForwardText={params.customForwardText || false}
        preview
        showPreview={() => {
          this.show_preview();
        }}
        customForwardFunc={params.customForwardFunc || false}
      />
    );
  };

  /**
   * Render the form content
   *
   * @returns 	{jsx} 												Form content, depending on which step we are on
   *
   * @author 					Pætur Mortensen
   */
  render_content() {
    switch (this.props.index) {
      case 0:
        return (
          <Information
            newTourOperator={this.newTourOperator}
            language={this.language}
            state={this.state}
            setState={(state) => this.setState(state)}
            whatsonNavigation={(config) =>
              this.render_whatson_navigation(config)
            }
          />
        );
      case 1:
        return (
          <Media
            newTourOperator={this.newTourOperator}
            language={this.language}
            saveChanges={this.save_changes}
            state={this.state}
            setState={(state) => this.setState(state)}
            setSiteRef={this.props.setSiteRef}
            openOverlay={this.props.openOverlay}
            whatsonNavigation={(config) =>
              this.render_whatson_navigation(config)
            }
          />
        );
      case 2:
        return (
          <Location
            newTourOperator={this.newTourOperator}
            language={this.language}
            state={this.state}
            setState={(state) => this.setState(state)}
            whatsonNavigation={(config) =>
              this.render_whatson_navigation(config)
            }
          />
        );
      case 3:
        return (
          <Team
            newTourOperator={this.newTourOperator}
            language={this.language}
            saveChanges={this.save_changes}
            setSiteRef={this.props.setSiteRef}
            openOverlay={this.props.openOverlay}
            whatsonNavigation={(config) =>
              this.render_whatson_navigation(config)
            }
          />
        );
      case 4:
        return (
          <CompleteRegistration
            newTourOperator={this.newTourOperator}
            language={this.language}
            checkValidation={() => this.check_submit_validation()}
            saveChanges={this.save_changes}
            outerSetState={(state) => {
              this.props.setState(state);
            }}
            whatsonNavigation={(config) =>
              this.render_whatson_navigation(config)
            }
          />
        );
      default:
        return <div>"Should not get here..."</div>;
    }
  }

  /**
   * Render the step bubbles in the header
   *
   * @returns 		{jsx} 								Header step bubbles
   *
   * @author 					Pætur Mortensen
   */
  render_step_bubbles() {
    return (
      <div className="header-display" /* ref={this.headerRef} DEPRECATE */>
        {this.props.stepBubble(
          faInfo,
          this.language.info.bubble_text,
          0,
          this.informationPassed,
          false,
          false
        )}
        {this.props.stepBubble(
          faImage,
          this.language.media.bubble_text,
          1,
          this.mediaPassed,
          false,
          false
        )}
        {this.props.stepBubble(
          faMapMarkerAlt,
          this.language.place.bubble_text,
          2,
          this.locationPassed,
          false,
          false
        )}
        {this.props.stepBubble(
          faUsers,
          this.language.team.bubble_text,
          3,
          true,
          false,
          false
        )}
        {this.props.stepBubble(
          faCheck,
          this.props.updateData === undefined
            ? this.language.register.bubble_text_register
            : this.language.register.bubble_text_update,
          4,
          true,
          false,
          true
        )}
      </div>
    );
  }

  render() {
    // Check validation before rendering
    this.check_validation();

    return (
      <div className="new-tour-operator">
        <div
          className="close-tour-operator"
          onClick={this.props.closeTourOperator}
        >
          <FontAwesomeIcon icon={faTimes2} />
        </div>
        {this.render_step_bubbles()}
        <div className="w-content">{this.render_content()}</div>
      </div>
    );
  }
}

export default NewTourOperator;
