import React, { useEffect, useState } from "react";
import "./PagesList.scss";
import axios from "axios";
import env from "../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faEyeSlash, faPlus } from "@fortawesome/pro-light-svg-icons";
import Page from "./Page";
import cloneDeep from "lodash.clonedeep";
import {
  forceCheckLanguage,
  getEditPage,
} from "../../../assets/helperFunctions";
import languages from "./language.json";
import ToolTip from "../../ToolTips/ToolTip";

export function newPage(site, page, values, afterFunc = () => void 0) {
  axios
    .post(env.protocol + env.env + "/api/secured/pages/AddPage", {
      user: sessionStorage.getItem("vfiUser"),
      site,
      page,
      values,
      languages: ["en"],
    })
    .then((response) => {
      afterFunc(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export default function PagesList(props) {
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState({});
  const [pageSelected, setPageSelected] = useState(
    props.selectedPage ? props.selectedPage : -1
  );
  const [pageSelectedShared, setPageSelectedShared] = useState(false);
  const [pagesFetched, setPagesFetched] = useState(false);
  const [pageFetched, setPageFetched] = useState(false);

  const { selectedLanguage, refreshPages } = props;

  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].pages_list;

  useEffect(() => {
    const source1 = axios.CancelToken.source();
    const source2 = axios.CancelToken.source();

    setPagesFetched(false);
    axios
      .post(
        env.protocol + env.env + "/api/public/GetPages.php",
        {
          userId: sessionStorage.getItem("vfiUser"),
          site: props.siteId
            ? props.siteId
            : sessionStorage.getItem("storedSite"),
          page: props.pageId,
        },
        {
          cancelToken: source1.token,
        }
      )
      .then((response) => {
        setPages(response.data);
        setPagesFetched(true);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // ignore error as it is due to cancellation
        } else {
          console.error(error);
        }
      });

    setPageFetched(false);
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/pages/GetPage.php?id=" +
          props.pageId,
        {
          cancelToken: source2.token,
        }
      )
      .then((response) => {
        setPage({ ...response.data.page, languages: response.data.languages });
        setPageFetched(true);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // ignore error as it is due to cancellation
        } else {
          console.error(error);
        }
      });

    // Cancel requests if component is unmounted
    return () => {
      source1.cancel();
      source2.cancel();
    };
  }, [props.pageId, props.siteId, props.time]);

  // useEffect(()=>{
  //   const copyPages = Object.assign([],pages);
  //   copyPages.forEach((e,i)=>{
  //     if(e.id === props.deletedChild)
  //     {
  //       copyPages[i].deleted = true;
  //     }
  //   })
  //   setPages(copyPages);
  // },[deletedChild])

  const markDeleted = (id) => {
    const copyPages = Object.assign([], pages);
    copyPages.forEach((e, i) => {
      if (e.id === id) {
        copyPages[i].deleted = true;
      }
    });
    setPages(copyPages);
  };

  let tempPages = Object.assign([], pages);
  if (props.newValue !== undefined && props.newValue !== []) {
    if (tempPages.find((e) => e.id === props.newValue.id) === undefined) {
      tempPages.push(props.newValue);
      setPages(tempPages);
    }
  }

  const editPage = (id, isShared) => {
    props.setIsLoading(true);
    getEditPage(id, isShared).then((data) => {
      let lang = "";
      for (var prop in data.languages) {
        if (prop === "en") {
          lang = prop;
        }
      }
      if (lang === "") {
        for (var prop in data.languages) {
          lang = prop;
          break;
        }
      }
      const state = { page_id: id };
      const title = data.languages[lang].title;
      if (!window.history.state) {
        window.history.pushState(state, title);
      } else if (window.history.state.page_id != id) {
        window.history.pushState(state, title);
      }
      props.editPage(data, isShared);
    });
  };

  const addNewPage = (page) => {
    let newPage = { ...page.page, languages: page.languages };

    let copyPages = cloneDeep(pages);
    copyPages.push(newPage);
    copyPages.sort((a, b) => a.order_value > b.order_value);
    setPages(copyPages);
  };
  let ghostTitle = "";
  if (props.ghostPage) {
    ghostTitle = props.ghostPage.page_name;
    const pageLanguages = props.ghostPage.languages;

    if (pageLanguages) {
      if (pageLanguages[language]) {
        if (pageLanguages[language].title !== "") {
          ghostTitle = pageLanguages[language].title;
        } else {
          const alternate = forceCheckLanguage(pageLanguages).title;
          ghostTitle = alternate ? alternate : ghostTitle;
        }
      } else {
        const alternate = forceCheckLanguage(pageLanguages).title;
        ghostTitle = alternate ? alternate : ghostTitle;
      }
    }

    if (selectedLanguage) {
      const foundSelectedLanguage = Object.keys(props.ghostPage.languages).find(
        (e) => e === selectedLanguage.value
      );

      if (foundSelectedLanguage) {
        ghostTitle = props.ghostPage.languages[foundSelectedLanguage].title;
      }
    }
  }

  const changePageValue = (id, isShared, key, value) => {
    axios
      .post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
        id,
        isShared,
        label: key,
        value,
      })
      .then((response) => {
        const copyPages = cloneDeep(pages);
        copyPages.find((p) => {
          return !!p.shared === isShared && p.id === id;
        })[key] = value;
        setPages(copyPages);
        refreshPages();
      })
      .catch((error) => console.error(error));
  };

  const PageShow = ({ element, i }) => {
    if (props.excludePages) {
      if (props.excludePages.includes(element.id)) return "";
    }

    if (element.shared && props.disableSharedPages) {
      return "";
    }

    return (
      <Page
        key={element.id}
        values={element}
        setPageSelected={(id, shared) => {
          setPageSelected(id);
          setPageSelectedShared(shared);
        }}
        pageSelected={pageSelected}
        pageSelectedShared={pageSelectedShared}
        pageSelect={props.pageSelect}
        pageId={props.pageId}
        siteId={props.siteId}
        newPage={(site, page, values) =>
          newPage(site, page, values, (response) => {
            const data = response.data;
            props.addExtraPage(data.child_of, data);
          })
        }
        editPage={editPage}
        pages={pages}
        movePage={props.movePage}
        orderPage={props.orderPage}
        duplicatePage={(id, parentId, refreshAfter) => {
          props.duplicatePage(id, parentId, addNewPage, refreshAfter);
        }}
        setOptionsList={props.setOptionsList}
        // setOptionsListPageSelected={props.setOptionsListPageSelected}
        optionsListPageSelected={props.optionsListPageSelected}
        selectPage={props.selectAPage}
        deletePage={props.deletePage}
        markDeleted={markDeleted}
        openOverlay={props.openOverlay}
        canMoveUp={() => {
          return i !== 0;
        }}
        canMoveDown={() => i !== pages.length - 1}
        selectedLanguage={selectedLanguage}
        templateList={props.templateList}
        changePageValue={(key, value) =>
          changePageValue(element.id, !!element.shared, key, value)
        }
      />
    );
  };

  return (
    <div
      className={
        "pages-list" +
        (pagesFetched ? " loaded" : "") +
        (props.className ? " " + props.className : "")
      }
    >
      {!pagesFetched ? (
        <div className="load-icon">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        ""
      )}
      <div className="the-list">
        <div className="pages-headline top">
          <span>{props.name.toUpperCase() + " "}</span>
        </div>
        {pages
          .filter((e) => !(e.current_status === "draft" && e.shared))
          .map((element, i) => {
            return (
              <PageShow
                key={element.id + (element.shared ? "s" : "")}
                element={element}
                i={i}
              />
            );
          })}
        {props.ghostPage && (
          <div key={props.ghostPage.id} className={"page ghost"}>
            {selectedLanguage ? (
              Object.keys(props.ghostPage.languages).find(
                (e) => e === selectedLanguage.value
              ) ? (
                <div className="short-lang">{selectedLanguage.label}</div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <span
              className={
                "title" +
                (props.ghostPage.current_status === "draft" ? " is-draft" : "")
              }
            >
              {ghostTitle}
            </span>

            <div className="default-button" />
            <div className="status">
              {props.ghostPage.current_status === "hidden" ? (
                <div className="stat">
                  <FontAwesomeIcon icon={faEyeSlash} />
                </div>
              ) : (
                ""
              )}
              {props.ghostPage.visibility === 0 ? (
                <div className="stat">{lang.private}</div>
              ) : (
                ""
              )}
              {props.ghostPage.deleted ? (
                <div className="stat">{lang.deleted}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {pages.filter((e) => e.current_status === "draft" && e.shared).length >
          0 && (
          <div className="pages-headline shared-pages">
            <span>{"SHARED FROM OTHER SITES"}</span>
          </div>
        )}
        {pages
          .filter((e) => e.current_status === "draft" && e.shared)
          .map((element, i) => (
            <PageShow key={element.id} element={element} i={i} />
          ))}
      </div>
      {!props.disableBottomSelect &&
        (props.selectAPage ? (
          <div
            className={"select-page" + (!pageFetched ? " disabled" : "")}
            onClick={() => {
              props.selectAPage(page);
            }}
          >
            <p>Select page</p>
          </div>
        ) : (
          <ToolTip
            offset={{ x: 30, y: 20 }}
            title=""
            delay={600}
            toolTipElements={<div>Add new page</div>}
            className="add-page"
            onClick={() => {
              newPage(
                /*props.siteId*/ page.site_id,
                props.pageId,
                {},
                (response) => {
                  const data = response.data;
                  props.addExtraPage(data.child_of, data);
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ToolTip>
        ))}
    </div>
  );
}
