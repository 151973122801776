import { faSiren, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import OneMedia from "../../../../assets/PageModules/OneMedia";
import "./PageLocation.scss";
import logo from "../../../../assets/images/vfi-logo.png";
import { faChevronRight, faPen } from "@fortawesome/pro-light-svg-icons";
import SitesList from "../../Pages/SitesList";
import axios from "axios";
import env from "../../../../environment.json";
import { VfiCheckbox2 } from "../../../../assets/VfiCheckbox";
import { cloneDeep } from "lodash";
import { capitalize } from "../../../../assets/helperFunctions";
import PLInfoscreens from "./PLInfoscreens";

export default function PageLocation({ close = () => void 0, data }) {
  const {
    page,
    selectedLanguage: propSelectedLanguage,
    preSelected,
    displayList,
  } = data;
  const [display, setDisplay] = useState(preSelected ?? "websites");
  const [inEdit, setInEdit] = useState(false);
  const [mySelectedLanguage, setMySelectedLanguage] = useState(undefined);
  const [dataToSave, setDataToSave] = useState({});
  const [dataToSaveBefore, setDataToSaveBefore] = useState({});
  const [sites, setSites] = useState([]);
  const [slugsInSites, setSlugsInSites] = useState(undefined);
  const selectedLanguage = mySelectedLanguage ?? propSelectedLanguage;
  const canEdit = dataToSave.sharedTo !== undefined;

  // [INFOSCREEN,] DISPLAY IS USING THESE STATES.
  const [rawDataToSave, setRawDataToSave] = useState(cloneDeep(data));
  const [rawDataBefore, setRawDataBefore] = useState(cloneDeep(data));

  useEffect(() => {
    axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        setSites(response.data ?? []);
      })
      .catch((error) => console.error(error));

    axios
      .post(
        env.protocol + env.env + "/api/secured/pages/shared/CheckSlugsInSites",
        {
          slugs: Object.keys(page.languages).map(
            (lang) => page.languages[lang].url_slug
          ),
        }
      )
      .then((response) => {
        setSlugsInSites(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    axios
      .post(
        env.protocol + env.env + "/api/public/pages/shared/GetSharedTo.php",
        {
          pageId: page.page.id,
          lang: selectedLanguage,
        }
      )
      .then((response) => {
        setDataToSave({ ...dataToSave, sharedTo: response.data });
      })
      .catch((error) => console.error(error));
  }, [selectedLanguage, inEdit]);

  useEffect(() => {
    if (inEdit === true) {
      setDataToSaveBefore(cloneDeep(dataToSave));
    }
  }, [inEdit]);

  const changeShare = (e) => {
    const active = e.checked;
    const { siteId } = e.site;

    const copySharedTo = cloneDeep(dataToSave.sharedTo);
    const oneSharedTo = copySharedTo.find((f) => {
      return parseInt(f.site_id) === parseInt(siteId);
    });
    if (oneSharedTo !== undefined) {
      oneSharedTo.active = active;
    } else {
      copySharedTo.push({
        page_id: page.page.id,
        site_id: parseInt(siteId),
        active: true,
      });
    }
    setDataToSave({ ...dataToSave, sharedTo: copySharedTo });
  };

  const save = () => {
    if (display === "websites") {
      axios
        .post(
          env.protocol +
            env.env +
            "/api/secured/pages/shared/SharePageToOtherSites",
          { pageId: page.page.id, sites: dataToSave.sharedTo }
        )
        .then((response) => {
          setInEdit(false);
        })
        .catch((error) => console.error(error));
    }
    if (display === "infoscreens") {
      const article = rawDataToSave.page.article;
      axios
        .post(
          env.protocol +
            env.env +
            "/api/secured/infoscreen/SaveArticleInfoscreenLocations",
          { article, user: sessionStorage.getItem("vfiUser") }
        )
        .then((response) => {
          if (response.data.success) {
            // rawDataToSave.page.article.infoscreens =
            //   response.data.infoscreensFilters;
            // rawDataToSave.page.article.article_filters =
            //   response.data.articleFilters;
            // setRawDataToSave({ ...rawDataToSave });
            setRawDataBefore(cloneDeep(rawDataToSave));
            setInEdit(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const discardChanges = () => {
    if (display === "websites") {
      setDataToSave(dataToSaveBefore);
    }
    if (display === "infoscreens") {
      setRawDataToSave(cloneDeep(rawDataBefore));
    }
    setInEdit(false);
  };

  let displayContent = "Should not be shown.";

  switch (display) {
    case "websites":
      displayContent = (
        <div>
          <table className="table-websites">
            <tbody>
              <tr>
                <th>PARENT PAGE</th>
              </tr>
              <tr>
                <td className="site">Tourism</td>
                <td className="page-directory">
                  <img className="logo" src={logo}></img>
                  {page.parent_pages.map((parent) => {
                    return (
                      <span key={parent.page_id}>
                        <FontAwesomeIcon
                          className="arrow"
                          icon={faChevronRight}
                        />
                        <span>{parent.title}</span>
                      </span>
                    );
                  })}
                  {page.parent_pages.length === 0 && (
                    <span>
                      <FontAwesomeIcon
                        className="arrow"
                        icon={faChevronRight}
                      />
                      <span>Home</span>
                    </span>
                  )}
                  <div className="edit-directory">
                    {/* <FontAwesomeIcon icon={faPen} /> */}
                  </div>
                </td>
              </tr>
              <tr className="table-gap"></tr>
              <tr>
                <th>OTHER PAGES - the pages this page is shown</th>
              </tr>
              {/* <td>
                    <div className="sites-shared-to"> */}
              {dataToSave.sharedTo !== undefined &&
                slugsInSites !== undefined &&
                sites
                  .filter(
                    (s) =>
                      s.type_of === "site" &&
                      parseInt(s.siteId) !== parseInt(page.site.siteId)
                  )
                  .map((site) => {
                    if (parseInt(site.siteId) === data.page.page.site_id) {
                      return "";
                    }
                    const oneSharedTo = dataToSave.sharedTo.find(
                      (e) => parseInt(site.siteId) === e.site_id
                    );
                    const isShared =
                      oneSharedTo !== undefined && oneSharedTo.active;
                    const slugFoundInSite = slugsInSites[site.siteId]
                      ? slugsInSites[site.siteId].amount > 0
                      : false;
                    const path = dataToSave.sharedTo.find(
                      (e) => e.site_id === parseInt(site.siteId)
                    );
                    return (
                      <tr key={site.siteId} className="sites-shared-to">
                        <td className={"site " + (isShared && "selected")}>
                          <label>
                            <VfiCheckbox2
                              className="site-check"
                              checked={isShared}
                              onChange={(e) => {
                                if (!slugFoundInSite)
                                  changeShare({
                                    site,
                                    checked: e.target.checked,
                                  });
                              }}
                            />
                            <span className="site-text">{site.name}</span>
                          </label>
                          {slugFoundInSite && (
                            <span className="slug-error">
                              Slug already exists in site
                            </span>
                          )}
                        </td>
                        <td className="path-location">
                          {path !== undefined && isShared && (
                            <>
                              <img className="logo" src={logo} />
                              {path.path &&
                                path.path.map((parent) => {
                                  return (
                                    <span key={parent.page_id}>
                                      <FontAwesomeIcon
                                        className="arrow"
                                        icon={faChevronRight}
                                      />
                                      <span>{parent.title}</span>
                                    </span>
                                  );
                                })}
                            </>
                          )}
                          {path && path.current_status !== "published" && (
                            <span>
                              <FontAwesomeIcon
                                className="arrow"
                                icon={faChevronRight}
                              />
                              {/* <span className="italic">(Inactive)</span> */}
                            </span>
                          )}
                          {path?.path !== undefined &&
                            path.path.length === 0 &&
                            path.current_status === "published" && (
                              <span>
                                <FontAwesomeIcon
                                  className="arrow"
                                  icon={faChevronRight}
                                />
                                <span className="italic">
                                  {
                                    sites.find(
                                      (e) => parseInt(e.siteId) === path.site_id
                                    )?.name
                                  }
                                </span>
                              </span>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              {/* </div>
                  </td> */}
            </tbody>
          </table>
        </div>
      );
      break;

    case "infoscreens":
      displayContent = (
        <div className="infoscreens-content">
          <div className="sub-text">
            INFOSCREENS - article active on infoscreens
          </div>
          <PLInfoscreens
            key={inEdit}
            data={rawDataToSave}
            setData={(data) => setRawDataToSave(data)}
            editable={inEdit}
          />
        </div>
      );
      break;
    default:
      // Should never be here..
      break;
  }

  return (
    <div className="page-location">
      <div className="header">
        <div className="close" onClick={() => close(rawDataBefore)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="header-text">Location</div>
        {!inEdit && canEdit && (
          <div className="edit" onClick={() => setInEdit(true)}>
            EDIT
          </div>
        )}
        {inEdit && (
          <>
            <div className="discard-changes" onClick={discardChanges}>
              DISCARD CHANGES
            </div>
            <div className="save-changes" onClick={save}>
              SAVE
            </div>
          </>
        )}
      </div>
      <div className="pl-content">
        <div className="left-side">
          <div className="page-image">
            <OneMedia
              media={{
                src: page.page.feature_image_dir
                  ? page.page.feature_image_dir.medium
                  : undefined,
              }}
              disableChange={true}
            />
          </div>
          <div className="display-list">
            <div className="display-at">DISPLAY AT</div>
            {displayList !== undefined
              ? displayList.map((d) => {
                  return (
                    <div
                      key={d}
                      className={"choice " + (display === d && "selected")}
                      onClick={() => setDisplay(d)}
                    >
                      {capitalize(d)}
                    </div>
                  );
                })
              : [
                  <div
                    key="websites"
                    className={
                      "choice " + (display === "websites" && "selected")
                    }
                    onClick={() => setDisplay("websites")}
                  >
                    Websites
                  </div>,
                  <div
                    key="infoscreens"
                    className={
                      "choice " + (display === "infoscreens" && "selected")
                    }
                    onClick={() => setDisplay("infoscreens")}
                  >
                    Infoscreens
                  </div>,
                ]}
          </div>
        </div>
        <div className="right-side">
          <div className="top-content">
            <div className="page-title">
              {data.page.languages[selectedLanguage].title}
            </div>
            <div className="languages">
              {Object.keys(page.languages)
                .sort(
                  (a, b) =>
                    page.languages[a].order_by > page.languages[b].order_by
                )
                .map((lang) => (
                  <div
                    key={lang}
                    className={
                      "lang " + (selectedLanguage === lang && "selected")
                    }
                    onClick={() => setMySelectedLanguage(lang)}
                  >
                    {lang.toLocaleUpperCase()}
                  </div>
                ))}
            </div>
          </div>
          <div className={"lower-content " + (inEdit && "in-edit")}>
            {displayContent}
          </div>
        </div>
      </div>
    </div>
  );
}
