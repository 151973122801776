import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import VfiInputText from "./VfiInputText/VfiInputText";
import React, { useRef, useState } from "react";

const libraries = ["places"];

const GoogleMapSearchBox = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA9xP3HbkU6iQiud39nHIrSRVKlvemgsXY",
    libraries,
  });

  const searchBoxRef = useRef(null);
  const [places, setPlaces] = useState([]);

  const onLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const newPlaces = searchBoxRef.current.getPlaces();
      setPlaces(newPlaces);
      if (props.change) {
        props.change(newPlaces[0]); // Call parent's callback with the first place.
      }
    }
  };

  return isLoaded ? (
    <div
      data-standalone-searchbox=""
      name="practicalInfo"
      className={props.className}
    >
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        <VfiInputText
          placeholder={props.placeholder}
          value={props.value}
          defaultValue={props.defaultValue}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      </StandaloneSearchBox>
      {props.listResult === true ? (
        <ol>
          {places.map(
            ({ place_id, formatted_address, geometry: { location } }) => (
              <li key={place_id}>
                {formatted_address}
                {" at "}({location.lat()}, {location.lng()})
              </li>
            )
          )}
        </ol>
      ) : null}
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default GoogleMapSearchBox;
