
import type { section } from "../ArticlesDisplay/types";


export const adminSections: section[] = [
    {
      title: "Location",
      views: [
        {name:"sites",key:"1",checkType:"sites",checkMinimumRole:"Publisher"}, 
        {name:"infoscreens",key:"2",checkType:"articleTypes",checkMinimumRole:"Publisher"}
      ],
    },
  
    // {
    //   title: "Email",
    //   views: [
    //     "subscribers",
    //     "forms",
    //     "e-mail flows",
    //     "newsletter",
    //   ],
    // },
  
    // {
    //   title: "Admin & Logs",
    //   views: [
    //     "webflow content",
    //     "message overview",
    //   ],
    // },
  
    {
      title: "What's on",
      views: [
        // "smart phrases",
        {name:"amenities",key:"3",checkType:"superadmin"},
      ],
    },
  
  ];

 